// @flow
import React from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import FaSpinner from "../components/faSpinner/faSpinner";
import { FRONTEND_ORIGIN_URL_NO_SLASH } from "../config";
import BasicPage from "../components/basicPage/basicPage";
import Page404 from "../components/404/404";
import {
  parseBasicPage,
  newsPageRouteConverter,
} from "../helperFunctions/parseNews";
import withTracker from "./withtracker";
import MetaData from "../components/meta/meta";
import AuthorizationHOC from "./AuthorizationHOC";
import { ALL_USERS } from "../constants/userGroups";
// import Breadcrumb from "../components/breadcrumb/breadcrumb";

type Props = {
  fetchBasicPage: Function,
  pages: Object,
  loading: boolean,
  match: Object,
  statusCode: string,
  token: string,
  location: {
    pathname: string,
  },
};

type Page = {
  title: string,
  field_image: {
    image_url: string,
    news_page_image_style: string,
  },
  author: {
    first_name: string,
    last_name: string,
    uuid: string,
    name: string,
    image: string,
  },
  body: string,
  path: Array<Object>,
  field_google_drive_id: string,
  fieldScrollspyVisible: boolean,
  image: Object,
  html: string,
};

const getActivePage = (props) => {
  const {
    pages,
    location: { pathname },
  } = props;
  if (!pages || (pages && pages.size === 0)) {
    return null;
  }
  const activePage = pages.get(
    newsPageRouteConverter(pathname, "/news", "/blog")
  );
  return activePage ? activePage.toJS() : null;
};

class BasicPageHOC extends React.Component<Props> {
  getCurrentPage = () => {
    const activePage = getActivePage(this.props);
    if (!activePage) return null;
    return parseBasicPage(activePage);
  };

  componentDidMount() {
    const { pathname } = this.props.location;
    const { token } = this.props;
    if (token) {
      this.props.fetchBasicPage({
        route: newsPageRouteConverter(pathname, "/news", "/blog"),
        token: token,
      });
    }
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    const { token } = this.props;
    const nextToken = nextProps.token;
    const { pathname } = this.props.location;
    const nextPathname = nextProps.location.pathname;

    if (nextPathname && nextPathname !== pathname) {
      this.props.fetchBasicPage({
        route: newsPageRouteConverter(pathname, "/news", "/blog"),
        token: token,
      });
    }

    if (nextToken && nextToken !== token) {
      this.props.fetchBasicPage({
        route: newsPageRouteConverter(pathname, "/news", "/blog"),
        token: token,
      });
    }
  }

  render() {
    const { loading, statusCode } = this.props;
    const { pathname } = this.props.location;
    const currentPage: Page | null = this.getCurrentPage();
    const { html, image, title, fieldScrollspyVisible, driveId } = currentPage
      ? currentPage
      : {};
    return (
      <div>
        <MetaData
          title={title ? title : "Page"}
          url={FRONTEND_ORIGIN_URL_NO_SLASH + pathname}
          description="Learn all the latest NBG Developer Portal news."
        />
        {!loading ? (
          statusCode === "200" && (html || driveId) ? (
            <BasicPage
              {...currentPage}
              pathname={pathname}
              image={image ? image.image_url : null}
              fieldScrollspyVisible={!fieldScrollspyVisible}
            />
          ) : (
            <Page404 />
          )
        ) : (
          <FaSpinner loading={loading} />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.basicPage.get("loading"),
  statusCode: state.basicPage.get("statusCode"),
  pages: state.basicPage.get("pages"),
  token: state.applicationUser.get("token"),
});

const mapDispatchToProps = (dispatch) => ({
  fetchBasicPage: (url) => {
    dispatch(actions.fetchBasicPage(url));
  },
});

export default withTracker(
  AuthorizationHOC(ALL_USERS)(
    connect(mapStateToProps, mapDispatchToProps)(BasicPageHOC)
  )
);
