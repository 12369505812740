// @flow
import React from "react";
import ModalContainer from "../modalContainer/modalContainer";
import "./organizationMemberInfoModal.css";
import { div } from "@cycle/dom";
import { USER_AVATAR } from "../images";
import RedoCircleIcon from "../svgs/icoMoon/icons/redoCircle/redoCircleIcon";
import CheckIcon from "../svgs/checkIcon";
import BanCircleIcon from "../svgs/icoMoon/icons/banCircle/banCircleIcon";
import {
  MEMBER_ACTIVE,
  MEMBER_DEFINITIONS,
  MEMBER_PENDING_INVITATION,
  ROLE_DEFINITIONS,
  ORGANIZATION_OWNER,
} from "../../constants/organizationRoles";
import Checkbox from "../checkbox/checkbox";
import Popover from "../popover/popover";
import QuestionCircleIcon from "../svgs/icoMoon/icons/questionCircle/questionCircle";
import SimpleButtonWrapper from "../simpleButtonWrapper/simpleButtonWrapper";
import Button from "../button/button";
import type { Member } from "../../constants/map/organization";
import ErrorMessage from "../errorMessage/errorMessage";
import _ from "lodash";
import ConfirmModal from "../organization/confirmModal/confirmModal";

type Props = {
  isOpen: boolean,
  isOrganizationCreator: boolean,
  onClose: Function,
  onSubmit: Function,
  onMemberRemove: Function,
  resendInvitation: Function,
  member: Member,
};

class OrganizationMemberInfoModal extends React.Component<Props> {
  state = {
    error: null,
    selectedRoles: [],
    confirmModalOpen: false,
  };

  openConfirmModal = () => {
    this.setState({
      confirmModalOpen: true,
    });
  };

  closeConfirmModal = () => {
    this.setState({
      confirmModalOpen: false,
    });
  };

  componentDidMount() {
    this.resetChecked(this.props.member);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.member.id !== this.props.member.id) {
      this.resetChecked(nextProps.member);
    }
  }

  resetChecked = (member) => {
    this.setState({
      selectedRoles: member.roles,
      error: null,
    });
  };

  handleRoleCheck = (role) => {
    const { isOrganizationCreator } = this.props;
    const { selectedRoles } = this.state;
    const uniqueSelectedRoles = [...new Set(selectedRoles)];

    if (isOrganizationCreator) {
      this.setState({
        selectedRoles:
          selectedRoles.indexOf(role) > -1
            ? role !== ORGANIZATION_OWNER
              ? selectedRoles.filter((r) => r !== role)
              : uniqueSelectedRoles
            : [...selectedRoles, role],
      });
    } else {
      this.setState({
        selectedRoles:
          selectedRoles.indexOf(role) > -1
            ? selectedRoles.filter((r) => r !== role)
            : [...selectedRoles, role],
      });
    }
  };

  onSubmit = () => {
    const { selectedRoles } = this.state;
    const { roles } = this.props.member;
    if (selectedRoles.length === 0) {
      return this.setState({
        error: "Select at least one role",
      });
    }
    if (!_.isEqual(_.sortBy(selectedRoles), _.sortBy(roles))) {
      this.props.onSubmit(this.props.member, selectedRoles);
    }
    this.props.onClose();
  };

  render() {
    const {
      isOpen,
      onClose,
      onMemberRemove,
      resendInvitation,
      member,
      isOrganizationCreator,
    } = this.props;
    const { selectedRoles, error, confirmModalOpen } = this.state;
    return (
      <ModalContainer
        isOpen={isOpen}
        closeModal={onClose}
        contentLabel="Member Information"
        title="View/Edit member information"
        className="member_info_modal"
      >
        <div className="member_info">
          <div className="member_profile_info">
            <div className="member_profile_photo">
              <img src={USER_AVATAR} alt="user avatar" />
            </div>
            <table>
              <tbody>
                <tr>
                  <td>Name</td>
                  <td>{member.userName}</td>
                </tr>
                <tr>
                  <td>Status</td>
                  <td>
                    {
                      MEMBER_DEFINITIONS.find((d) => d.id === member.status)
                        .name
                    }
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="member_actions">
              {member.status === MEMBER_PENDING_INVITATION && (
                <div>
                  {!member.resentInvitation ? (
                    <div
                      className="member_actions__action member_actions__action--resend"
                      onClick={() => resendInvitation(member)}
                    >
                      <RedoCircleIcon />
                      <span>Resend Invitation</span>
                    </div>
                  ) : (
                    <div className="member_actions__action member_actions__action--sent">
                      <CheckIcon />
                      <span>Invitation sent</span>
                    </div>
                  )}
                  <div
                    className="member_actions__action"
                    onClick={this.openConfirmModal}
                  >
                    <BanCircleIcon />
                    <span>Cancel invitation</span>
                  </div>
                </div>
              )}
              {member.status === MEMBER_ACTIVE && (
                <div>
                  <div className="member_actions__action member_actions__action--sent">
                    <CheckIcon />
                    <span>Active</span>
                  </div>
                  {!isOrganizationCreator && (
                    <div
                      onClick={this.openConfirmModal}
                      className="member_actions__action cancel_action"
                    >
                      <BanCircleIcon />
                      <span>Remove Member</span>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="edit_member_roles">
            <span>Edit Roles</span>
            {ROLE_DEFINITIONS.map((role) => (
              <div key={role.id} className="member_roles_checkbox">
                <Checkbox
                  label={role.name}
                  onChange={() => this.handleRoleCheck(role.id)}
                  checked={selectedRoles.indexOf(role.id) > -1}
                />
                <div className="member_roles_description">
                  <Popover
                    message={role.description}
                    direction="right"
                    autohide
                  >
                    <QuestionCircleIcon />
                  </Popover>
                </div>
              </div>
            ))}
          </div>
          <div className="member_info_buttons">
            <div style={{ marginLeft: -8, marginBottom: 8 }}>
              <ErrorMessage inline message={error} />
            </div>
            <SimpleButtonWrapper
              style={{ marginLeft: "1rem" }}
              position="right"
            >
              <Button onClick={onClose}>CANCEL</Button>
            </SimpleButtonWrapper>
            <SimpleButtonWrapper position="right">
              <Button electro position="right" onClick={this.onSubmit}>
                SUBMIT
              </Button>
            </SimpleButtonWrapper>
          </div>
        </div>
        <ConfirmModal
          isOpen={confirmModalOpen}
          onCancel={this.closeConfirmModal}
          onSubmit={() => {
            this.closeConfirmModal();
            onMemberRemove(member);
          }}
          {...(member.status === MEMBER_PENDING_INVITATION
            ? {
                title: "Cancel Invitation",
                message:
                  "Are you sure you want to cancel this invitation? This will remove this member from this organization. ",
              }
            : {
                title: "Remove Member",
                message: "Are you sure you want to remove this member? ",
              })}
        />
      </ModalContainer>
    );
  }
}

export default OrganizationMemberInfoModal;
