import React from "react";
import SearchResult from "../searchResult/searchResult";
import v4 from "uuid/v4";
import SearchPagePagerHOC from "../../../containers/SearchPagePagerHOC";
import FaSpinner from "../../faSpinner/faSpinner";
import "./searchResultList.css";
import DropDownMenu from "../../dropDownMenu/dropDownMenu";
import SearchNew from "../searchNew";

type Props = {
  listItems: Array<Object>,
  query: string,
  loading: boolean,
  onCategoryChange: Function,
};

const CATEGORIES = [
  {
    name: "Article",
    value: "article",
  },
  {
    name: "Basic Page",
    value: "page",
  },
  {
    name: "Forum",
    value: "forum",
  },
  {
    name: "Product",
    value: "api_product",
  },
  {
    name: "Api",
    value: "api_endpoint",
  },
];

const SearchResultList = (props: Props) => {
  const { listItems, query, loading, onCategoryChange } = props;
  return (
    <div className="searchArea-wrapper">
      <div className="searchArea">
        <div className="searchBar">
          <div className="sTitle">
            <span>
              {query === ""
                ? "Type something in the search bar..."
                : "Showing results for:"}
            </span>
          </div>
          <SearchNew autofocusMobile />
        </div>
        <div className="searchDropdown">
          <div className="sTitle">
            <span>Search in:</span>
          </div>
          <div className="side_field">
            <DropDownMenu
              name={"ALL"}
              items={CATEGORIES}
              selectHandler={onCategoryChange}
            />
          </div>
        </div>
      </div>
      {listItems && listItems.length > 0 && query !== "" ? (
        <div>
          {listItems.map((item) => (
            <SearchResult
              key={v4()}
              link={item.link}
              title={item.title}
              description={item.description}
              query={query}
              created={item.created}
            />
          ))}
          <SearchPagePagerHOC query={query} />
        </div>
      ) : !loading && query !== "" ? (
        <div className="result">
          <div className="rInfo">
            <div className="rTitle">Your search yielded no results</div>
            <div className="rDescription">
              <p>
                Check if your spelling is correct and remove quotes around
                phrases to search for each word individually.
              </p>
            </div>
          </div>
        </div>
      ) : null}
      <div
        style={{
          clear: "both",
          marginTop: 22,
          display: "inline-block",
          width: "100%",
        }}
      >
        <FaSpinner loading={loading} />
      </div>
    </div>
  );
};

export default SearchResultList;
