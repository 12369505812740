import * as ActionTypes from "./types";

export function fetchBasicPage(payload) {
  return {
    type: ActionTypes.FETCH_BASIC_PAGE,
    payload,
  };
}

export function fetchBasicPageFailed(payload) {
  return {
    type: ActionTypes.FETCH_BASIC_PAGE_FAILED,
    payload,
  };
}

export function storeBasicPage(payload) {
  return {
    type: ActionTypes.STORE_BASIC_PAGE,
    payload,
  };
}

export function fetchArticles(payload) {
  return {
    type: ActionTypes.FETCH_ARTICLES,
    payload,
  };
}

export function storeArticles(payload) {
  return {
    type: ActionTypes.STORE_ARTICLES,
    payload,
  };
}

export function fetchArticlesFailed(payload) {
  return {
    type: ActionTypes.FETCH_ARTICLES_FAILED,
    payload,
  };
}

export function fetchArticlesCategory(payload) {
  return {
    type: ActionTypes.FETCH_ARTICLES_CATEGORY,
    payload,
  };
}

export function storeArticlesCategory(payload) {
  return {
    type: ActionTypes.STORE_ARTICLES_CATEGORY,
    payload,
  };
}

export function fetchArticlesCategoryFailed(payload) {
  return {
    type: ActionTypes.FETCH_ARTICLES_CATEGORY_FAILED,
    payload,
  };
}

export function addCategoryFilter(payload) {
  return {
    type: ActionTypes.ADD_CATEGORY_FILTER,
    payload,
  };
}

export function removeCategoryFilter(payload) {
  return {
    type: ActionTypes.REMOVE_CATEGORY_FILTER,
    payload,
  };
}

export function searchArticle(payload) {
  return {
    type: ActionTypes.SEARCH_ARTICLES,
    payload,
  };
}

export function clearCategoryFilter() {
  return {
    type: ActionTypes.CLEAR_CATEGORY_FILTER,
  };
}
