// @flow
import React from "react";
import "./forumTableRow.css";
import { Link } from "react-router-dom";
import { formattedDate, invalidDate } from "../../../constants/misc";
import ChangeSubscriptionStatus from "../../changeSubscriptionStatus/changeSubscriptionStatus";
import SimpleButtonWrapper from "../../simpleButtonWrapper/simpleButtonWrapper";
import Folder from "../../svgs/icoMoon/icons/folder/folder";
import Popover from "../../popover/popover";
import { IDS } from "../../../constants/testIds";

type Props = {
  depth: number,
  forum_container: boolean,
  name: string,
  uuid: string,
  statistics: Object,
  last_post: Object,
  changeSubscriptionStatus: Function,
  subscribed?: boolean,
  isLoggedIn: boolean,
  tid: number,
  pastDate: string,
  tableRowIndex: number,
  parentIndex: number,
};

class ForumTableRow extends React.Component<Props> {
  shouldComponentUpdate(nextProps) {
    if (
      nextProps.tid === this.props.tid &&
      nextProps.subscribed === this.props.subscribed
    ) {
      return false;
    }
    return true;
  }

  render() {
    const {
      name,
      uuid,
      statistics,
      last_post,
      subscribed,
      tableRowIndex,
      parentIndex,
    } = this.props;
    const changeSubscriptionStatusData = {
      node: uuid,
      entity_type: "taxonomy_term",
    };
    return (
      <li className="row line">
        <div className="cell forum" data-title="FORUM">
          <div className="arr_line_1">
            <Link to={"/forum/" + uuid}>
              <Folder />
              {" " + name}
            </Link>
            <SimpleButtonWrapper position="right">
              <Popover
                message={
                  !subscribed ? (
                    <p>
                      Click the button below to subscribe to this forum. <br />
                      You will receive email notifications. <br />
                    </p>
                  ) : (
                    <p>
                      Click the button below to unsubscribe to this forum.{" "}
                      <br />
                      You will no longer receive email notifications. <br />
                      You can subscribe again at any time
                    </p>
                  )
                }
                delay
                autohide
                milliseconds={2000}
              >
                <ChangeSubscriptionStatus
                  data={changeSubscriptionStatusData}
                  subscribed={subscribed}
                  placeRight
                  id={
                    subscribed
                      ? `${IDS.forum_unsubscribe_button_}${parentIndex}_${tableRowIndex}`
                      : `${IDS.forum_subscribe_button_}${parentIndex}_${tableRowIndex}`
                  }
                />
              </Popover>
            </SimpleButtonWrapper>
          </div>
        </div>
        <div className="cell tr_white topic" data-title="TOPICS">
          {statistics.topic_count}
        </div>
        <div className="cell tr_white posts" data-title="POSTS">
          {statistics.comment_count}
        </div>
        <div className="cell tr_white lastposts" data-title="LAST POSTS">
          {last_post &&
          last_post.created.getTime() !== invalidDate.getTime() ? (
            <span>
              {formattedDate(last_post.created)} from{" "}
              <span>{last_post.name}</span>
            </span>
          ) : (
            "n/a"
          )}
        </div>
      </li>
    );
  }
}

export default ForumTableRow;
