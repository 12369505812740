import React, { Component } from "react";
import { FRONTEND_ORIGIN_URL_NO_SLASH } from "../config";
import withTracker from "./withtracker";
import AuthorizationHOC from "./AuthorizationHOC";
import { REGISTERED_USERS } from "../constants/userGroups";
import MetaData from "../components/meta/meta";
import SectionStart from "../components/sectionStart/sectionStart";
import { Sidebar, SidebarHeader } from "../components/layouts/layout1";
import Title from "../components/title/title";
import Heading from "../components/heading/heading";
import BracketButton from "../components/bracketButton/bracketButton";
import { parseOrganization } from "../constants/parse/organization";
import * as actions from "../actions";
import { connect } from "react-redux";
import { getRouteUrl } from "../routers";
import {
  ROUTE_ORGANIZATION_EDIT,
  ROUTE_ORGANIZATION_VIEW_SINGLE,
} from "../routers/content/organization";
import OrganizationForm from "../components/organization/organizationForm/organizationForm";
import FaSpinner from "../components/faSpinner/faSpinner";
import Breadcrumb from "../components/breadcrumb/breadcrumb";
import withBreadCrumb from "./withBreadcrumb";
import UploadFileErrors from "../components/organization/uploadFileErrors/uploadFileErrors";

type Props = {
  match: {
    params: {
      organizationId: string,
    },
  },
  organizations: Object,
  loading: boolean,
  breadcrumb: Object,
  match: Object,
  fetchOrganizationMemberships: Function,
  prepareOrganizationForm: Function,
  fetchOrganizations: Function,
  updateOrganization: Function,
  updateFormField: Function,
};

class OrganizationEditHOC extends Component<Props> {
  handleInputChange = (event: Object) => {
    const target = event.target;
    const value =
      target.type === "checkbox"
        ? target.checked
        : target.files
        ? target.files
        : target.value;
    const name = target.name;
    this.props.updateFormField({
      name,
      value,
    });
  };

  onSubmit = (evt) => {
    const payload = {
      uuid: evt.get("uuid"),
      title: evt.get("title"),
      description: evt.get("description"),
      image: evt.get("image"),
      redirectAfterUpdate: getRouteUrl(ROUTE_ORGANIZATION_VIEW_SINGLE, {
        organizationId: evt.get("uuid"),
      }),
    };
    this.props.updateOrganization(payload);
  };

  componentDidMount() {
    const { organizations, match } = this.props;
    const { organizationId } = match.params;

    if (!organizations.hasIn([organizationId])) {
      this.props.fetchOrganizations({
        uuid: organizationId,
      });
    } else {
      this.props.prepareOrganizationForm(
        parseOrganization(organizations.get(organizationId))
      );
    }
  }

  componentWillReceiveProps(nextProps) {
    const { organizations, match } = this.props;
    const { organizationId } = match.params;

    if (
      nextProps.organizations.hasIn([organizationId]) &&
      !organizations.hasIn([organizationId])
    ) {
      this.props.prepareOrganizationForm(
        parseOrganization(nextProps.organizations.get(organizationId))
      );
    }
  }

  render() {
    const { organizations, loading, breadcrumb, match } = this.props;
    const { organizationId } = match.params;
    const organizationData = parseOrganization(
      organizations.get(organizationId)
    );

    return (
      <div>
        <MetaData
          title="Edit Organization"
          url={
            FRONTEND_ORIGIN_URL_NO_SLASH +
            getRouteUrl(ROUTE_ORGANIZATION_EDIT, { organizationId })
          }
        />
        <SectionStart>
          <Sidebar
            sidebarLeft={
              <SidebarHeader>
                <Title type="section" displayEffect>
                  <Heading level="1">EDIT ORGANIZATION</Heading>
                </Title>
                {organizationData && (
                  <div>
                    <Breadcrumb
                      {...breadcrumb}
                      params={{
                        org: {
                          href: getRouteUrl(ROUTE_ORGANIZATION_VIEW_SINGLE, {
                            organizationId,
                          }),
                          name: organizationData.title,
                        },
                      }}
                    />
                    <BracketButton
                      caretEnable
                      className="movingBrackets left"
                      title={"BACK TO OVERVIEW"}
                      url={getRouteUrl(ROUTE_ORGANIZATION_VIEW_SINGLE, {
                        organizationId: organizationData.uuid,
                      })}
                    />
                  </div>
                )}
              </SidebarHeader>
            }
            sidebarRight={
              <div>
                {organizationData && (
                  <div>
                    <OrganizationForm
                      handleInputChange={this.handleInputChange}
                      onSubmit={this.onSubmit}
                    />
                    <UploadFileErrors organizationId={organizationData.uuid} />
                  </div>
                )}
                {loading && <FaSpinner loading />}
              </div>
            }
          />
        </SectionStart>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  organizations: state.organizationReducer.get("byId"),
  loading: state.organizationReducer.get("loading"),
});

const mapDispatchToProps = (dispatch) => ({
  fetchOrganizations: (payload) => {
    dispatch(actions.fetchOrganizations(payload));
  },
  updateFormField: (payload) => {
    dispatch(actions.updateOrganizationFormField(payload));
  },
  prepareOrganizationForm: (payload) => {
    dispatch(actions.prepareOrganizationForm(payload));
  },
  updateOrganization: (payload) => {
    dispatch(actions.updateOrganization(payload));
  },
});

export default withTracker(
  AuthorizationHOC(REGISTERED_USERS)(
    withBreadCrumb(
      connect(mapStateToProps, mapDispatchToProps)(OrganizationEditHOC),
      {
        name: "Edit",
        parents: ["Organizations", "{org}"],
      }
    )
  )
);
