// API Analytics
export const CHECK_IF_ANALYTICS_ARE_ENABLED = "CHECK_IF_ANALYTICS_ARE_ENABLED";
export const CHECK_IF_ANALYTICS_ARE_ENABLED_SUCCESS =
  "CHECK_IF_ANALYTICS_ARE_ENABLED_SUCCESS";
export const CHECK_IF_ANALYTICS_ARE_ENABLED_FAILED =
  "CHECK_IF_ANALYTICS_ARE_ENABLED_FAILED";
export const FETCH_AGGREGATES_PER_ENDPOINT_PER_DAY_STATISTICS =
  "FETCH_AGGREGATES_PER_ENDPOINT_PER_DAY_STATISTICS";
export const FETCH_AGGREGATES_PER_ENDPOINT_PER_DAY_STATISTICS_SUCCESS =
  "FETCH_AGGREGATES_PER_ENDPOINT_PER_DAY_STATISTICS_SUCCESS";
export const FETCH_AGGREGATES_PER_ENDPOINT_PER_DAY_STATISTICS_FAILED =
  "FETCH_AGGREGATES_PER_ENDPOINT_PER_DAY_STATISTICS_FAILED";
export const FETCH_AGGREGATES_PER_ENDPOINT_STATISTICS =
  "FETCH_AGGREGATES_PER_ENDPOINT_STATISTICS";
export const FETCH_AGGREGATES_PER_ENDPOINT_STATISTICS_SUCCESS =
  "FETCH_AGGREGATES_PER_ENDPOINT_STATISTICS_SUCCESS";
export const FETCH_AGGREGATES_PER_ENDPOINT_STATISTICS_FAILED =
  "FETCH_AGGREGATES_PER_ENDPOINT_STATISTICS_FAILED";

//API Products
export const FETCH_API_PRODUCTS = "FETCH_API_PRODUCTS";

export const STORE_API_PRODUCTS = "STORE_API_PRODUCTS";
export const FETCH_API_PRODUCTS_FAILED = "FETCH_API_PRODUCTS_FAILED";
export const FETCH_API_PRODUCTS_CATEGORY = "FETCH_API_PRODUCTS_CATEGORY";
export const STORE_API_PRODUCTS_CATEGORY = "STORE_API_PRODUCTS_CATEGORY";
export const FETCH_API_PRODUCTS_CATEGORY_FAILED =
  "FETCH_API_PRODUCTS_CATEGORY_FAILED";
export const FILTER_API_PRODUCTS_BY_TITLE = "FILTER_API_PRODUCTS_BY_TITLE";

//API Product
export const FETCH_API_PRODUCT = "FETCH_API_PRODUCT";
export const FETCH_API_PRODUCT_SUCCESS = "FETCH_API_PRODUCT_SUCCESS";
export const FETCH_API_PRODUCT_FAILED = "FETCH_API_PRODUCT_FAILED";
export const FETCH_API_PRODUCT_NEW_VERSION = "FETCH_API_PRODUCT_NEW_VERSION";
export const FETCH_API_PRODUCT_NEW_VERSION_SUCCESS =
  "FETCH_API_PRODUCT_NEW_VERSION_SUCCESS";
export const FETCH_API_PRODUCT_NEW_VERSION_FAILED =
  "FETCH_API_PRODUCT_NEW_VERSION_FAILED";
export const CLEAR_SUBSCRIBE_ERROR_MESSAGE = "CLEAR_SUBSCRIBE_ERROR_MESSAGE";
export const CLEAR_API_PRODUCTS_BY_ID = "CLEAR_API_PRODUCTS_BY_ID";
export const FETCH_COMPARISON_TABLE_SUCCESS = "FETCH_COMPARISON_TABLE_SUCCESS";
export const FETCH_COMPARISON_TABLE_FAILED = "FETCH_COMPARISON_TABLE_FAILED";

//API Product Changelog
export const FETCH_API_PRODUCT_CHANGELOG_SUCCESS =
  "FETCH_API_PRODUCT_CHANGELOG_SUCCESS";
export const FETCH_API_PRODUCT_CHANGELOG_FAILED =
  "FETCH_API_PRODUCT_CHANGELOG_FAILED";

// Applications
export const FETCH_APPLICATIONS = "FETCH_APPLICATIONS";
export const STORE_APPLICATIONS = "STORE_APPLICATIONS";
export const FETCH_APPLICATIONS_FAILED = "FETCH_APPLICATIONS_FAILED";
export const FETCH_APPLICATION = "FETCH_APPLICATION";
export const STORE_APPLICATION = "STORE_APPLICATION";
export const FETCH_APPLICATION_FAILED = "FETCH_APPLICATION_FAILED";
export const VALIDATE_PSD_CERTIFICATE = "VALIDATE_PSD_CERTIFICATE";
export const VALIDATE_PSD_CERTIFICATE_SUCCESS =
  "VALIDATE_PSD_CERTIFICATE_SUCCESS";
export const VALIDATE_PSD_CERTIFICATE_FAILED =
  "VALIDATE_PSD_CERTIFICATE_FAILED";
export const VALIDATE_PSD_CERTIFICATE_RESET = "VALIDATE_PSD_CERTIFICATE_RESET";

//CONTACT FORM
export const SEND_CONTACT_FORM = "SEND_CONTACT_FORM";
export const SEND_CONTACT_FORM_SUCCESS = "SEND_CONTACT_FORM_SUCCESS";
export const SEND_CONTACT_FORM_FAILED = "SEND_CONTACT_FORM_FAILED";
export const CLEAR_CONTACT_FORM_MESSAGES = "CLEAR_CONTACT_FORM_MESSAGES";
export const RESET_FORM_CONTACT_FORM = "RESET_FORM_CONTACT_FORM";

// ORGANIZATIONS
export const UPDATE_ORGANIZATION_FORM_FIELD = "UPDATE_ORGANIZATION_FORM_FIELD";
export const PREPARE_ORGANIZATION_FORM = "PREPARE_ORGANIZATION_FORM";
export const CLEAR_ORGANIZATION_FORM = "CLEAR_ORGANIZATION_FORM";
export const REMOVE_ORGANIZATION_STATUS_MESSAGE =
  "REMOVE_ORGANIZATION_STATUS_MESSAGE";

// export const REMOVE_ORGANIZATION_ERROR = 'REMOVE_ORGANIZATION_ERROR';
// export const REMOVE_ORGANIZATION_SUCCESS = 'REMOVE_ORGANIZATION_SUCCESS';

//FETCH_ORGANIZATIONS
export const FETCH_ORGANIZATIONS = "FETCH_ORGANIZATIONS";
export const FETCH_ORGANIZATIONS_SUCCESS = "FETCH_ORGANIZATIONS_SUCCESS";
export const FETCH_ORGANIZATIONS_FAILED = "FETCH_ORGANIZATIONS_FAILED";
export const FETCH_ORGANIZATIONS_NEXT = "FETCH_ORGANIZATIONS_NEXT";
export const FETCH_ORGANIZATIONS_NEXT_SUCCESS =
  "FETCH_ORGANIZATIONS_NEXT_SUCCESS";
export const FETCH_ORGANIZATIONS_NEXT_FAILED =
  "FETCH_ORGANIZATIONS_NEXT_FAILED";

//CREATE_ORGANIZATION
export const CREATE_ORGANIZATION = "CREATE_ORGANIZATION";
export const CREATE_ORGANIZATION_SUCCESS = "CREATE_ORGANIZATION_SUCCESS";
export const CREATE_ORGANIZATION_FAILED = "CREATE_ORGANIZATION_FAILED";

//UPDATE_ORGANIZATION
export const UPDATE_ORGANIZATION = "UPDATE_ORGANIZATION";
export const UPDATE_ORGANIZATION_SUCCESS = "UPDATE_ORGANIZATION_SUCCESS";
export const UPDATE_ORGANIZATION_FAILED = "UPDATE_ORGANIZATION_FAILED";

//DELETE_ORGANIZATION
export const DELETE_ORGANIZATION = "DELETE_ORGANIZATION";
export const DELETE_ORGANIZATION_SUCCESS = "DELETE_ORGANIZATION_SUCCESS";
export const DELETE_ORGANIZATION_FAILED = "DELETE_ORGANIZATION_FAILED";

//UPLOAD_ORGANIZATION_FILE
export const REMOVE_ORGANIZATION_FILE_UPLOAD_ERROR =
  "REMOVE_ORGANIZATION_FILE_UPLOAD_ERROR";
export const UPLOAD_NEXT_ORGANIZATION_FILE = "UPLOAD_NEXT_ORGANIZATION_FILE";
export const UPLOAD_ORGANIZATION_FILE = "UPLOAD_ORGANIZATION_FILE";
export const UPLOAD_ORGANIZATION_FILE_SUCCESS =
  "UPLOAD_ORGANIZATION_FILE_SUCCESS";
export const UPLOAD_ORGANIZATION_FILE_FAILED =
  "UPLOAD_ORGANIZATION_FILE_FAILED";

//RESEND_MEMBER_INVITATION
export const RESEND_MEMBER_INVITATION = "RESEND_MEMBER_INVITATION";
export const RESEND_MEMBER_INVITATION_SUCCESS =
  "RESEND_MEMBER_INVITATION_SUCCESS";
export const RESEND_MEMBER_INVITATION_FAILED =
  "RESEND_MEMBER_INVITATION_FAILED";

//FETCH_ORGANIZATION_MEMBERSHIP
export const FETCH_ORGANIZATION_MEMBERSHIP = "FETCH_ORGANIZATION_MEMBERSHIP";
export const FETCH_ORGANIZATION_MEMBERSHIP_SUCCESS =
  "FETCH_ORGANIZATION_MEMBERSHIP_SUCCESS";
export const FETCH_ORGANIZATION_MEMBERSHIP_FAILED =
  "FETCH_ORGANIZATION_MEMBERSHIP_FAILED";

//FETCH_ORGANIZATION_ROLES
export const FETCH_ORGANIZATION_ROLES = "FETCH_ORGANIZATION_ROLES";
export const FETCH_ORGANIZATION_ROLES_SUCCESS =
  "FETCH_ORGANIZATION_ROLES_SUCCESS";
export const FETCH_ORGANIZATION_ROLES_FAILED =
  "FETCH_ORGANIZATION_ROLES_FAILED";

//CREATE_ORGANIZATION_MEMBERSHIP
export const CREATE_ORGANIZATION_MEMBERSHIP = "CREATE_ORGANIZATION_MEMBERSHIP";
export const CREATE_ORGANIZATION_MEMBERSHIP_SUCCESS =
  "CREATE_ORGANIZATION_MEMBERSHIP_SUCCESS";
export const CREATE_ORGANIZATION_MEMBERSHIP_FAILED =
  "CREATE_ORGANIZATION_MEMBERSHIP_FAILED";

//UPDATE_ORGANIZATION_MEMBERSHIP
export const UPDATE_ORGANIZATION_MEMBERSHIP = "UPDATE_ORGANIZATION_MEMBERSHIP";
export const UPDATE_ORGANIZATION_MEMBERSHIP_SUCCESS =
  "UPDATE_ORGANIZATION_MEMBERSHIP_SUCCESS";
export const UPDATE_ORGANIZATION_MEMBERSHIP_FAILED =
  "UPDATE_ORGANIZATION_MEMBERSHIP_FAILED";

//DELETE_ORGANIZATION_MEMBERSHIP
export const DELETE_ORGANIZATION_MEMBERSHIP = "DELETE_ORGANIZATION_MEMBERSHIP";
export const DELETE_ORGANIZATION_MEMBERSHIP_SUCCESS =
  "DELETE_ORGANIZATION_MEMBERSHIP_SUCCESS";
export const DELETE_ORGANIZATION_MEMBERSHIP_FAILED =
  "DELETE_ORGANIZATION_MEMBERSHIP_FAILED";

//FETCH_ORGANIZATION_CONTENT
export const FETCH_ORGANIZATION_CONTENT = "FETCH_ORGANIZATION_CONTENT";
export const FETCH_ORGANIZATION_CONTENT_SUCCESS =
  "FETCH_ORGANIZATION_CONTENT_SUCCESS";
export const FETCH_ORGANIZATION_CONTENT_FAILED =
  "FETCH_ORGANIZATION_CONTENT_FAILED";

//CREATE_ORGANIZATION_CONTENT
export const CREATE_ORGANIZATION_CONTENT = "CREATE_ORGANIZATION_CONTENT";
export const CREATE_ORGANIZATION_CONTENT_SUCCESS =
  "CREATE_ORGANIZATION_CONTENT_SUCCESS";
export const CREATE_ORGANIZATION_CONTENT_FAILED =
  "CREATE_ORGANIZATION_CONTENT_FAILED";

//Documentation
export const FETCH_API_DOCUMENTATION = "FETCH_API_DOCUMENTATION";
export const FETCH_API_DOCUMENTATION_FAILED = "FETCH_API_DOCUMENTATION_FAILED";
export const STORE_API_DOCUMENTATION = "STORE_API_DOCUMENTATION";

//DOCUMENTATION_INFO
export const FETCH_DOCUMENTATION_BY_ALIAS = "FETCH_DOCUMENTATION_BY_ALIAS";
export const FETCH_DOCUMENTATION_BY_ALIAS_SUCCESS =
  "FETCH_DOCUMENTATION_BY_ALIAS_SUCCESS";
export const FETCH_DOCUMENTATION_BY_ALIAS_FAILED =
  "FETCH_DOCUMENTATION_BY_ALIAS_FAILED";

//FAQ's
export const FETCH_FAQS = "FETCH_FAQS";
export const FETCH_FAQS_FAILED = "FETCH_FAQS_FAILED";
export const STORE_FAQS = "STORE_FAQS";
export const SEARCH_FAQ = "SEARCH_FAQ";

//BREADCRUMB
export const UPDATE_BREADCRUMB = "UPDATE_BREADCRUMB";

//Application
export const CREATE_APPLICATION = "CREATE_APPLICATION";
export const CREATE_APPLICATION_SUCCESS = "CREATE_APPLICATION_SUCCESS";
export const CREATE_APPLICATION_FAILED = "CREATE_APPLICATION_FAILED";
export const PREPARE_EDIT_APPLICATION = "PREPARE_EDIT_APPLICATION";
export const EDIT_APPLICATION = "EDIT_APPLICATION";
export const EDIT_APPLICATION_SUCCESS = "EDIT_APPLICATION_SUCCESS";
export const EDIT_APPLICATION_FAILED = "EDIT_APPLICATION_FAILED";
export const SUBSCRIBE_API_PRODUCT_TO_APPLICATION =
  "SUBSCRIBE_API_PRODUCT_TO_APPLICATION";
export const RESET_SUBSCRIPTION_MESSAGE = "RESET_SUBSCRIPTION_MESSAGE";
export const SUBSCRIBE_API_PRODUCT_TO_APPLICATION_SUCCESS =
  "SUBSCRIBE_API_PRODUCT_TO_APPLICATION_SUCCESS";
export const SUBSCRIBE_API_PRODUCT_TO_APPLICATION_FAILED =
  "SUBSCRIBE_API_PRODUCT_TO_APPLICATION_FAILED";
export const DELETE_APPLICATION = "DELETE_APPLICATION";
export const DELETE_APPLICATION_SUCCESS = "DELETE_APPLICATION_SUCCESS";
export const DELETE_APPLICATION_FAILED = "DELETE_APPLICATION_FAILED";
export const OPEN_DELETE_APPLICATION_MODAL = "OPEN_DELETE_APPLICATION_MODAL";
export const CLOSE_DELETE_APPLICATION_MODAL = "CLOSE_DELETE_APPLICATION_MODAL";
export const OPEN_UNSUBSCRIBE_FROM_API_PRODUCT_MODAL =
  "OPEN_UNSUBSCRIBE_FROM_API_PRODUCT_MODAL";
export const CLOSE_UNSUBSCRIBE_FROM_API_PRODUCT_MODAL =
  "CLOSE_UNSUBSCRIBE_FROM_API_PRODUCT_MODAL";
export const UNSUBSCRIBE_FROM_API_PRODUCT = "UNSUBSCRIBE_FROM_API_PRODUCT";
export const UNSUBSCRIBE_FROM_API_PRODUCT_SUCCESS =
  "UNSUBSCRIBE_FROM_API_PRODUCT_SUCCESS";
export const UNSUBSCRIBE_FROM_API_PRODUCT_FAILED =
  "UNSUBSCRIBE_FROM_API_PRODUCT_FAILED";
export const RESET_APPLICATION_CLIENT_SECRET =
  "RESET_APPLICATION_CLIENT_SECRET";
export const RESET_APPLICATION_CLIENT_SECRET_SUCCESS =
  "RESET_APPLICATION_CLIENT_SECRET_SUCCESS";
export const RESET_APPLICATION_CLIENT_SECRET_FAILED =
  "RESET_APPLICATION_CLIENT_SECRET_FAILED";
export const OPEN_APPLICATION_MODAL = "OPEN_APPLICATION_MODAL";
export const CLOSE_APPLICATION_MODAL = "CLOSE_APPLICATION_MODAL";
export const DELETE_APPLICATION_CLIENT_SECRET =
  "DELETE_APPLICATION_CLIENT_SECRET";

//Tags
export const UPDATE_OAUTH_TAG = "UPDATE_OAUTH_TAG";
export const UPDATE_POST_LOGOUT_TAG = "UPDATE_POST_LOGOUT_TAG";
export const UPDATE_CREATE_APPLICATION_FORM_FIELD =
  "UPDATE_CREATE_APPLICATION_FORM_FIELD";
export const CLEAR_APPLICATION_FORM_STATE = "CLEAR_APPLICATION_FORM_STATE";

// Support Tickets
export const FETCH_TICKETS = "FETCH_TICKETS";
export const FETCH_TICKET_ID = "FETCH_TICKET_ID";
export const STORE_TICKETS = "STORE_TICKETS";
export const STORE_TICKET_ID = "STORE_TICKET_ID";
export const FETCH_TICKETS_FAILED = "FETCH_TICKETS_FAILED";
export const FETCH_TICKET_ID_FAILED = "FETCH_TICKET_ID_FAILED";
export const CREATE_TICKET = "CREATE_TICKET";
export const CREATE_TICKET_SUCCEED = "CREATE_TICKET_SUCCEED";
export const CREATE_TICKET_FAILED = "CREATE_TICKET_FAILED";
export const UPDATE_TICKET_FORM_FIELD = "UPDATE_TICKET_FORM_FIELD";
export const CLEAR_TICKET_FORM = "CLEAR_TICKET_FORM";
export const SAVE_TICKET = "SAVE_TICKET";
export const SAVE_TICKET_SUCCEED = "SAVE_TICKET_SUCCEED";
export const SAVE_TICKET_FAILED = "SAVE_TICKET_FAILED";
export const UPDATE_TICKET = "UPDATE_TICKET";
export const UPDATE_TICKET_SUCCEED = "UPDATE_TICKET_SUCCEED";
export const UPDATE_TICKET_FAILED = "UPDATE_TICKET_FAILED";
export const UPDATE_TICKET_FORM_ID_FIELD = "UPDATE_TICKET_FORM_ID_FIELD";
export const CLEAR_TICKET_FORM_ID = "CLEAR_TICKET_FORM_ID";

// FORUM
export const FETCH_FORUMS = "FETCH_FORUMS";
export const STORE_FORUMS = "STORE_FORUMS";
export const FETCH_FORUMS_FAILED = "FETCH_FORUMS_FAILED";

export const CLEAR_FORUM_TOPIC = "CLEAR_FORUM_TOPIC";

export const FETCH_FORUM_TOPICS = "FETCH_FORUM_TOPICS";
export const STORE_FORUM_TOPICS = "STORE_FORUM_TOPICS";
export const CLEAR_FORUM_TOPICS = "CLEAR_FORUM_TOPICS";
export const FETCH_FORUM_TOPICS_FAILED = "FETCH_FORUM_TOPICS_FAILED";

export const FETCH_FORUM_TOPIC_ID = "FETCH_FORUM_TOPIC_ID";
export const STORE_FORUM_TOPIC_ID = "STORE_FORUM_TOPIC_ID";
export const FETCH_FORUM_TOPIC_ID_FAILED = "FETCH_FORUM_ID_FAILED";

export const CREATE_FORUM_TOPIC = "CREATE_FORUM_TOPIC";
export const CREATE_FORUM_TOPIC_SUCCESS = "CREATE_FORUM_TOPIC_SUCCESS";
export const CREATE_FORUM_TOPIC_FAILED = "CREATE_FORUM_TOPIC_FAILED";
export const UPDATE_FORUM_TOPIC_FORM_FIELD = "UPDATE_FORUM_TOPIC_FORM_FIELD";

export const FETCH_FORUM_TOPIC_COMMENTS = "FETCH_FORUM_TOPIC_COMMENTS";
export const STORE_FORUM_TOPIC_COMMENTS = "STORE_FORUM_TOPIC_COMMENTS";
export const FETCH_FORUM_TOPIC_COMMENTS_FAILED = "FETCH_FORUM_COMMENTS_FAILED";

export const UPDATE_FORUM_FIELD = "UPDATE_FORUM_FIELD";
export const UPDATE_FORUM_COMMENT_FIELD = "UPDATE_FORUM_COMMENT_FIELD";
export const SAVE_FORUM_COMMENT = "SAVE_FORUM_COMMENT";
export const SAVE_FORUM_COMMENT_SUCCEED = "SAVE_FORUM_COMMENT_SUCCEED";
export const SAVE_FORUM_COMMENT_FAILED = "SAVE_FORUM_COMMENT_FAILED";
export const SEARCH_FORUMS_AND_TOPICS = "SEARCH_FORUMS_AND_TOPICS";
export const STORE_FORUM_TOPICS_AFTER_SEARCH =
  "STORE_FORUM_TOPICS_AFTER_SEARCH";

//SUBSCRIPTION_STATUS
export const CHANGE_SUBSCRIPTION_STATUS = "CHANGE_SUBSCRIPTION_STATUS";
export const CHANGE_SUBSCRIPTION_STATUS_SUCCESS =
  "CHANGE_SUBSCRIPTION_STATUS_SUCCESS";
export const CHANGE_SUBSCRIPTION_STATUS_FAILED =
  "CHANGE_SUBSCRIPTION_STATUS_FAILED";

export const FETCH_SUBSCRIPTION_STATUS = "FETCH_SUBSCRIPTION_STATUS";
export const FETCH_SUBSCRIPTION_STATUS_SUCCESS =
  "FETCH_SUBSCRIPTION_STATUS_SUCCESS";
export const FETCH_SUBSCRIPTION_STATUS_FAILED =
  "FETCH_SUBSCRIPTION_STATUS_FAILED";
export const SEARCH_COMMENT_IN_TOPIC = "SEARCH_COMMENT_IN_TOPIC";

// GENERAL
export const GET_WEBSITE_STATIC_CONTENT = "GET_WEBSITE_STATIC_CONTENT";
export const STORE_WEBSITE_STATIC_CONTENT = "STORE_WEBSITE_STATIC_CONTENT";
export const GET_WEBSITE_STATIC_CONTENT_FAILED =
  "GET_WEBSITE_STATIC_CONTENT_FAILED";

// FAB
export const INC_FAB_COUNT = "INC_FAB_COUNT";
export const DEC_FAB_COUNT = "DEC_FAB_COUNT";

// ARTICLES
export const FETCH_ARTICLES = "FETCH_ARTICLES";
export const STORE_ARTICLES = "STORE_ARTICLES";
export const FETCH_ARTICLES_FAILED = "FETCH_ARTICLES_FAILED";
export const SEARCH_ARTICLES = "SEARCH_ARTICLES";

// ARTICLES CATEGORY
export const FETCH_ARTICLES_CATEGORY = "FETCH_ARTICLES_CATEGORY";
export const STORE_ARTICLES_CATEGORY = "STORE_ARTICLES_CATEGORY";
export const FETCH_ARTICLES_CATEGORY_FAILED = "FETCH_ARTICLES_CATEGORY_FAILED";
export const ADD_CATEGORY_FILTER = "ADD_CATEGORY_FILTER";
export const REMOVE_CATEGORY_FILTER = "REMOVE_CATEGORY_FILTER";
export const CLEAR_CATEGORY_FILTER = "CLEAR_CATEGORY_FILTER";

//GO LIVE FORM
export const UPDATE_GO_LIVE_FORM_FIELD = "UPDATE_GO_LIVE_FORM_FIELD";
export const CLEAR_GO_LIVE_FORM_STATE = "CLEAR_GO_LIVE_FORM_STATE";
export const CREATE_GO_LIVE_FORM = "CREATE_GO_LIVE_FORM";
export const CREATE_GO_LIVE_FORM_SUCCESS = "CREATE_GO_LIVE_FORM_SUCCESS";
export const CREATE_GO_LIVE_FORM_FAILED = "CREATE_GO_LIVE_FORM_FAILED";
export const GET_LIVE_FORMS = "GET_LIVE_FORMS";
export const GET_LIVE_FORMS_FAILED = "GET_LIVE_FORMS_FAILED";
export const STORE_LIVE_FORMS = "STORE_LIVE_FORMS";

//COOKIES
export const FETCH_COOKIE_CONSENT = "FETCH_COOKIE_CONSENT";
export const STORE_COOKIE_CONSENT = "STORE_COOKIE_CONSENT";
export const FETCH_COOKIE_CONSENT_FAILED = "FETCH_COOKIE_CONSENT_FAILED";
export const COOKIE_CONSENT_ACTION = "COOKIE_CONSENT_ACTION";

//DATETIME
export const REQUEST_DATETIME = "REQUEST_DATETIME";
export const REQUEST_DATETIME_COMPLETE = "REQUEST_DATETIME_COMPLETE";

//BASIC PAGE
export const FETCH_BASIC_PAGE = "FETCH_BASIC_PAGE";
export const STORE_BASIC_PAGE = "STORE_BASIC_PAGE";
export const FETCH_BASIC_PAGE_FAILED = "FETCH_BASIC_PAGE_FAILED";
export const FETCH_DRIVE_PAGE = "FETCH_DRIVE_PAGE";
export const FETCH_DRIVE_PAGE_SUCCESS = "FETCH_DRIVE_PAGE_SUCCESS";
export const FETCH_DRIVE_PAGE_FAILED = "FETCH_DRIVE_PAGE_FAILED";

//SEARCH
export const FETCH_SEARCH_RESULTS = "FETCH_SEARCH_RESULTS";
export const STORE_SEARCH_RESULTS = "STORE_SEARCH_RESULTS";
export const FETCH_SEARCH_RESULTS_FAILED = "FETCH_SEARCH_RESULTS_FAILED";
export const UPDATE_SEARCH_CATEGORY = "UPDATE_SEARCH_CATEGORY";
export const CLEAR_PAGER = "CLEAR_PAGER";
export const CLEAR_SEARCH_RESULTS = "CLEAR_SEARCH_RESULTS";
export const CLEAR_FORUM_SEARCH_TEXT = "CLEAR_FORUM_SEARCH_TEXT";

//SEARCH
export const REDOC_TRY_OUT_SEND = "REDOC_TRY_OUT_SEND";
export const REDOC_TRY_OUT_SEND_SUCCESS = "REDOC_TRY_OUT_SEND_SUCCESS";
export const REDOC_TRY_OUT_SEND_FAILED = "REDOC_TRY_OUT_SEND_FAILED";

//EXCECUTE_TERMINAL_COMMAND
export const EXCECUTE_TERMINAL_COMMAND = "EXCECUTE_TERMINAL_COMMAND";
export const CANCEL_TERMINAL_COMMAND = "CANCEL_TERMINAL_COMMAND";
export const EXCECUTE_TERMINAL_COMMAND_SUCCESS =
  "EXCECUTE_TERMINAL_COMMAND_SUCCESS";
export const CANCEL_TERMINAL_COMMAND_SUCCESS =
  "CANCEL_TERMINAL_COMMAND_SUCCESS";

//CREATE_TERMINAL
export const CREATE_TERMINAL = "CREATE_TERMINAL";
export const DELETE_TERMINAL = "DELETE_TERMINAL";
export const UPDATE_TERMINAL = "UPDATE_TERMINAL";
export const CLEAR_TERMINAL_HISTORY = "CLEAR_TERMINAL_HISTORY";
export const MINIFY_TERMINAL = "MINIFY_TERMINAL";
export const CREATE_TERMINAL_APPLICATION = "CREATE_TERMINAL_APPLICATION";
export const RESET_TERMINAL_APPLICATION = "RESET_TERMINAL_APPLICATION";
export const CREATE_TERMINAL_APPLICATION_SUCCESS =
  "CREATE_TERMINAL_APPLICATION_SUCCESS";
export const CREATE_TERMINAL_APPLICATION_FAILED =
  "CREATE_TERMINAL_APPLICATION_FAILED";
export const TERMINAL_CHANGE_ACTIVE_DIRECTORY =
  "TERMINAL_CHANGE_ACTIVE_DIRECTORY";
export const CREATE_TERMINAL_FORUM_TOPIC = "CREATE_TERMINAL_FORUM_TOPIC";
export const CREATE_TERMINAL_FORUM_TOPIC_SUCCESS =
  "CREATE_TERMINAL_FORUM_TOPIC_SUCCESS";
export const CREATE_TERMINAL_FORUM_TOPIC_FAILED =
  "CREATE_TERMINAL_FORUM_TOPIC_FAILED";

//TRYOUT
export const CREATE_TRYOUT = "CREATE_TRYOUT";
export const UPDATE_TRYOUT = "UPDATE_TRYOUT";
export const SHOW_TRYOUT = "SHOW_TRYOUT";
export const TRYOUT_RESPONSE_CLEAR = "TRYOUT_RESPONSE_CLEAR";
export const CLEAR_TRYOUT_HISTORY = "CLEAR_TRYOUT_HISTORY";
export const DELETE_TRYOUT_HISTORY_ITEM = "DELETE_TRYOUT_HISTORY_ITEM";
export const TRYOUT_SET_AUTH_CODE = "TRYOUT_SET_AUTH_CODE";
export const TRYOUT_CLEAR_AUTH_CODE = "TRYOUT_CLEAR_AUTH_CODE";
export const FAVORITE_TRYOUT_HISTORY_ITEM = "FAVORITE_TRYOUT_HISTORY_ITEM";
export const TRYOUT_REQUEST_COMPLETED = "TRYOUT_REQUEST_COMPLETED";
export const TRYOUT_FETCH_SUGGESTIONS = "TRYOUT_FETCH_SUGGESTIONS";
export const IMPORT_TRYOUT_HISTORY = "IMPORT_TRYOUT_HISTORY";
export const EDIT_TRYOUT_DESCRIPTION = "EDIT_TRYOUT_DESCRIPTION";
export const TRYOUT_GET_ACCESS_TOKEN = "TRYOUT_GET_AUTH_TOKEN";
export const STORE_TRYOUT_ACCESS_TOKEN = "STORE_TRYOUT_ACCESS_TOKEN";
export const STORE_TRYOUT_ACCESS_TOKEN_FAILED =
  "STORE_TRYOUT_ACCESS_TOKEN_FAILED";
export const TRYOUT_CLEAR_ACCESS_TOKEN = "TRYOUT_CLEAR_ACCESS_TOKEN";


// GETTING STARTED
export const FETCH_GETTING_STARTED = "FETCH_GETTING_STARTED";
export const GETTING_STARTED_FAILED = "GETTING_STARTED_FAILED"
export const STORE_GETTING_STARTED = "STORE_GETTING_STARTED"
