import type {
  JsonApiErrorResponse,
  JsonApiItem,
  JsonApiItemCollection,
  JsonApiResponse,
} from "../jsonapi_helpers";
import { mapImageDatum } from "./image";
import { mapFileDatum } from "./file";
import {
  getJsonApiDataById,
  getJsonApiRelationshipData,
} from "../jsonapi_helpers";
import type { Image } from "./image";
import { CONTENT_TYPE_APPLICATION } from "../organizationRoles";
import { mapApplicationDatum } from "./application";
import v4 from "uuid";

export type Organization = {
  uuid: string,
  id: string,
  uid: string,
  title: string,
  description: string,
  created: Date,
  documents: Object,
  image: Image,
  members: Array<Member>,
};

export type Member = {
  uuid: string,
  id: string,
  membershipUuid: string,
  userName: string,
  created: Date,
  changed: Date,
  roles: Array<string>,
  status: string,
};

export type Role = {
  uuid: string,
  id: string,
  permissions: Array<string>,
};

export const mapOrganizationData = (unMappedData: JsonApiResponse) => {
  if (!unMappedData) return;
  const { data, included } = unMappedData;
  return data.reduce((previous, datum) => {
    const mappedDatum = mapOrganizationDatum(datum, included);
    return mappedDatum && mappedDatum.uuid
      ? {
          ...previous,
          [mappedDatum.uuid]: mappedDatum,
        }
      : previous;
  }, {});
};

const mapOrganizationDatum: Organization = (
  datum: JsonApiItem,
  included: Array<JsonApiItem>
) => {
  if (!datum) return;
  const { drupal_internal__id, field_body, created, label } = datum.attributes;
  const { field_documents, field_image, uid } = datum.relationships;
  return {
    id: drupal_internal__id,
    uid: uid.data.id,
    uuid: datum.id,
    title: label,
    description: field_body && field_body.value ? field_body.value : "",
    created: new Date(created),
    image: mapImageDatum(field_image, included, true),
    documents: mapFileDatum(field_documents, included),
  };
};

export const mapOrganizationMembers: Array<Member> = (
  unMappedData: JsonApiResponse
) => {
  if (!unMappedData) return {};
  const { data, included } = unMappedData;
  return data.reduce((previous, datum) => {
    const mappedDatum = mapOrganizationMember(datum, included);
    return mappedDatum && mappedDatum.membershipUuid
      ? {
          ...previous,
          [mappedDatum.membershipUuid]: mappedDatum,
        }
      : previous;
  }, {});
};

export const mapOrganizationMember: Member = (
  datum: JsonApiItem,
  included: Array<JsonApiItem>
) => {
  if (!datum) return;
  const {
    drupal_internal__id,
    changed,
    created,
    label,
    field_status,
  } = datum.attributes;
  const { group_roles, entity_id } = datum.relationships;
  const uuid = entity_id.data.id;
  const user = getJsonApiDataById(uuid, included);
  return {
    uuid,
    id: drupal_internal__id,
    status: field_status,
    membershipUuid: datum.id,
    userName: user.attributes.display_name
      ? user.attributes.display_name
      : user.attributes.name,
    created: new Date(created),
    changed: new Date(changed),
    roles: mapMemberRoles(
      getJsonApiRelationshipData(group_roles.data, included)
    ),
  };
};

const mapMemberRoles: Array<string> = (data: JsonApiItemCollection) => {
  if (!data || !data.length) return [];
  return data.map((datum) => {
    const { drupal_internal__id } = datum.attributes; //same role id on drupal - react
    return drupal_internal__id;
  });
};

export const mapOrganizationRoles = (unMappedData: JsonApiResponse) => {
  if (!unMappedData) return;
  const { data, included } = unMappedData;
  return data.reduce((previous, datum) => {
    const mappedDatum = mapOrganizationRole(datum, included);
    return mappedDatum && mappedDatum.id
      ? {
          ...previous,
          [mappedDatum.id]: mappedDatum,
        }
      : previous;
  }, {});
};

const mapOrganizationRole: Role = (
  datum: JsonApiItem,
  included: Array<JsonApiItem>
) => {
  if (!datum) return;
  const { drupal_internal__id, permissions } = datum.attributes;
  return {
    uuid: datum.id,
    id: drupal_internal__id,
    permissions,
  };
};

export const mapOrganizationContent: Array<Object> = (
  unMappedData: JsonApiResponse
) => {
  if (!unMappedData) return {};
  const { data, included } = unMappedData;
  return data.reduce((previous, contentEntity) => {
    const datum = getJsonApiDataById(
      contentEntity.relationships.entity_id.data.id,
      included
    );
    let mappedDatum;
    switch (datum.type) {
      case CONTENT_TYPE_APPLICATION:
        mappedDatum = mapApplicationDatum({
          data: datum,
          included,
        });
        break;
      default:
        mappedDatum = undefined;
        break;
    }

    return mappedDatum && mappedDatum.uuid
      ? {
          ...previous,
          [datum.type]: {
            ...previous[datum.type],
            [mappedDatum.uuid]: mappedDatum,
          },
        }
      : previous;
  }, {});
};

export const mapFileUploadErrors = (
  unMappedData: JsonApiErrorResponse,
  fileName
) => {
  if (!(unMappedData && unMappedData.errors)) {
    const errorId = v4();
    return {
      [errorId]: {
        title: "Could not upload file",
        detail: "Please check that the file extension is allowed.",
        fileName,
        uuid: errorId,
        created: Date.now(),
      },
    };
  }
  return unMappedData.errors.reduce((previous, datum) => {
    const { title, detail } = datum;
    const mappedDatum = {
      title,
      detail,
      fileName,
      uuid: v4(),
      created: Date.now(),
    };
    return {
      ...previous,
      [mappedDatum.uuid]: mappedDatum,
    };
  }, {});
};

export const mapStatusMessage = (message, type) => {
  return {
    uuid: v4(),
    created: Date.now(),
    description: message,
    type: type,
  };
};
