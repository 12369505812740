// @flow
import React from "react";
import { connect } from "react-redux";
import withTracker from "./withtracker";
import AuthorizationHOC from "./AuthorizationHOC";
import { REGISTERED_USERS } from "../constants/userGroups";
import * as actions from "../actions";
import withBreadCrumb from "./withBreadcrumb";
import Breadcrumb from "../components/breadcrumb/breadcrumb";
import { parseOrganization } from "../constants/parse/organization";
import MetaData from "../components/meta/meta";
import { FRONTEND_ORIGIN_URL_NO_SLASH } from "../config";
import { parseApplications } from "../constants/parse/application";
import { CONTENT_TYPE_APPLICATION } from "../constants/organizationRoles";
import { getRouteUrl } from "../routers";
import { ROUTE_ORGANIZATION_VIEW_CONTENT } from "../routers/content/organization";
import OrganizationContent from "../components/organization/organizationContent/organizationContent";

type Props = {
  match: {
    params: {
      organizationId: string,
    },
  },
  breadcrumb: Object,
  organizations: Object,
  organizationsToUpdate: Object,
  breadcrumb: Object,
  match: Object,
  fetchOrganizationMemberships: Function,
  fetchOrganizations: Function,
  fetchOrganizationContent: Function,
  updateOrganization: Function,
  loading: boolean,
  loadingContent: boolean,
};

class OrganizationContentHOC extends React.Component<Props> {
  componentDidMount() {
    const { organizations, match } = this.props;
    const { organizationId } = match.params;
    if (organizations.hasIn([organizationId])) {
      this.props.fetchOrganizationContent({
        uuid: organizationId,
        id: organizations.getIn([organizationId, "id"]),
      });
    } else if (!organizations.hasIn([organizationId])) {
      this.props.fetchOrganizations({
        uuid: organizationId,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { organizations, match } = this.props;
    const { organizationId } = match.params;
    if (
      (nextProps.organizations.hasIn([organizationId]) &&
        !organizations.hasIn([organizationId])) ||
      nextProps.organizationsToUpdate.get(organizationId) !==
        this.props.organizationsToUpdate.get(organizationId)
    ) {
      this.props.fetchOrganizationContent({
        uuid: organizationId,
        id: nextProps.organizations.getIn([organizationId, "id"]),
      });
      this.props.fetchOrganizationMemberships({
        uuid: organizationId,
        id: nextProps.organizations.getIn([organizationId, "id"]),
      });
    }
  }

  updateOrganizationDocuments = ({ uuid, documents, field_documents }) => {
    this.props.updateOrganization({
      uuid,
      documents,
      field_documents,
    });
  };

  render() {
    const {
      organizations,
      organizationsToUpdate,
      breadcrumb,
      match,
      loading,
      loadingContent,
    } = this.props;
    const { organizationId } = match.params;
    const organizationData = parseOrganization(
      organizations.get(organizationId)
    );
    return (
      <div>
        {organizationData && (
          <div>
            <MetaData
              title="Organization"
              url={
                FRONTEND_ORIGIN_URL_NO_SLASH +
                getRouteUrl(ROUTE_ORGANIZATION_VIEW_CONTENT, {
                  organizationId: organizationData.uuid,
                })
              }
            />
            <Breadcrumb
              {...breadcrumb}
              params={{
                org: {
                  name: organizationData.title,
                },
              }}
            />
            <OrganizationContent
              loading={loading}
              loadingContent={loadingContent}
              updating={organizationsToUpdate.get(organizationData.uuid)}
              organization={organizationData}
              updateDocuments={this.updateOrganizationDocuments}
              applications={parseApplications(
                organizations.getIn([
                  organizationId,
                  "content",
                  CONTENT_TYPE_APPLICATION,
                ])
              )}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.organizationReducer.get("loading"),
  loadingContent: state.organizationReducer.get("loadingOrganizationContent"),
  organizations: state.organizationReducer.get("byId"),
  organizationsToUpdate: state.organizationFormReducer.get(
    "organizationsToUpdate"
  ),
});

const mapDispatchToProps = (dispatch) => ({
  fetchOrganizationContent: (payload) => {
    dispatch(actions.fetchOrganizationContent(payload));
  },
  fetchOrganizations: (payload) => {
    dispatch(actions.fetchOrganizations(payload));
  },
  updateOrganization: (payload) => {
    dispatch(actions.updateOrganization(payload));
  },
  fetchOrganizationMemberships: (payload) => {
    dispatch(actions.fetchOrganizationMemberships(payload));
  },
});

export default withTracker(
  AuthorizationHOC(REGISTERED_USERS)(
    withBreadCrumb(
      connect(mapStateToProps, mapDispatchToProps)(OrganizationContentHOC),
      {
        name: "{org}",
        parents: ["Organizations"],
      }
    )
  )
);
