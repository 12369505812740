// @flow
import React from "react";
import Support from "../components/support/support";
import ContactFormHOC from "./ContactFormHOC";
import withTracker from "./withtracker";
import AuthorizationHOC from "./AuthorizationHOC";
import { ALL_USERS } from "../constants/userGroups";
import withBreadCrumb from "./withBreadcrumb";

type Props = {};

class SupportHOC extends React.Component<Props> {
  render() {
    return (
      <div>
        <Support />
        <ContactFormHOC />
      </div>
    );
  }
}

export default withTracker(
  AuthorizationHOC(ALL_USERS)(
    withBreadCrumb(SupportHOC, {
      name: "Support",
    })
  )
);
