import dependencies from "../authentication/dependencies";

const { baseUrl, generalHeader } = dependencies;

export const fetchTickets = async (csrfToken) => {
  const res = await fetch(`${baseUrl}/api/support_ticket?_format=json`, {
    headers: generalHeader(csrfToken, true),
    credentials: "include",
  });
  return res.json();
};

export const fetchTicketById = async (csrfToken, id) => {
  const res = await fetch(`${baseUrl}/api/support_ticket/${id}?_format=json`, {
    headers: generalHeader(csrfToken, true),
    credentials: "include",
  });
  return res.json();
};

export const updateTicketById = async (csrfToken, uuid, data) => {
  const response = await fetch(`${baseUrl}/api/support_ticket/${uuid}`, {
    method: "PATCH",
    headers: generalHeader(csrfToken, true),
    credentials: "include",
    body: JSON.stringify(data),
  });
  return response.json();
};

export const createTicket = async (csrfToken, data) => {
  const response = await fetch(`${baseUrl}/api/support_ticket/create`, {
    method: "POST",
    headers: generalHeader(csrfToken, true),
    credentials: "include",
    body: JSON.stringify(data),
  });
  return response.json();
};
