// @flow
import React from "react";
import { connect } from "react-redux";
import withTracker from "./withtracker";
import AuthorizationHOC from "./AuthorizationHOC";
import { REGISTERED_USERS } from "../constants/userGroups";
import OrganizationMembers from "../components/organizationMembers/organizationMembers";
import Breadcrumb from "../components/breadcrumb/breadcrumb";
import withBreadCrumb from "./withBreadcrumb";
import * as actions from "../actions";
import FaSpinner from "../components/faSpinner/faSpinner";
import { parseOrganization } from "../constants/parse/organization";
import MetaData from "../components/meta/meta";
import { getRouteUrl } from "../routers";
import {
  ROUTE_ORGANIZATION_VIEW_MEMBERS,
  ROUTE_ORGANIZATION_VIEW_SINGLE,
} from "../routers/content/organization";

type Props = {
  userName: string,
  organizations: Object,
  loading: boolean,
  breadcrumb: Object,
  match: {
    params: {
      organizationId: string,
    },
  },
  fetchOrganizationMemberships: Function,
  fetchOrganizations: Function,
  fetchOrganizationRoles: Function,
};

class OrganizationMembersHOC extends React.Component<Props> {
  componentDidMount() {
    const { organizations, match } = this.props;
    const { organizationId } = match.params;

    if (
      organizations.hasIn([organizationId]) &&
      !organizations.getIn([organizationId, "members"])
    ) {
      this.props.fetchOrganizationMemberships({
        uuid: organizationId,
        id: organizations.getIn([organizationId, "id"]),
      });
    } else if (!organizations.hasIn([organizationId])) {
      this.props.fetchOrganizations({
        uuid: organizationId,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { organizations, match } = this.props;
    const { organizationId } = match.params;

    if (
      nextProps.organizations.hasIn([organizationId]) &&
      !organizations.hasIn([organizationId]) &&
      !nextProps.organizations.getIn([organizationId, "members"])
    ) {
      this.props.fetchOrganizationMemberships({
        uuid: organizationId,
        id: nextProps.organizations.getIn([organizationId, "id"]),
      });
    }
  }

  render() {
    const { breadcrumb, organizations, loading, userName, match } = this.props;
    const { organizationId } = match.params;
    const organizationData = parseOrganization(
      organizations.get(organizationId)
    );
    return (
      <div>
        {organizationData && (
          <div>
            <MetaData
              title="Organization"
              url={getRouteUrl(ROUTE_ORGANIZATION_VIEW_MEMBERS, {
                organizationId,
              })}
            />
            <Breadcrumb
              {...breadcrumb}
              params={{
                org: {
                  name: organizationData.title,
                  href: getRouteUrl(ROUTE_ORGANIZATION_VIEW_SINGLE, {
                    organizationId,
                  }),
                },
              }}
            />
            <OrganizationMembers
              currentUserName={userName}
              organization={organizationData}
            />
          </div>
        )}
        {loading && <FaSpinner loading />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  organizations: state.organizationReducer.get("byId"),
  loading: state.organizationReducer.get("loading"),
  userName: state.applicationUser.get("name"),
});

const mapDispatchToProps = (dispatch) => ({
  fetchOrganizationMemberships: (payload) => {
    dispatch(actions.fetchOrganizationMemberships(payload));
  },
  fetchOrganizations: () => {
    dispatch(actions.fetchOrganizations());
  },
});

export default withTracker(
  AuthorizationHOC(REGISTERED_USERS)(
    withBreadCrumb(
      connect(mapStateToProps, mapDispatchToProps)(OrganizationMembersHOC),
      {
        name: "Members",
        parents: ["Organizations", "{org}"],
      }
    )
  )
);
