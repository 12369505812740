import type {
  JsonApiItemCollection,
  JsonApiRelationshipItem,
} from "../jsonapi_helpers";
import { getJsonApiDataById } from "../jsonapi_helpers";

export type File = {
  fid: string,
  uuid: string,
  url: string,
  fileName: string,
  fileSize: number,
};

export const mapFileDatum: File = (
  item: JsonApiRelationshipItem,
  included: JsonApiItemCollection
) => {
  if (!(item && item.data)) return null;
  if (!item.data.id && item.data.length === 0) return;

  const mapDatum: File = (itemId) => {
    const itemData = getJsonApiDataById(itemId, included);
    const {
      drupal_internal__fid,
      uri,
      filename,
      filesize,
    } = itemData.attributes;
    return {
      fid: drupal_internal__fid,
      uuid: itemData.id,
      url: uri.url,
      fileName: filename,
      fileSize: filesize,
    };
  };

  return item.data.length > 0
    ? item.data.map((relatedItem) => mapDatum(relatedItem.id))
    : mapDatum(item);
};
