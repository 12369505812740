// @flow
import React from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import Faqs from "../components/faqs/faqs";
import withTracker from "./withtracker";
import AuthorizationHOC from "./AuthorizationHOC";
import { ALL_USERS } from "../constants/userGroups";
import withBreadCrumb from "./withBreadcrumb";

type Props = {
  fetchFaqs: Function,
  faqs: Object,
  faqsIds: Array<String>,
  faqsCategoryIds: Array<String>,
  faqsCategory: Object,
  searchFaqText: string,
  searchFaq: Function,
};

type State = {
  questionExpanded: ?string,
};

const parseFaqs = (faqs) => {
  if (faqs === undefined) return {};
  const faqsObject = {};
  faqs
    .valueSeq()
    .toArray()
    .map(
      (faq) =>
        (faqsObject[faq.get("uuid")] = {
          faqId: faq.get("uuid"),
          question: faq.get("question"),
          answer: faq.get("answer"),
          categoryId: faq.get("categoryId"),
        })
    );
  return faqsObject;
};

const parseFaqsIds = (faqsIds) => {
  if (faqsIds === undefined) return [];
  const faqsIdsList = [];
  faqsIds.forEach((faqId) => faqsIdsList.push(faqId));
  return faqsIdsList;
};

const parseFaqsCategories = (faqs) => {
  if (faqs === undefined) return {};
  const faqsObject = {};
  faqs
    .valueSeq()
    .toArray()
    .map(
      (faq) =>
        (faqsObject[faq.get("uuid")] = {
          uuid: faq.get("uuid"),
          name: faq.get("name"),
        })
    );
  return faqsObject;
};

const parseFaqsCategoriesIds = (faqsIds) => {
  if (faqsIds === undefined) return [];
  const faqsIdsList = [];
  faqsIds.forEach((faqId) => faqsIdsList.push(faqId));
  return faqsIdsList;
};

class FaqsHOC extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      questionExpanded: null,
    };
  }

  componentDidMount() {
    this.props.fetchFaqs();
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    const { searchFaqText } = this.props;
    // if (searchFaqText !== nextProps.searchFaqText) {
    //     nextProps.fetchFaqs({
    //         searchFaq: searchFaqText
    //     });
    // }
    if (
      searchFaqText !== nextProps.searchFaqText &&
      nextProps.searchFaqText === ""
    ) {
      this.props.fetchFaqs();
    }
  }

  search = (evt) => {
    this.props.fetchFaqs({
      searchFaq: evt.target.value,
    });
  };

  render() {
    const {
      faqs,
      faqsIds,
      faqsCategory,
      faqsCategoryIds,
      searchFaqText,
    } = this.props;
    const { questionExpanded } = this.state;
    return (
      <Faqs
        faqs={parseFaqs(faqs)}
        faqsIds={parseFaqsIds(faqsIds)}
        faqsCategory={parseFaqsCategories(faqsCategory)}
        faqsCategoryIds={parseFaqsCategoriesIds(faqsCategoryIds)}
        questionExpanded={questionExpanded}
        search={this.search}
        searchFaqText={searchFaqText}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  faqs: state.faqs.get("byId"),
  faqsIds: state.faqs.get("ids"),
  loading: state.faqs.get("loading"),
  faqsCategory: state.faqsCategories.get("byId"),
  faqsCategoryIds: state.faqsCategories.get("ids"),
  searchFaqText: state.faqs.get("searchFaq"),
});

const mapDispatchToProps = (dispatch) => ({
  fetchFaqs: (payload) => {
    dispatch(actions.fetchFaqs(payload));
  },
  // searchFaq: (payload) => {
  //     dispatch(actions.searchFaq(payload));
  // }
});

// export default connect(mapStateToProps, mapDispatchToProps)(FaqsHOC);

export default withTracker(
  AuthorizationHOC(ALL_USERS)(
    withBreadCrumb(connect(mapStateToProps, mapDispatchToProps)(FaqsHOC), {
      name: "FAQs",
    })
  )
);
