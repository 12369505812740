import React from "react";
import "./radiobutton.css";

type Props = {
  checked?: boolean,
  label?: string,
  className?: string,
  onChange?: Function,
  inputProps?: Object,
  disabled?: boolean,
};

const RadioButton = (props: Props) => {
  const { label, ...inputProps } = props;
  return (
    <div className="radio">
      <label className="radio__label">
        <input
          data-testid="radio-button"
          {...inputProps}
          type="radio"
          className="radio__input"
        />
        <div className="radio__icon">
          <svg width="20px" height="20px" viewBox="0 0 20 20">
            <circle cx="10" cy="10" r="9" />
            <polyline points="4 11 8 15 16 6" />
          </svg>
        </div>
        <span>{label}</span>
      </label>
    </div>
  );
};

export default RadioButton;
