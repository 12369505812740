// @flow
import React from "react";
import { formattedDate } from "../../../constants/misc";
import "./forumTopicDisplay.css";
import ForumBreadcrumb from "../forumBreadcrumb/forumBreadcrumb";
import SubscribePopover from "../../changeSubscriptionStatus/subscribePopover/subscribePopover";

type Props = {
  body: Object,
  author: Object,
  changed: Date,
  created: Date,
  title: string,
  uuid: string,
  isLoggedIn: boolean,
  subscribed?: boolean,
  changeSubscriptionStatus: Function,
  forum_id: string,
  forumParent: string,
};

const ForumTopicDisplay = (props: Props) => {
  // eslint-disable-next-line no-unused-vars
  const {
    subscribed,
    changeSubscriptionStatus,
    author,
    body,
    changed,
    created,
    title,
    uuid,
    isLoggedIn,
    forum_id,
    forumParent,
  } = props;
  return (
    <div>
      <ForumBreadcrumb
        root={forumParent}
        inner
        ancestors={[
          {
            name: title,
          },
        ]}
      />
      <div className="text-align-right">
        {subscribed !== null && isLoggedIn ? (
          <SubscribePopover
            messageSubscribe={
              <p>
                Click the button below to receive an email notification when a
                new comment gets posted in this topic.
              </p>
            }
            messageUnsubscribe={
              <p>
                Click the button below to unsubscribe to this forum topic.{" "}
                <br />
                You will no longer receive email notifications. <br />
                You can subscribe again at any time
              </p>
            }
            direction="left"
            data={{ node: uuid }}
            subscribed={subscribed}
          />
        ) : null}
      </div>
      <div className="forum-topic" id={uuid}>
        <div className="forum_post title_table">
          <div className="post_title title_cell">{title}</div>
          <div className="title_right_side title_cell">
            <div className="post_user">{author.name}</div>
            <div className="post_date">{formattedDate(created)}</div>
          </div>
        </div>
        <div className="forum_txt">
          <div dangerouslySetInnerHTML={{ __html: body }} />
        </div>
      </div>
    </div>
  );
};

export default ForumTopicDisplay;
