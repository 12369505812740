// @flow
import React from "react";
import SubmitButton from "../../submitButton/submitButton";
import { Field, reduxForm } from "redux-form/lib/immutable";
import renderTextInput from "../../formHelpers/renderTextInput";
import { maxLength1000, required } from "../../formHelpers/validationFunctions";
import renderTextArea from "../../formHelpers/renderTextArea";
import { connect } from "react-redux";
import ErrorMessage from "../../errorMessage/errorMessage";

type Props = {
  handleSubmit: Function,
  handleInputChange: Function,
  onSubmit: Function,
  submitting?: boolean,
  error?: string,
  hideImageUpload: boolean,
};
let OrganizationForm = (props: Props) => {
  // eslint-disable-next-line no-unused-vars
  const {
    handleSubmit,
    submitting,
    error,
    onSubmit,
    handleInputChange,
    hideImageUpload,
  } = props;
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        {/*{!hideImageUpload && (*/}
        {/*    <Field*/}
        {/*        label="Organization Image"*/}
        {/*        component={renderImageUpload}*/}
        {/*        name="image"*/}
        {/*        validate={[isImage]}*/}
        {/*        onChange={handleInputChange}*/}
        {/*    />*/}
        {/*)}*/}
        <Field
          className="body_field"
          labelClass="block__form_element_label"
          name="title"
          placeholder="Enter the title of your organization"
          type="text"
          component={renderTextInput}
          onChange={handleInputChange}
          validate={[required]}
        />
        <Field
          className="body_field"
          name="description"
          placeholder="Enter a short description for your organization"
          validate={[maxLength1000]}
          onChange={handleInputChange}
          component={renderTextArea}
        />
      </div>
      {error && <ErrorMessage message={error} />}
      <div style={{ textAlign: "center", marginTop: 20 }}>
        <SubmitButton disabled={submitting} title="SUBMIT" position="right" />
      </div>
    </form>
  );
};

OrganizationForm = reduxForm({
  form: "organizationForm",
})(OrganizationForm);

OrganizationForm = connect((state) => ({
  initialValues: {
    uuid: state.organizationFormReducer.getIn(["form", "uuid"]),
    title: state.organizationFormReducer.getIn(["form", "title"]),
    description: state.organizationFormReducer.getIn(["form", "description"]),
    image: state.organizationFormReducer.getIn(["form", "image", "url"]),
  },
  enableReinitialize: true,
}))(OrganizationForm);

export default OrganizationForm;
