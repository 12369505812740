// @flow
import React, { Fragment } from "react";
import "./productDetails.css";
import * as actions from "../../actions";
import { connect } from "react-redux";
import {
  Sidebar,
  SideBarButtons,
  SidebarHeader,
  SidebarViewAll,
} from "../layouts/layout1";
import Title from "../title/title";
import { Link } from "react-router-dom";
import Tag from "../tag/tag";
import SectionStart from "../sectionStart/sectionStart";
import Button from "../button/button";
import Table from "../table/table";
import ApiProductTableRow from "../apiProductTableRow/apiProductTableRow";
import SimpleButtonWrapper from "../simpleButtonWrapper/simpleButtonWrapper";
import SubscribePopover from "../changeSubscriptionStatus/subscribePopover/subscribePopover";
import ChangeLog from "../changeLog/changeLog";
import v4 from "uuid";
import _ from "lodash";
import Heading from "../heading/heading";
import { getRouteUrl } from "../../routers";
import { ROUTE_PRODUCT_DOCUMENTATION } from "../../routers/content/product";
import type { Product } from "../../constants/map/product";
import ProductNotificationsSubscribe from "../apiProduct/productNotificationsSubscribe/productNotificationsSubscribe";

type Props = {
  role: string,
  isLoggedIn?: boolean,
  changeLog: Array<Object>,
  product: Product,
  openModal: Function,
};

const PRODUCT_TABLE_HEADER = [
  {
    title: "PLAN NAME",
  },
  {
    title: "",
    className: "accordion_column",
  },
  {
    title: "DEFAULT PLAN",
  },
];

function ProductDetails(props: Props) {
  const { product, changeLog, isLoggedIn, openModal } = props;
  const { deprecated, version, description, subscribed, uuid, label } = product;
  return (
    <SectionStart>
      <Sidebar
        sidebarLeft={
          <Fragment>
            <SidebarHeader>
              <Title type="small">
                <Tag className="display-effect">OPEN API DOCUMENTATIONS</Tag>
              </Title>
              <SideBarButtons classNameWrapper="ApiButtons">
                {product.apis && product.apis.length > 0
                  ? product.apis.map((api) => (
                      <Link
                        key={api.uuid}
                        to={
                          api.alias
                            ? api.alias
                            : getRouteUrl(ROUTE_PRODUCT_DOCUMENTATION, {
                                apiDocumentationId: api.uuid,
                              })
                        }
                        target={"_blank"}
                      >
                        <Button className="ApiButton btn">
                          {api.title}
                          <span className="icon-icon_open" />
                        </Button>
                      </Link>
                    ))
                  : null}
              </SideBarButtons>
            </SidebarHeader>
            <SidebarViewAll />
          </Fragment>
        }
        sidebarRight={
          <Fragment>
            <div className="nbg_table accordion_table">
              <div>
                <Tag>{label}</Tag>
              </div>
              <div className="array_descr">
                <span className="path_big">
                  {product.title.toUpperCase() + " " + version}
                </span>
                <br /> DOCUMENTATION
              </div>
              <div>
                {deprecated ? (
                  <div className="text-align-right">
                    <span className="tag tag--DEVELOPMENT">Deprecated</span>
                  </div>
                ) : null}
                {subscribed !== null && isLoggedIn && (
                  <div
                    className={
                      deprecated
                        ? "notification__subscription_deprecated"
                        : "notification__subscription"
                    }
                  >
                    <ProductNotificationsSubscribe
                      uuid={uuid}
                      subscribed={subscribed}
                    />
                  </div>
                )}
              </div>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
                dangerouslySetInnerHTML={{ __html: description }}
              />
              {changeLog && changeLog.length > 0 ? (
                <div className="changelog-container">
                  <Heading
                    level="5"
                    style={{ color: "white", fontWeight: "400" }}
                  >
                    ChangeLog
                  </Heading>
                  {_.orderBy(changeLog, ["version"], ["desc"]).map((item) => (
                    <ChangeLog key={v4()} {...item} />
                  ))}
                </div>
              ) : null}
              <div style={{ marginTop: "2rem" }}>
                <Table
                  cellsNumber={2}
                  header={PRODUCT_TABLE_HEADER}
                  rows={product.apis}
                  RenderRow={ApiProductTableRow}
                />
              </div>
            </div>
            {isLoggedIn ? (
              <SimpleButtonWrapper
                style={{ marginTop: "8px" }}
                position="right"
              >
                <Button onClick={openModal} electro>
                  SUBSCRIBE
                </Button>
              </SimpleButtonWrapper>
            ) : (
              <SimpleButtonWrapper
                style={{ marginTop: "8px" }}
                position="right"
              >
                <Button electro style={{ cursor: "not-allowed" }}>
                  SUBSCRIBE
                </Button>
              </SimpleButtonWrapper>
            )}
          </Fragment>
        }
      />
    </SectionStart>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.applicationUser.get("isLoggedIn"),
});

const mapDispatchToProps = (dispatch) => ({
  changeSubscriptionStatus: (payload) => {
    dispatch(actions.changeSubscriptionStatus(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetails);
