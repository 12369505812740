// @flow
import React from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import ForumTopicId from "../components/forums/forumTopicId/forumTopicId";
import { ContentState, convertFromHTML, EditorState } from "draft-js";
import scrollToComponent from "react-scroll-to-component";
import AddCommentForm from "../components/forums/addCommentForm/addCommentForm";
import { renderCommentQuote } from "../helperFunctions/parseForums";
import withTracker from "./withtracker";
import AuthorizationHOC from "./AuthorizationHOC";
import { ALL_USERS } from "../constants/userGroups";
import { parseComments, parseTopic } from "../constants/parse/forum";

type Props = {
  subscribed?: boolean,
  changeSubscriptionStatus: Function,
  saveForumComment: Function,
  updateForumField: Function,
  clearForumTopic: Function,
  updateForumCommentField: Function,
  fetchForumTopicComments: Function,
  fetchForumTopicId: Function,
  loading: boolean,
  forum_id: string,
  match: Object,
  comments: Object,
  topic: Object,
  editorState: Object,
  replyTo: string,
  location: Object,
  isLoggedIn: boolean,
  searchCommentInTopicText: string,
  searchCommentInTopic: Function,
};

type State = {
  editorState: EditorState,
  submit: boolean,
  itemsPerPage: number,
  page: number,
};

class ForumTopicHOC extends React.Component<Props, State> {
  state = {
    editorState: EditorState.createEmpty(),
    submit: false,
    itemsPerPage: 20,
    page: 0,
  };

  handleEditorChange = ({ name, value }) => {
    this.props.updateForumCommentField({
      name,
      value,
    });
  };

  handleInputChange = (event) => {
    const { target, name } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.props.updateForumCommentField({
      name,
      value,
    });
  };

  topicReply = (event, uuid) => {
    event.preventDefault();
    this.handleInputChange({
      name: "parent",
      target: {
        type: event.type,
        value: uuid,
      },
    });
    // $FlowFixMe
    scrollToComponent(this.AddCommentForm, {
      offset: 0,
      align: "top",
      duration: 1500,
    });
  };

  saveReplyComment = (payload) => {
    const { comment, uuid, topic } = payload;
    this.setState({
      submit: true,
    });
    this.props.saveForumComment({
      body: comment,
      parent: uuid,
      forum: topic,
    });
  };

  saveNewComment = (form) => {
    const body = form.get("body");
    if (body && body.value) {
      this.props.saveForumComment({
        body: body.value,
        subject: form.get("subject"),
        parent: form.get("parent"),
        forum: parseTopic(this.props.topic),
      });
      // $FlowFixMe
      scrollToComponent(this.AddCommentForm, {
        offset: -300,
        align: "top",
        duration: 1500,
      });
    }
  };

  componentDidMount() {
    const { forum_id } = this.props.match.params;
    setTimeout(() => {
      this.props.fetchForumTopicId(forum_id);
    }, 1);
    window.scrollTo(0, 0);
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    // const {forum_id} = this.props.match.params;
    const { replyTo, comments, loading } = this.props;
    if (nextProps.replyTo && nextProps.replyTo !== replyTo) {
      const parent = parseComments(comments).find(
        (comment) => comment.uuid === nextProps.replyTo
      );
      const blocksFromHtml = parent
        ? convertFromHTML(renderCommentQuote(parent))
        : undefined;
      if (blocksFromHtml && blocksFromHtml.contentBlocks) {
        const contentState = ContentState.createFromBlockArray(
          blocksFromHtml.contentBlocks,
          blocksFromHtml.entityMap
        );
        const editorState = EditorState.createWithContent(contentState);
        this.setState({ editorState });
      }
    }
    if (!nextProps.loading && loading && this.state.submit) {
      this.setState({
        submit: false,
        editorState: EditorState.createEmpty(),
      });
    }
  }

  componentWillUnmount() {
    this.props.clearForumTopic();
  }

  searchTopic = (evt) => {
    const { forum_id } = this.props.match.params;
    const { itemsPerPage, page } = this.state;
    this.props.searchCommentInTopic({
      searchComment: evt.target.value,
      forum: forum_id,
      itemsPerPage,
      page,
    });
  };

  render() {
    const {
      subscribed,
      topic,
      loading,
      comments,
      isLoggedIn,
      changeSubscriptionStatus,
      searchCommentInTopicText,
    } = this.props;
    const { forum_id } = this.props.match.params;
    const { submit } = this.state;
    return (
      <div>
        <ForumTopicId
          forum_id={forum_id}
          changeSubscriptionStatus={changeSubscriptionStatus}
          subscribed={subscribed}
          topic={parseTopic(topic)}
          topicReply={this.topicReply}
          comments={parseComments(comments)}
          loading={loading}
          isLoggedIn={isLoggedIn}
          saveReplyComment={this.saveReplyComment}
          searchTopic={this.searchTopic}
          searchCommentInTopicText={searchCommentInTopicText}
          submit={submit}
        />
        <div
          ref={(section) => {
            // $FlowFixMe
            this.AddCommentForm = section;
          }}
        >
          {isLoggedIn ? (
            <AddCommentForm
              loading={loading && submit}
              handleEditorChange={this.handleEditorChange}
              handleInputChange={this.handleInputChange}
              saveNewComment={this.saveNewComment}
              editorState={this.state.editorState}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  subscribed: state.forum.get("subscriberToTopic"),
  loading: state.forum.get("loading"),
  topic: state.forum.get("topicId"),
  comments: state.forum.get("comments"),
  receiveStatus: state.forum.get("receiveStatus"),
  replyTo: state.forum.getIn(["form", "parent"]),
  commentPage: state.forum.get("commentPage"),
  isLoggedIn: state.applicationUser.get("isLoggedIn"),
  searchCommentInTopicText: state.forum.get("searchCommentInTopicText"),
});

const mapDispatchToProps = (dispatch) => ({
  changeSubscriptionStatus: (payload) => {
    dispatch(actions.changeSubscriptionStatus(payload));
  },
  fetchForumTopicId: (payload) => {
    dispatch(actions.fetchForumTopicId(payload));
  },
  updateForumField: (payload) => {
    dispatch(actions.updateForumField(payload));
  },
  updateForumCommentField: (payload) => {
    dispatch(actions.updateForumCommentField(payload));
  },
  saveForumComment: (payload) => {
    dispatch(actions.saveForumComment(payload));
  },
  clearForumTopic: (payload) => {
    dispatch(actions.clearForumTopic(payload));
  },
  searchCommentInTopic: (payload) => {
    dispatch(actions.searchCommentInTopic(payload));
  },
});

export default withTracker(
  AuthorizationHOC(ALL_USERS)(
    connect(mapStateToProps, mapDispatchToProps)(ForumTopicHOC)
  )
);
