import React from "react";
import * as actions from "../../../actions";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import SearchNew from "../../search/searchNew";

type Props = {
  fetchSearchResults?: Function,
  mobile: boolean,
  page: number,
  itemsPerPage: number,
  query: string,
};

class NavBarSearch extends React.Component<Props> {
  onSearch = (evt) => {
    const { value } = evt.target;
    const { page, itemsPerPage } = this.props;
    if (value !== "") {
      this.props.fetchSearchResults({
        query: value,
        itemsPerPage,
        page,
      });
    }
  };

  render() {
    const { mobile, query } = this.props;
    return (
      <Link to="/search" style={{ textDecoration: "none", display: "block" }}>
        {mobile ? (
          <input
            onChange={this.onSearch}
            type="text"
            value={query}
            placeholder="SEARCH"
            className="mobile-seach-input"
          />
        ) : (
          <SearchNew expanding onChange={this.onSearch} />
        )}
      </Link>
    );
  }
}

// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state) => ({
  query: state.search.get("query"),
  page: state.search.get("page"),
  itemsPerPage: state.search.get("itemsPerPage"),
});

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSearchResults: (payload) => {
      dispatch(actions.fetchSearchResults(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBarSearch);
