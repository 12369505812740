import { formattedDate } from "../constants/misc";
import React from "react";
import { renderToString } from "react-dom/server";

export const renderCommentQuote = (comment: Object) => {
  const quoted = comment.body.includes("<blockquote>");
  const body = quoted
    ? comment.body.replace(/<blockquote.*?<\/blockquote>/g, "")
    : comment.body;
  return renderToString(
    <div>
      <blockquote>
        <h1>
          {/*<a href={"/user/" + comment.author.uid}> {comment.author.name} </a>*/}
          <span> {comment.author.name} </span>
          wrote at {formattedDate(comment.created)}
        </h1>
        <br />
        <div dangerouslySetInnerHTML={{ __html: body }} />
      </blockquote>
      <p>Enter reply...</p>
    </div>
  );
};
