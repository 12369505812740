import xs from "xstream/index";
import { BASE_URL } from "../config";
import * as ActionTypes from "../actions/types";
import * as actions from "../actions";
import {
  createJsonApiFilter,
  createJsonApiOrGroup,
} from "../constants/jsonapi_helpers";
import { generalHeader } from "./headers";

export function fetchFaqs(sources) {
  const request$ = sources.ACTION.filter(
    (action) => action.type === ActionTypes.FETCH_FAQS
  ).map((action) => {
    // eslint-disable-line
    let requestUrl = "";
    if (typeof action.payload !== "undefined") {
      const { searchFaq } = action.payload;
      if (searchFaq) {
        requestUrl =
          BASE_URL +
          "/jsonapi/node/faq?include=field_category" +
          "&" +
          "filter[faq-group][group][conjunction]=OR" +
          "&" +
          createJsonApiFilter("searchTitle", "title", searchFaq, "CONTAINS") +
          "&" +
          "filter[searchTitle][condition][memberOf]=faq-group" +
          "&" +
          createJsonApiFilter(
            "searchTitle",
            "field_answer.value",
            searchFaq,
            "CONTAINS"
          ) +
          "&" +
          "filter[searchTitle][condition][memberOf]=faq-group";
      }
    } else {
      requestUrl = BASE_URL + "/jsonapi/node/faq?include=field_category";
    }
    return {
      url: requestUrl,
      category: "fetchFaqs",
      method: "GET",
      headers: generalHeader(null),
    };
  });

  let httpResponse$ = sources.HTTP.select("fetchFaqs")
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .filter((response) => response.status === 200)
    .map((response) => actions.storeFaqs(response.body));

  return {
    ACTION: httpResponse$,
    HTTP: request$,
  };
}

export function fetchFaqsFailed(sources) {
  let httpResponse$ = sources.HTTP.select("fetchFaqs")
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .filter((response) => response.status !== 200)
    .map((response) => actions.fetchFaqsFailed(response));

  return {
    ACTION: httpResponse$,
  };
}
