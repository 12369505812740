import { SidebarHeader, SidebarViewAll } from "../layouts/layout1";
import Title from "../title/title";
import Heading from "../heading/heading";
import Tag from "../tag/tag";
import SearchInput from "../search/searchInput/searchInput";
import DropDownMenu from "../dropDownMenu/dropDownMenu";
import Chips from "../chips/chips";
import BracketButton from "../bracketButton/bracketButton";
import React from "react";
import { ROUTE_PAGE_NEWS } from "../../routers/content/page";

type Props = {
  search: Function,
  searchTerm: String,
  viewStyle: boolean,
  category: Array<Object>,
  onClick: Function,
  toggler: boolean,
  addCategoryFilter: Function,
  arrayCategory: Array,
  removeCategoryFilter: Function,
};
const NewsHeader = (props: Props) => {
  const {
    search,
    viewStyle,
    category,
    toggler,
    addCategoryFilter,
    arrayCategory,
    removeCategoryFilter,
    searchTerm,
  } = props;
  return (
    <SidebarHeader>
      {viewStyle === "block" ? (
        <Title type="section">
          <Heading level="2" className="folowed_by_subtitle display-effect">
            BLOG
          </Heading>
          <Title type="descr">
            <Tag>Search in blog</Tag>
          </Title>
        </Title>
      ) : (
        <div>
          <Title type="section">
            <Heading level="2" className="folowed_by_subtitle display-effect">
              BLOG
            </Heading>
          </Title>
          <Title type="small">
            <Tag className="display-effect">SEARCH</Tag>
          </Title>
        </div>
      )}
      <SearchInput onChange={search} query={searchTerm} />
      {viewStyle === "full" ? (
        <div>
          {/*<div*/}
          {/*    className="filtersButton-wrapper"*/}
          {/*    onClick={onClick}*/}
          {/*>*/}
          {/*    <div className="filtersButton">*/}
          {/*        <button*/}
          {/*            id="filter_btn"*/}
          {/*            className="theFiltersBtn"*/}
          {/*        >*/}
          {/*            <span*/}
          {/*                className="icon-icon_filter"/> FILTER BLOG*/}
          {/*        </button>*/}
          {/*    </div>*/}
          {/*</div>*/}
          <div className="filterBlogBorder" />
          <div
            id="filter_accordion"
            style={toggler ? { display: "none" } : { display: "block" }}
          >
            <div id="filter_title_hide" className="small_title">
              <span className="display-effect">FILTER BLOG</span>
            </div>
            <div className="side_field">
              <DropDownMenu
                selectHandler={addCategoryFilter}
                items={
                  category
                    ? category.map((item) => ({
                        name: item.name,
                        value: item.uuid,
                      }))
                    : null
                }
              />
            </div>
            <div className="page page--chips">
              {arrayCategory &&
                arrayCategory.map((filter) => (
                  <Chips
                    key={filter.uuid}
                    title={filter.name}
                    closed={() => removeCategoryFilter(filter)}
                  />
                ))}
            </div>
          </div>
        </div>
      ) : (
        <SidebarViewAll
          ViewAllClassName="view-all-news-button"
          viewAll={
            <BracketButton
              caretEnable
              title="VIEW ALL BLOGS"
              className="movingBrackets left"
              url={ROUTE_PAGE_NEWS.path}
              id="custom_mobile_position_link_2"
            />
          }
        />
      )}
    </SidebarHeader>
  );
};

export default NewsHeader;
