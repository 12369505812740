import { useEffect } from "react";
import * as actions from "../../../actions";
import { connect } from "react-redux";

export function useOrganizationMemberships(uuid) {
  const mapStateToProps = (state) => {
    return {
      organizations: state.organizationReducer.get("byId"),
    };
  };

  const mapDispatchToProps = (dispatch) => ({
    fetchOrganizationMemberships: (payload) => {
      dispatch(actions.fetchOrganizationMemberships(payload));
    },
  });

  const FetchComponent = (props) => {
    const { organizations, fetchOrganizationMemberships } = props;
    useEffect(() => {
      if (!organizations.hasIn([uuid, "members"])) {
        fetchOrganizationMemberships({
          uuid: uuid,
          id: organizations.getIn([uuid, "id"]),
        });
      }
    }, []);
    return null;
  };

  return connect(mapStateToProps, mapDispatchToProps)(FetchComponent);
}
