// @flow
import * as React from "react";
import { useSelector } from "react-redux";
import GoLiveForm from "../components/goLiveForm/goLiveForm";
import withTracker from "./withtracker";
import { REGISTERED_USERS } from "../constants/userGroups";
import withBreadCrumb from "./withBreadcrumb";
import { getRouteUrl } from "../routers";
import Breadcrumb from "../components/breadcrumb/breadcrumb";
import { ROUTE_APPLICATION_VIEW } from "../routers/content/application";
import SectionStart from "../components/sectionStart/sectionStart";
import { Sidebar, SidebarHeader } from "../components/layouts/layout1";
import Title from "../components/title/title";
import Heading from "../components/heading/heading";
import BracketButton from "../components/bracketButton/bracketButton";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { fetchApplicationById } from "../Apis/application";
import FaSpinner from "../components/faSpinner/faSpinner";
import AuthProvider from "../AuthProvider/AuthProvider";

type Props = {
  breadcrumb: Object,
};
const GoLiveHOC = ({ breadcrumb }: Props) => {
  let { applicationId } = useParams();

  const csrfToken = useSelector(({ applicationUser }) =>
    applicationUser.get("token")
  );

  const {
    data: application,
    isLoading,
  } = useQuery(`application-${applicationId}`, () =>
    fetchApplicationById(csrfToken, applicationId)
  );

  if (isLoading) {
    return <FaSpinner loading={true} />;
  }

  return (
    <React.Fragment>
      <AuthProvider allowedRoles={REGISTERED_USERS}>
        <Breadcrumb
          {...breadcrumb}
          params={{
            app: {
              name: application.data.attributes.title,
              href: applicationId
                ? getRouteUrl(ROUTE_APPLICATION_VIEW, {
                    applicationId: applicationId,
                  })
                : null,
            },
          }}
        />
        <SectionStart>
          <Sidebar
            sidebarLeft={
              <SidebarHeader>
                <Title type="section">
                  <Heading level="1" className="display-effect">
                    Go Live for {application.data.attributes.title}
                  </Heading>
                </Title>
                <BracketButton
                  caretEnable
                  className="movingBrackets left"
                  title={"BACK TO OVERVIEW"}
                  url={getRouteUrl(ROUTE_APPLICATION_VIEW, {
                    applicationId: applicationId,
                  })}
                />
              </SidebarHeader>
            }
            sidebarRight={
              <GoLiveForm
                applicationId={applicationId}
                applicationTitle={application.data.attributes.title}
              />
            }
          />
        </SectionStart>
      </AuthProvider>
    </React.Fragment>
  );
};

export default withTracker(
  withBreadCrumb(GoLiveHOC, {
    name: "Go Live",
    parents: ["Apps", "{app}"],
  })
);
