// @flow
import React, { useState } from "react";
import "./apiProductsList.css";
import FaSpinner from "../faSpinner/faSpinner";
import _ from "lodash";
import ProductPreview from "../productPreview/productPreview";
import { parseApiProductsResponse } from "../../constants/parse/product";
import { mapProductData } from "../../constants/map/product";
import useToken from "../../customHooks/useToken";
import { useQuery } from "react-query";
import { QUERRYIDS } from "../../constants/queryIds";
import { filterApiProductsQuery } from "../../Apis/apiProducts";
import Pager from "../pager/pager";
import { DEFAULT_PAGINATION } from "../../config";

type Props = {
  category: Object,
};

const ApiProductsList = (props: Props) => {
  const { category } = props;
  const [page, setPage] = useState(0);
  const csrfToken = useToken();
  const apiProducts = useQuery(
    [QUERRYIDS.fetchProductsByCategory, category, page],
    () => filterApiProductsQuery(csrfToken, category, page)
  );

  const changePage = (evt, link) => {
    switch (link.name) {
      case "PREVIOUS":
        return setPage(page - 1);
      case "FIRST":
        return setPage(0);
      case "NEXT":
        return setPage(page + 1);
      case "LAST":
        return setPage(
          Math.floor(apiProducts.data.meta.count / DEFAULT_PAGINATION)
        );
      default:
        return setPage(link.name - 1);
    }
  };

  if (apiProducts.isLoading) {
    return <FaSpinner loading />;
  }

  return (
    <div className="choises">
      {_.sortBy(parseApiProductsResponse(mapProductData(apiProducts.data)), [
        "weight",
        "title",
      ]).map((product) => {
        const { uuid, description, disable_documentation } = product;
        return (
          <ProductPreview
            {...product}
            key={uuid}
            animated
            body={description}
            disable_documentation={disable_documentation}
          />
        );
      })}
      <Pager
        page={page + 1}
        onClick={changePage}
        links={apiProducts.data.links}
        meta={apiProducts.data.meta}
        itemsPerPage={DEFAULT_PAGINATION}
      />
    </div>
  );
};

export default ApiProductsList;
