import React from "react";
import "./news.css";
import { Sidebar } from "../layouts/layout1";
import { connect } from "react-redux";
import * as actions from "../../actions";
import NewSummary from "../newSummary/newSummary";
import v4 from "uuid";
import { calcNextPage } from "../../constants/misc";
import FaSpinner from "../faSpinner/faSpinner";
import Pager from "../pager/pager";
import SectionStart from "../sectionStart/sectionStart";
import { parseLinks, parseMeta } from "../../helperFunctions/parsePager";
import { parseNodes } from "../../helperFunctions/parseNews";
import NewsHeader from "../newsHeader/newsHeader";

type Props = {
  fetchArticles: Function,
  searchArticleText: Function,
  fetchArticlesCategory: Function,
  pages: Object,
  articles: Object,
  loading: boolean,
  match: Object,
  location: {
    pathname: string,
  },
  category: Array,
  categoryFilterIds: Array,
  categoryFilterById: Object,
  addCategoryFilter: Function,
  searchArticle: Function,
  viewStyle: string,
  removeCategoryFilter: Function,
  clearCategoryFilter: Function,
  searchTerm: String,
};

type State = {
  total: number,
  page: number,
};

class News extends React.Component<Props, State> {
  state = {
    itemsPerPage: 5,
    page: 0,
    toggler: true,
  };

  onPagerClick = (evt, link) => {
    const { page, itemsPerPage } = this.state;
    const meta = parseMeta(this.props.articles.get("meta"));
    const nextPage = calcNextPage(link.name, page, meta.count, itemsPerPage);

    if (meta && meta.count && page === nextPage) {
      this.setState({
        page: link.name - 1,
      });
      this.props.fetchArticles({
        itemsPerPage,
        page: link.name - 1,
      });
    } else {
      this.setState({
        page: nextPage,
      });
      this.props.fetchArticles({
        url: link.href,
      });
    }
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  };

  componentDidMount() {
    const { categoryFilterIds, searchTerm } = this.props;
    const payload = {
      categories: categoryFilterIds.length > 0 ? categoryFilterIds : this.state,
      searchArticle: searchTerm && searchTerm.length > 0 ? searchTerm : "",
    };
    this.props.fetchArticles(payload);
    this.props.fetchArticlesCategory();
  }

  search = (evt) => {
    this.props.searchArticle(evt.target.value);
  };

  addCategoryFilter = (selectedOption) => {
    if (selectedOption) {
      this.props.addCategoryFilter({
        uuid: selectedOption.value,
        name: selectedOption.name,
      });
    } else {
      this.props.clearCategoryFilter();
    }
  };

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    const { categoryFilterIds, searchArticleText } = this.props;
    if (
      categoryFilterIds.length !== nextProps.categoryFilterIds.length ||
      searchArticleText !== nextProps.searchArticleText
    ) {
      nextProps.fetchArticles({
        categories: nextProps.categoryFilterIds,
        searchArticle: nextProps.searchArticleText,
      });
      this.setState({
        page: 0,
      });
    }
    if (
      searchArticleText !== nextProps.searchArticleText &&
      nextProps.searchArticleText === ""
    ) {
      this.props.fetchArticles({
        page: this.state.page,
        total: this.state.total,
      });
    }
  }

  toggleFiltersMenu = () => {
    const { toggler } = this.state;
    this.setState({
      toggler: !toggler,
    });
  };

  render() {
    const {
      loading,
      articles,
      category,
      removeCategoryFilter,
      categoryFilterById,
      searchArticleText,
      viewStyle,
      searchTerm,
    } = this.props;
    const { itemsPerPage } = this.state;
    const arrayCategory = Object.values(categoryFilterById);
    const articleNodes =
      articles && articles.get("nodes") !== undefined
        ? parseNodes(articles.get("nodes"))
        : [];

    return (
      <SectionStart>
        <Sidebar
          sidebarLeft={
            <NewsHeader
              category={category}
              toggler={false}
              onClick={this.toggleFiltersMenu}
              search={this.search}
              searchTerm={searchTerm}
              viewStyle={viewStyle}
              addCategoryFilter={this.addCategoryFilter}
              arrayCategory={arrayCategory}
              removeCategoryFilter={removeCategoryFilter}
            />
          }
          sidebarRight={
            <div className="nbg-hub-news-wrapper">
              <FaSpinner loading={loading} />
              {articleNodes ? (
                articleNodes.map((node) => (
                  <NewSummary
                    key={v4()}
                    {...node}
                    searchText={searchArticleText}
                  />
                ))
              ) : (
                <div>No articles </div>
              )}
              {articleNodes &&
              articleNodes.length > 0 &&
              viewStyle === "full" ? (
                <Pager
                  page={this.state.page + 1}
                  onClick={this.onPagerClick}
                  links={parseLinks(articles.get("links"))}
                  meta={parseMeta(articles.get("meta"))}
                  itemsPerPage={itemsPerPage}
                />
              ) : null}
            </div>
          }
        />
      </SectionStart>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.basicPage.get("loading"),
  articles: state.basicPage.get("articles"),
  category: state.basicPage.get("category")
    ? state.basicPage.get("category").toJS()
    : [],
  searchTerm: state.basicPage.get("searchArticle"),
  categoryFilterIds: state.basicPage.get("categoryFilterIds").toJS(),
  categoryFilterById: state.basicPage.get("categoryFilterById").toJS(),
  searchArticleText: state.basicPage.get("searchArticle"),
});

const mapDispatchToProps = (dispatch) => ({
  fetchArticles: (payload) => {
    dispatch(actions.fetchArticles(payload));
  },
  fetchArticlesCategory: () => {
    dispatch(actions.fetchArticlesCategory());
  },
  addCategoryFilter: (payload) => {
    dispatch(actions.addCategoryFilter(payload));
  },
  removeCategoryFilter: (payload) => {
    dispatch(actions.removeCategoryFilter(payload));
  },
  clearCategoryFilter: (payload) => {
    dispatch(actions.clearCategoryFilter(payload));
  },
  searchArticle: (payload) => {
    dispatch(actions.searchArticle(payload));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(News);
