import xs from "xstream/index";
import { BASE_URL } from "../config";
import * as ActionTypes from "../actions/types";
import * as actions from "../actions";
import { generalHeader } from "./headers";

export function fetchSearchResults(sources) {
  const request$ = sources.ACTION.filter(
    (action) => action.type === ActionTypes.FETCH_SEARCH_RESULTS
  ).map((action) => {
    const { query, page, itemsPerPage, type } = action.payload;
    return {
      url:
        BASE_URL +
        `/phpsdk/search?q=${query}&pageSize=${itemsPerPage}&pageOffset=${page}&type=${type}&pagination=true`,
      category: "fetchSearchResults",
      method: "GET",
      headers: generalHeader(null),
      withCredentials: true,
    };
  });

  let httpResponse$ = sources.HTTP.select("fetchSearchResults")
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .filter((response) => response.status === 200)
    .map((response) => actions.storeSearchResults(response.body));

  return {
    ACTION: httpResponse$,
    HTTP: request$,
  };
}

export function fetchSearhResultsFailed(sources) {
  let httpResponse$ = sources.HTTP.select("fetchSearchResults")
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .filter((response) => response.status !== 200)
    .map((response) => actions.fetchSearchResultsFailed(response));

  return {
    ACTION: httpResponse$,
  };
}
