// @flow
import React, { Fragment } from "react";
import "./organizationMembers.css";
import SectionStart from "../sectionStart/sectionStart";
import { Sidebar, SidebarHeader, SidebarViewAll } from "../layouts/layout1";
import Title from "../title/title";
import Heading from "../heading/heading";
import InviteMembersModal from "../organization/inviteMembersModal/inviteMembersModal";
import BracketButton from "../bracketButton/bracketButton";
import v4 from "uuid";
import OrganizationMemberInfoModal from "../organizationMemberInfoModal/organizationMemberInfoModal";
import type { Member, Organization } from "../../constants/map/organization";
import OrganizationMembersList from "../organization/organizationMembersList/organizationMembersList";
import StatusMessage from "../statusMessage/statusMessage";
import { connect } from "react-redux";
import * as actions from "../../actions";
import { getRouteUrl } from "../../routers";
import { ROUTE_ORGANIZATION_VIEW_SINGLE } from "../../routers/content/organization";
import SimpleButtonWrapper from "../simpleButtonWrapper/simpleButtonWrapper";
import Button from "../button/button";
import { isValidEmail } from "../formHelpers/validationFunctions";

type Props = {
  currentUserName: string,
  organization: Organization,
  roles: Object,
  createOrganizationMembership: Function,
  resendMemberInvitation: Function,
  updateOrganizationMembership: Function,
  deleteOrganizationMembership: Function,
  fetchOrganizationRoles: Function,
};

type State = {
  fields: Array<{
    uuid: string,
    email: string,
    roles: string,
    errors: {
      roles?: string,
      email?: string,
    },
  }>,
};

const createInvitationRow = (mail, roles) => {
  return {
    uuid: v4(),
    email: mail ? mail : "",
    roles: roles ? roles : [],
  };
};

class OrganizationMembers extends React.Component<Props, State> {
  state = {
    invitationModalIsOpen: false,
    inviteFormMembers: [createInvitationRow()],
    memberModalIsOpen: false,
    memberInfo: null,
    message: "",
  };

  openInviteFormModal = () => {
    this.setState({
      invitationModalIsOpen: true,
    });
  };

  closeInviteFormModal = () => {
    this.setState({
      invitationModalIsOpen: false,
      inviteFormMembers: [createInvitationRow()],
    });
  };

  openMemberModal = (id) => {
    const { organization } = this.props;
    this.setState({
      memberModalIsOpen: true,
      memberInfo: organization.members.find((member) => member.uuid === id),
    });
  };

  closeMemberModal = () => {
    this.setState({ memberModalIsOpen: false });
  };

  removeMember = (member: Member) => {
    const { deleteOrganizationMembership, organization } = this.props;
    deleteOrganizationMembership({
      membershipUuid: member.membershipUuid,
      organizationUuid: organization.uuid,
    });
    this.closeMemberModal();
  };

  resendInvitation = (member: Member) => {
    const { organization } = this.props;
    this.props.resendMemberInvitation({
      membershipId: member.id,
      gid: organization.id,
    });
    this.closeMemberModal();
  };

  updateMemberInfo = (member: Member, selectedRoles) => {
    const { roles } = this.props;
    this.props.updateOrganizationMembership({
      membershipUuid: member.membershipUuid,
      roleUuids: selectedRoles.map((role) => roles.getIn([role, "uuid"])),
    });
  };

  componentDidMount() {
    this.props.fetchOrganizationRoles();
  }

  validateInviteForm = (field) => {
    const { inviteFormMembers } = this.state;
    const newForm = inviteFormMembers.map((m) => ({
      ...m,
      errors: {
        ...m.errors,
        ...(typeof field === "undefined" ||
        (field === "email" && m.email !== "")
          ? {
              email: !isValidEmail(m.email)
                ? "Please enter a valid email"
                : undefined,
            }
          : {}),
        ...(typeof field === "undefined" || field === "roles"
          ? {
              roles:
                m.roles.length === 0
                  ? "Select one or more roles for this member"
                  : undefined,
            }
          : {}),
      },
    }));
    this.setState({
      inviteFormMembers: newForm,
    });
    return !newForm.find((m) => m.errors.email || m.errors.roles);
  };

  handleInviteFormMemberEdit = ({ field, uuid, value }) => {
    const { inviteFormMembers } = this.state;
    const memberIndex = inviteFormMembers.findIndex((m) => m.uuid === uuid);
    if (memberIndex === -1) return;
    this.setState({
      inviteFormMembers: [
        ...inviteFormMembers.slice(0, memberIndex),
        {
          ...inviteFormMembers[memberIndex],
          [field]: value,
          errors: {
            ...inviteFormMembers[memberIndex].errors,
            [field]: undefined,
          },
        },
        ...inviteFormMembers.slice(memberIndex + 1),
      ],
    });
  };

  handleInviteFormRemoveMember = (uuid) => {
    const { inviteFormMembers } = this.state;
    this.setState({
      inviteFormMembers: inviteFormMembers.filter((m) => m.uuid !== uuid),
    });
  };

  handleInviteFormAddMember = (evt) => {
    evt.preventDefault();
    this.setState({
      inviteFormMembers: [
        ...this.state.inviteFormMembers,
        createInvitationRow(),
      ],
    });
  };

  handleInviteFormSubmit = (e) => {
    e.preventDefault();
    if (!this.validateInviteForm()) return;

    const { inviteFormMembers } = this.state;
    const { organization, createOrganizationMembership } = this.props;

    inviteFormMembers.forEach((m) => {
      createOrganizationMembership({
        gid: organization.id,
        email: m.email,
        group_roles: m.roles,
      });
    });
    this.closeInviteFormModal();
  };

  render() {
    const {
      invitationModalIsOpen,
      inviteFormMembers,
      memberInfo,
      memberModalIsOpen,
      message,
    } = this.state;
    const {
      organization: { members, uuid, title, uid },
    } = this.props;
    return (
      <SectionStart>
        <Sidebar
          sidebarLeft={
            <Fragment>
              <SidebarHeader>
                <Title type="section">
                  <Heading level="1" className="display-effect">
                    {title}
                  </Heading>
                </Title>
                <BracketButton
                  caretEnable
                  className="movingBrackets left"
                  title={"BACK TO OVERVIEW"}
                  url={getRouteUrl(ROUTE_ORGANIZATION_VIEW_SINGLE, {
                    organizationId: uuid,
                  })}
                />
              </SidebarHeader>
              <SidebarViewAll />
            </Fragment>
          }
          sidebarRight={
            <div>
              <InviteMembersModal
                isOpen={invitationModalIsOpen}
                closeModal={this.closeInviteFormModal}
                members={inviteFormMembers}
                onEditMember={this.handleInviteFormMemberEdit}
                onRemoveMember={this.handleInviteFormRemoveMember}
                onAddMember={this.handleInviteFormAddMember}
                onSubmit={this.handleInviteFormSubmit}
                onInputBlur={(field) => this.validateInviteForm(field)}
              />
              <div className="nbg-organization-members-wrapper">
                <SimpleButtonWrapper
                  position="right"
                  style={{ marginBottom: "1rem" }}
                >
                  <Button onClick={this.openInviteFormModal}>
                    INVITE MEMBER
                  </Button>
                </SimpleButtonWrapper>
                <Title type="array">Members List</Title>
                <div className="array_descr">
                  {message !== "" && (
                    <StatusMessage modifiers={[StatusMessage.WARNING]}>
                      {message}
                    </StatusMessage>
                  )}
                </div>
                <OrganizationMembersList
                  organizationUuid={uuid}
                  memberOperations={{
                    onEdit: this.openMemberModal,
                  }}
                  members={members}
                />
                {memberInfo && (
                  <OrganizationMemberInfoModal
                    isOrganizationCreator={uid === memberInfo.uuid}
                    onMemberRemove={this.removeMember}
                    resendInvitation={this.resendInvitation}
                    isOpen={memberModalIsOpen}
                    onSubmit={this.updateMemberInfo}
                    onClose={this.closeMemberModal}
                    member={memberInfo}
                  />
                )}
              </div>
            </div>
          }
        />
      </SectionStart>
    );
  }
}

const mapStateToProps = (state) => ({
  roles: state.organizationReducer.get("rolesById"),
});

const mapDispatchToProps = (dispatch) => ({
  fetchOrganizationRoles: (payload) => {
    dispatch(actions.fetchOrganizationRoles(payload));
  },
  resendMemberInvitation: (payload) => {
    dispatch(actions.resendMemberInvitation(payload));
  },
  createOrganizationMembership: (payload) => {
    dispatch(actions.createOrganizationMembership(payload));
  },
  updateOrganizationMembership: (payload) => {
    dispatch(actions.updateOrganizationMembership(payload));
  },
  deleteOrganizationMembership: (payload) => {
    dispatch(actions.deleteOrganizationMembership(payload));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganizationMembers);
