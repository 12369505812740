// @flow
import React from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import Pager from "../components/pager/pager";
import { calcNextPage } from "../constants/misc";
import { parseLinks, parseMeta } from "../helperFunctions/parsePager";

type Props = {
  page: number,
  meta: {
    count: number,
  },
  links: Object,
  fetchForumTopicComments: Function,
  updateForumField: Function,
  forum: string,
  searchCommentInTopicText: string,
};

type State = {
  itemsPerPage: number,
  page: number,
};

class ForumPagerHOC extends React.Component<Props, State> {
  state = {
    itemsPerPage: 10,
    page: 0,
  };

  onPagerClick = (evt, link) => {
    const { page, itemsPerPage } = this.state;
    const { forum } = this.props;
    const meta = parseMeta(this.props.meta);

    const nextPage = calcNextPage(link.name, page, meta.count, itemsPerPage);

    if (meta && meta.count && page === nextPage) {
      this.setState({
        page: link.name - 1,
      });
      this.props.fetchForumTopicComments({
        itemsPerPage,
        page: link.name - 1,
        forum,
      });
    } else {
      this.setState({
        page: nextPage,
      });
      this.props.fetchForumTopicComments({
        link: link.href,
      });
    }
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  };

  componentDidMount() {
    const { forum } = this.props;
    this.props.fetchForumTopicComments({
      ...this.state,
      forum,
    });
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.searchCommentInTopicText !== prevProps.searchCommentInTopicText
    ) {
      this.setState({
        page: 0,
      });
    }
  }
  render() {
    const { meta, links } = this.props;
    const { page, itemsPerPage } = this.state;
    return (
      <Pager
        page={page + 1}
        meta={parseMeta(meta)}
        links={parseLinks(links)}
        onClick={this.onPagerClick}
        itemsPerPage={itemsPerPage}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.forum.get("loading"),
  page: state.forum.get("commentPage"),
  meta: state.forum.get("commentMeta"),
  links: state.forum.get("commentPager"),
});

const mapDispatchToProps = (dispatch) => ({
  fetchForumTopicComments: (payload) => {
    dispatch(actions.fetchForumTopicComments(payload));
  },
  updateForumField: (payload) => {
    dispatch(actions.updateForumField(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ForumPagerHOC);
