const globalConfig = require("../assets/assets/globalConfig");

export const DRIVE_API_KEY = globalConfig.googleDriveApiKEY;
export const BASE_URL = globalConfig.baseUrl;
export const FRONTEND_URL = globalConfig.frontEndUrl;
export const reCAPTCHA_KEY = globalConfig.reCaptchaKey;
export const REACT_HEADER_CLIENT_ID = globalConfig.reactHeaderClientId;

export const OAUTH_REDIRECT_URL =
  window.location.protocol +
  "//" +
  window.location.host +
  FRONTEND_URL +
  "oauth2/callback";

export const GOOGLE_ANALYTICS_PROPERTY_ID =
  globalConfig.googleAnalyticsPropertyId;

export const RELATIVE_JSON_API_LINKS = globalConfig.relativeJsonApiLinks;

export const FRONTEND_ORIGIN_URL =
  window.location.protocol + "//" + window.location.host + FRONTEND_URL;

export const FRONTEND_ORIGIN_URL_NO_SLASH =
  window.location.protocol +
  "//" +
  window.location.host +
  FRONTEND_URL.slice(0, -1);

export const TEMP_CLIENT_ID = "516C6E55-8071-460A-BF1E-9B9F58F3A21C";

export const SLACK_URL =
  "https://join.slack.com/t/nbgtechnology/shared_invite/enQtNTE5OTk0MDQ5MzMzLWQ3YmUzYjQwNGE2NThlYTkwYzQxMTlkODEzNjdhMWY3NDVkMWViYWUxZjQ2ZjAwMWZmOThkZGNjZWNjNDc1ZTY";

export const DEFAULT_PAGINATION = 20;
