// @flow
import React, { useEffect } from "react";
import { connect } from "react-redux";
import * as actions from "../actions/index";
import SectionStart from "../components/sectionStart/sectionStart";
import { Sidebar, SidebarHeader } from "../components/layouts/layout1";
import Title from "../components/title/title";
import Heading from "../components/heading/heading";
import { parseResults } from "../helperFunctions/parseSearch";
import SearchResultList from "../components/search/searchResultList/searchResultList";
import withTracker from "./withtracker";
import withBreadCrumb from "./withBreadcrumb";
import { object } from "@cycle/dom";
import { parseApiProducts } from "../constants/parse/product";

type Props = {
  loading: boolean,
  results: Object,
  query: string,
  fetchSearchResults: Function,
  updateSearchCategory: Function,
  clearSearchResults: Function,
  fetchApiProducts: Function,
  apiProducts: object,
};

function SearchPageHOC(props: Props) {
  const { loading, results, query, apiProducts } = props;

  const onCategoryChange = (evt) => {
    const value = evt ? evt.value : null;
    props.updateSearchCategory(value);
  };

  useEffect(() => {
    parseApiProducts(apiProducts).length === 0 && props.fetchApiProducts();
  }, []);

  useEffect(() => {
    if (query === "") {
      props.clearSearchResults();
    }
  }, [query]);

  return (
    <SectionStart>
      <Sidebar
        sidebarLeft={
          <SidebarHeader>
            <Title type="section">
              <Heading level="2">SEARCH RESULTS</Heading>
            </Title>
          </SidebarHeader>
        }
        sidebarRight={
          <SearchResultList
            onCategoryChange={onCategoryChange}
            query={query}
            loading={loading}
            listItems={parseResults(query, results, props.apiProducts)}
          />
        }
      />
    </SectionStart>
  );
}

const mapStateToProps = (state) => ({
  loading: state.search.get("loading"),
  results: state.search.get("results"),
  query: state.search.get("query"),
  apiProducts: state.apiProduct.get("apiProductsById"),
});

const mapDispatchToProps = (dispatch) => ({
  updateSearchCategory: (payload) => {
    dispatch(actions.updateSearchCategory(payload));
  },
  clearSearchResults: () => {
    dispatch(actions.clearSearchResults());
  },
  fetchApiProducts: () => {
    dispatch(actions.fetchApiProducts());
  },
});

export default withTracker(
  withBreadCrumb(connect(mapStateToProps, mapDispatchToProps)(SearchPageHOC), {
    name: "Search",
  })
);
