import { fromJS } from "immutable";
import * as ActionTypes from "../actions/types";
import { getJsonApiDataById } from "../constants/jsonapi_helpers";
import { dateFromTimestamp } from "../constants/misc";
import { mapImageDatum } from "../constants/map/image";
import { mapAuthorDatum } from "../constants/map/forum";

const INITIAL_STATE = {
  loading: false,
  statusCode: "200",
  pages: {},
  articles: {
    nodes: [],
    links: [],
    meta: [],
  },
  category: [],
  categoryFilterById: {},
  categoryFilterIds: [],
  searchArticle: null,
};

function storePage(state, action) {
  const { path, field_google_drive_id, created } = action.payload;
  const alias = path ? path[0].alias : null;

  return state
    .set("statusCode", "200")
    .setIn(
      ["pages", alias],
      fromJS({
        ...action.payload,
        created: dateFromTimestamp(created),
        alias,
      })
    )
    .set("loading", false);
}

function fetchPageFailed(state) {
  return state.set("statusCode", "404").set("loading", false);
}

function fetchPage(state) {
  return state.set("loading", true);
}

function storeArticles(state, action) {
  const { data, included, meta, links } = action.payload;
  const nodes = [];
  const authorIds = [];
  const authorById = {};

  data.forEach((datum) => {
    const {
      title,
      created,
      body,
      path,
      field_scrollspy_visible,
    } = datum.attributes;
    const { field_image, field_author, field_category } = datum.relationships;
    const authorId =
      field_author && field_author.data && field_author.data.id
        ? field_author.data.id
        : null;
    const authorDatum = getJsonApiDataById(authorId, included);

    const categoryId =
      field_category &&
      field_category.data &&
      field_category.data[0] &&
      field_category.data[0].id
        ? field_category.data[0].id
        : null;
    const category = getJsonApiDataById(categoryId, included);
    const categoryName =
      category && category.attributes && category.attributes.name
        ? category.attributes.name
        : null;

    nodes.push({
      title,
      created: new Date(created),
      description: body ? body.summary : null,
      url: path && path.alias ? path.alias : null,
      image: mapImageDatum(field_image, included),
      category: categoryName,
      scrollspyVisible: field_scrollspy_visible,
      author: mapAuthorDatum(authorDatum, included),
    });
  });

  return state
    .setIn(["articles", "nodes"], fromJS(nodes))
    .setIn(["articles", "links"], fromJS(links))
    .setIn(["articles", "meta"], fromJS(meta))
    .set("authorById", fromJS(authorById))
    .set("authorIds", fromJS(authorIds))
    .set("loading", false);
}

function fetchArticlesFailed(state) {
  return state.set("loading", false);
}

function fetchArticles(state) {
  return state.set("loading", false);
}

function fetchArticlesCategory(state) {
  return state.set("loading", false);
}

function fetchArticlesCategoryFailed(state) {
  return state.set("loading", false);
}

function storeArticlesCategory(state, action) {
  const { data } = action.payload;
  const categories = [];
  data.forEach((datum) => {
    const { name } = datum.attributes;
    categories.push({ name, uuid: datum.id });
  });
  return state.set("category", fromJS(categories)).set("loading", false);
}

function addCategoryFilter(state, action) {
  const { uuid, name } = action.payload;

  const categoryFilterIds = state.get("categoryFilterIds");

  if (categoryFilterIds.indexOf(uuid) > -1) return state;

  return state
    .set("categoryFilterIds", categoryFilterIds.push(uuid))
    .setIn(["categoryFilterById", uuid], fromJS({ uuid, name }));
}

function removeCategoryFilter(state, action) {
  const { uuid } = action.payload;
  const categoryFilterIds = state
    .get("categoryFilterIds")
    .filter((filterId) => filterId !== uuid);
  return state
    .set("categoryFilterIds", categoryFilterIds)
    .removeIn(["categoryFilterById", uuid]);
}

function searchArticle(state, action) {
  const search = action.payload;
  return state.set("searchArticle", search);
}

function clearCategoryFilter(state) {
  return state
    .set("categoryFilterIds", fromJS([]))
    .setIn(["categoryFilterById"], fromJS({}));
}

export const basicPage = {
  initialState: INITIAL_STATE,
  handlers: {
    [ActionTypes.FETCH_BASIC_PAGE]: fetchPage,
    [ActionTypes.STORE_BASIC_PAGE]: storePage,
    [ActionTypes.FETCH_BASIC_PAGE_FAILED]: fetchPageFailed,
    [ActionTypes.FETCH_ARTICLES]: fetchArticles,
    [ActionTypes.FETCH_ARTICLES_FAILED]: fetchArticlesFailed,
    [ActionTypes.STORE_ARTICLES]: storeArticles,
    [ActionTypes.FETCH_ARTICLES_CATEGORY]: fetchArticlesCategory,
    [ActionTypes.FETCH_ARTICLES_CATEGORY_FAILED]: fetchArticlesCategoryFailed,
    [ActionTypes.STORE_ARTICLES_CATEGORY]: storeArticlesCategory,
    [ActionTypes.ADD_CATEGORY_FILTER]: addCategoryFilter,
    [ActionTypes.REMOVE_CATEGORY_FILTER]: removeCategoryFilter,
    [ActionTypes.SEARCH_ARTICLES]: searchArticle,
    [ActionTypes.CLEAR_CATEGORY_FILTER]: clearCategoryFilter,
  },
};
