import React, { Component } from "react";
import "./searchNew.css";
import SearchIcon from "../svgs/icoMoon/icons/searchIconNew/searchIconNew";
import * as actions from "../../actions";
import { connect } from "react-redux";
import SearchInput from "./searchInput/searchInput";

type Props = {
  fetchSearchResults?: Function,
  mobile: boolean,
  page: number,
  itemsPerPage: number,
  expanding: boolean,
  autofocusMobile: boolean,
  type: string,
  query: string,
};

class SearchNew extends Component<Props> {
  SearchInputRef = React.createRef();

  onSearch = (evt) => {
    const { value } = evt.target;
    const { page, itemsPerPage, type } = this.props;
    this.props.fetchSearchResults({
      query: value,
      itemsPerPage,
      page,
      type,
    });
  };

  onClick = () => {
    if (this.SearchInputRef.current) {
      window.scroll({
        top: this.SearchInputRef.current.offsetTop + 90,
        behavior: "smooth",
      });
    }
  };

  componentDidUpdate(prevProps) {
    const { autofocusMobile } = this.props;
    if (
      this.SearchInputRef.current &&
      autofocusMobile &&
      window.window.innerWidth < 1199
    ) {
      this.SearchInputRef.current.getElementsByTagName("input")[0].focus();
      window.scroll({
        top: this.SearchInputRef.current.offsetTop + 90,
        behavior: "smooth",
      });
    }

    const { type } = this.props;
    if (type && type !== prevProps.type) {
      const { query, page, itemsPerPage, type } = this.props;
      this.props.fetchSearchResults({
        query,
        itemsPerPage,
        page,
        type,
      });
    }
  }

  render() {
    const { autofocusMobile, mobile, expanding, query } = this.props;
    return expanding ? (
      <form className="search">
        <div className="search__wrapper">
          <input
            value={query}
            onChange={this.onSearch}
            type="text"
            placeholder="Search for..."
            className="search__field"
          />
          <SearchIcon />
        </div>
      </form>
    ) : mobile ? (
      <input
        value={query}
        onChange={this.onSearch}
        type="text"
        placeholder="SEARCH"
        className="mobile-seach-input"
      />
    ) : (
      <span ref={this.SearchInputRef}>
        <SearchInput
          query={query}
          autoFocus
          onChange={this.onSearch}
          onClick={this.onClick}
        />
      </span>
    );
  }
}

// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state) => ({
  query: state.search.get("query"),
  page: state.search.get("page"),
  itemsPerPage: state.search.get("itemsPerPage"),
  type: state.search.get("type"),
});

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSearchResults: (payload) => {
      dispatch(actions.fetchSearchResults(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchNew);
