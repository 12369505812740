import * as ActionTypes from "./types";

export function fetchFaqs(payload) {
  return {
    type: ActionTypes.FETCH_FAQS,
    payload,
  };
}

export function fetchFaqsFailed(payload) {
  return {
    type: ActionTypes.FETCH_FAQS_FAILED,
    payload,
  };
}

export function storeFaqs(payload) {
  return {
    type: ActionTypes.STORE_FAQS,
    payload,
  };
}

export function searchFaq(payload) {
  return {
    type: ActionTypes.SEARCH_FAQ,
    payload,
  };
}
