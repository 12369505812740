import React, { useState, useEffect } from "react";
import "./productOverview.css";
import ApiProductFeatures from "../apiProductFeatures/apiProductFeatures";
import ApiProductAdvantages from "../apiProductAdvantages/apiProductAdvantages";
import ApiProductRelatedApis from "../apiProductRelatedApis/apiProductRelatedApis";
import Breadcrumb from "../breadcrumb/breadcrumb";
import { connect } from "react-redux";
import type { Image } from "../../constants/map/image";
import { getRouteUrl } from "../../routers";
import { ROUTE_PRODUCT_VIEW_SINGLE } from "../../routers/content/product";
import HeaderImage from "../headerImage/headerImage";
import BracketButton from "../bracketButton/bracketButton";
import ComparisonTable from "../comparisonTable/comparisonTable";

type Props = {
  overviewDisplayToggler: Function,
  subscribeToggler: Function,
  imageUrl: string,
  title: string,
  uuid: string,
  alias: string,
  description: string,
  features: Array,
  advantages: Object,
  related: Array<Object>,
  headerImage: Image,
  advantagesHeader: Object,
  breadcrumb: Object,
  isLoggedIn: boolean,
  disable_documentation: boolean,
  subscribed: boolean,
  comparisonTable: Object,
  commercialProduct: boolean
};

function ProductOverview(props: Props) {
  const {
    disable_documentation,
    commercialProduct,
    breadcrumb,
    alias,
    uuid,
    title,
    description,
    features,
    related,
    headerImage,
    overviewDisplayToggler,
    subscribeToggler,
    advantagesHeader,
    isLoggedIn,
    advantages,
    subscribed,
    comparisonTable,
  } = props;

  const [activeAdvantage, setActiveAdvantage] = useState(
    advantages ? advantages[0] : null
  );
  const showAdvantage = (uuid) => {
    const activeAdvantage = advantages.find((a) => a.uuid === uuid);
    setActiveAdvantage(activeAdvantage);
  };

  useEffect(() => {
    if (advantages && advantages[0]) {
      setActiveAdvantage(advantages[0]);
    }
  }, [advantages]);

  return (
    <>
      <Breadcrumb
        {...breadcrumb}
        params={{
          product: {
            name: title,
            href: alias
              ? alias
              : getRouteUrl(ROUTE_PRODUCT_VIEW_SINGLE, { apiProductId: uuid }),
          },
        }}
      />
      <HeaderImage
        title={{
          main: title,
          sub: "API PRODUCT",
        }}
        image={headerImage}
        description={description}
        uuid={uuid}
        commercialProduct={commercialProduct}
        subscribed={subscribed}
      >
        {!disable_documentation ? (
          <BracketButton
            title="TECHNICAL DOCUMENTATION"
            className="movingBrackets left"
            onClick={overviewDisplayToggler}
            caretEnable
          />
        ) : null}
      </HeaderImage>
      {features && features.length > 0 && (
        <ApiProductFeatures features={features} />
      )}
      {advantages && advantages.length > 0 && (
        <ApiProductAdvantages
          advantagesHeader={advantagesHeader}
          advantages={advantages}
          activeAdvantage={activeAdvantage}
          showAdvantage={showAdvantage}
          overviewDisplayToggler={overviewDisplayToggler}
          subscribeToggler={subscribeToggler}
          isLoggedIn={isLoggedIn}
          disable_documentation={disable_documentation}
        />
      )}
      {comparisonTable && comparisonTable.productNames.length > 0 && (
        <ComparisonTable comparisonTable={comparisonTable} uuid={uuid} />
      )}
      {related && related.length > 0 && (
        <ApiProductRelatedApis related={related} />
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.applicationUser.get("isLoggedIn"),
});

export default connect(mapStateToProps, null)(ProductOverview);
