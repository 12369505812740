import React from "react";
import { connect } from "react-redux";
import {
  parseOrganizationMembers,
  parseOrganizationRoles,
} from "../../../constants/parse/organization";
import type { Member, Role } from "../../../constants/map/organization";

type Props = {
  roles: Array<Role>,
  userMembership: Member,
};

type Config = {
  allowedPermissions: Array<string>,
  allowedRoles: Array<string>,
};

const OrganizationPermissionHOC = (
  organizationUuid: string,
  config: Config,
  notAllowedResponse: any = null
) => (WrappedComponent: any) => {
  const { allowedPermissions, allowedRoles } = config;
  function WithAuthorization(props: Props) {
    const { roles, userMembership } = props;
    if (!userMembership) return notAllowedResponse;

    const allowToRoles = allowedRoles
      ? allowedRoles
      : allowedPermissions
      ? roles
          .filter((role) =>
            role.permissions.some((permission) =>
              allowedPermissions.includes(permission)
            )
          )
          .map((role) => role.id)
      : [];

    const userRolesIncludeAllowed = userMembership.roles.some((role) =>
      allowToRoles.includes(role)
    );

    return userRolesIncludeAllowed ? (
      <WrappedComponent {...props} />
    ) : (
      notAllowedResponse
    );
  }

  const mapStateToProps = (state) => {
    const userUuid = state.applicationUser.get("uuid");
    const memberships = parseOrganizationMembers(
      state.organizationReducer.getIn(["byId", organizationUuid, "members"])
    );
    return {
      organizations: state.organizationReducer.get("byId"),
      roles: parseOrganizationRoles(state.organizationReducer.get("rolesById")),
      userMembership: memberships.find((m) => m.uuid === userUuid),
    };
  };

  return connect(mapStateToProps, null)(WithAuthorization);
};

export default OrganizationPermissionHOC;
