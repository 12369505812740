// @flow
import React from "react";
import { connect } from "react-redux";
import OrganizationsList from "../components/organization/organizationsList/organizationsList";
import { FRONTEND_ORIGIN_URL_NO_SLASH } from "../config";
import withTracker from "./withtracker";
import AuthorizationHOC from "./AuthorizationHOC";
import { REGISTERED_USERS } from "../constants/userGroups";
import * as actions from "../actions";
import withBreadCrumb from "./withBreadcrumb";
import { parseOrganizations } from "../constants/parse/organization";
import MetaData from "../components/meta/meta";
import FaSpinner from "../components/faSpinner/faSpinner";
import type { Organization } from "../constants/map/organization";
import { getRouteUrl } from "../routers";
import { ROUTE_ORGANIZATION_VIEW_ALL } from "../routers/content/organization";

type Props = {
  fetchOrganizations: Function,
  organizations: Array<Organization>,
  loading: boolean,
};

class OrganizationsHOC extends React.Component<Props> {
  componentDidMount() {
    this.props.fetchOrganizations();
  }

  render() {
    const { organizations, loading } = this.props;
    return (
      <div>
        <MetaData
          title="Organizations"
          url={
            FRONTEND_ORIGIN_URL_NO_SLASH +
            getRouteUrl(ROUTE_ORGANIZATION_VIEW_ALL)
          }
        />
        {loading && <FaSpinner loading />}
        <OrganizationsList organizations={organizations} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  organizations: parseOrganizations(state.organizationReducer.get("byId")),
  loading: state.organizationReducer.get("loading"),
});

const mapDispatchToProps = (dispatch) => ({
  fetchOrganizations: (payload) => {
    dispatch(actions.fetchOrganizations(payload));
  },
});

export default withTracker(
  AuthorizationHOC(REGISTERED_USERS)(
    withBreadCrumb(
      connect(mapStateToProps, mapDispatchToProps)(OrganizationsHOC),
      {
        name: "Organizations",
      }
    )
  )
);
