import React from "react";
import withTracker from "./withtracker";
import AuthorizationHOC from "./AuthorizationHOC";
import { REGISTERED_USERS } from "../constants/userGroups";
import withBreadCrumb from "./withBreadcrumb";
import { connect } from "react-redux";
import SectionStart from "../components/sectionStart/sectionStart";
import { Sidebar, SidebarHeader } from "../components/layouts/layout1";
import Title from "../components/title/title";
import Heading from "../components/heading/heading";
import GoogleDocsViewer from "../components/googleDocsViewer/googleDocsViewer";
import { useParams } from "react-router-dom";
/**
 *  Custom page component for Viewing google docs technical documentation.
 */

function GoogleDocsHOC() {
  let { fileId } = useParams();
  return (
    <SectionStart>
      <Sidebar
        sidebarLeft={
          <SidebarHeader>
            <Title type="section" displayEffect>
              <Heading level="1">GOOGLE DOCS</Heading>
            </Title>
          </SidebarHeader>
        }
        sidebarRight={
          <div>
            <GoogleDocsViewer fileId={fileId} />
          </div>
        }
      />
    </SectionStart>
  );
}

export default withTracker(
  AuthorizationHOC(REGISTERED_USERS)(
    withBreadCrumb(connect(null, null)(GoogleDocsHOC), {
      name: "Google Doc",
    })
  )
);
