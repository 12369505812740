import React from "react";
import {
  bemClassesFromModifiers,
  dateFromTimestamp,
} from "../../constants/misc";
import "./messageBox.css";

type Props = {
  title: string,
  isAnswer: boolean,
  created: string,
  body: string,
};

const MessageBox = (props: Props) => {
  const { title, isAnswer, created, body } = props;
  return (
    <div
      style={{ marginTop: "1rem" }}
      className={bemClassesFromModifiers("message", [
        isAnswer ? "answer" : null,
      ])}
    >
      <div className="support title_table">
        <div className="post_title title_cell">
          <div className="support_comment_user">{title}</div>
          <div className="support_comment_date">
            {dateFromTimestamp(created).toDateString()}
          </div>
        </div>
        <div className="title_right_side title_cell" />
      </div>

      <div className="comment_txt">
        <div dangerouslySetInnerHTML={{ __html: body }} />
      </div>
    </div>
  );
};

export default MessageBox;

//
// <div
//     className={bemClassesFromModifiers("message-box", [
//         isAnswer ? "answer" : null
//     ])}
// >
//     <div className="message-box__top clearfix">
//         <h5 className="message-box__title">
//             {title}
//         </h5>
//         <h5 className="message-box__subtitle">
//             {dateFromTimestamp(created).toDateString()}
//         </h5>
//     </div>
//     <div className="message-box__body"
//          dangerouslySetInnerHTML={{__html: body}}/>
// </div>
