import React, { Fragment } from "react";
import "./apiProductAdvantages.css";
import {
  Sidebar,
  SideBarButtons,
  SidebarHeader,
  SidebarViewAll,
} from "../layouts/layout1";
import Button from "../button/button";
import Title from "../title/title";
import Tag from "../tag/tag";
import SectionStart from "../sectionStart/sectionStart";
import CornersButton from "../button/cornersButton/cornersButton";
import SimpleButtonWrapper from "../simpleButtonWrapper/simpleButtonWrapper";
import LoginLink from "../loginLink/loginLink";

type Props = {
  advantagesHeader: Object,
  advantages: Array,
  activeAdvantage: Object,
  showAdvantage: Function,
  overviewDisplayToggler: Function,
  subscribeToggler: Function,
  isLoggedIn: boolean,
  disable_documentation: boolean,
};

const ApiProductAdvantages = (props: Props) => {
  const {
    disable_documentation,
    advantagesHeader,
    advantages,
    activeAdvantage,
    showAdvantage,
    overviewDisplayToggler,
    subscribeToggler,
    isLoggedIn,
  } = props;

  if (activeAdvantage) {
    if (activeAdvantage.description) {
      const links = activeAdvantage.description.match(/<a[^>]*/gm);
      if (links) {
        links.forEach((link) => {
          const linksBlank = link + ' target="_blank"';
          activeAdvantage.description = activeAdvantage.description.replace(
            link,
            linksBlank
          );
        });
      }
    }
  }

  return (
    <SectionStart>
      <div className="nbg-hub-apis-product">
        <Sidebar
          sidebarLeft={
            <Fragment>
              <SidebarHeader>
                <Title type="small">
                  <Tag className="display-effect">
                    {advantagesHeader.title
                      ? advantagesHeader.title.toUpperCase()
                      : "ADVANTAGES"}
                  </Tag>
                </Title>
                <Title type="descr">
                  <span
                    dangerouslySetInnerHTML={
                      advantagesHeader && advantagesHeader.body
                        ? {
                            __html: advantagesHeader.body.replace(
                              /(<([^>]+)>)/gi,
                              ""
                            ),
                          }
                        : null
                    }
                  />
                </Title>
                <SideBarButtons classNameWrapper="left-sidebar-button-wrapper">
                  {advantages.map((advantage) => (
                    <div
                      key={advantage.uuid}
                      className={
                        activeAdvantage &&
                        advantage.uuid === activeAdvantage.uuid
                          ? "cornersButton-wrapper enable"
                          : "cornersButton-wrapper"
                      }
                    >
                      <CornersButton
                        left
                        onClick={() => {
                          showAdvantage(advantage.uuid);
                        }}
                      >
                        {advantage && advantage.title
                          ? advantage.title.toUpperCase()
                          : null}
                      </CornersButton>
                    </div>
                  ))}
                </SideBarButtons>
              </SidebarHeader>
              <SidebarViewAll />
            </Fragment>
          }
          sidebarRight={
            <div className="txtBox-wrapper advantage-wrapper">
              <div className="advantage-wrapper-inner">
                <Title type="small">
                  <span>
                    {activeAdvantage && activeAdvantage.title
                      ? activeAdvantage.title.toUpperCase()
                      : null}
                  </span>
                </Title>
                {activeAdvantage && (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: activeAdvantage.description,
                    }}
                  />
                )}
              </div>
              <SimpleButtonWrapper position="left">
                {!disable_documentation ? (
                  <Button onClick={overviewDisplayToggler}>
                    TECHNICAL DOCUMENTATION
                  </Button>
                ) : null}
              </SimpleButtonWrapper>
              {isLoggedIn ? (
                <SimpleButtonWrapper position="left" lastButton>
                  {!disable_documentation && (
                    <Button id="subscribe_to_product_button" electro onClick={subscribeToggler}>
                      START USING THIS API
                    </Button>
                  )}
                </SimpleButtonWrapper>
              ) : (
                <SimpleButtonWrapper position="left" lastButton>
                  {!disable_documentation && (
                    <LoginLink>
                      <Button electro>START USING THIS API</Button>
                    </LoginLink>
                  )}
                </SimpleButtonWrapper>
              )}
            </div>
          }
        />
      </div>
    </SectionStart>
  );
};

export default ApiProductAdvantages;
