// @flow
import React from "react";
import Container from "../container/container";
import BracketButton from "../bracketButton/bracketButton";
import Button from "../button/button";
import Title from "../title/title";
import Heading from "../heading/heading";
import SimpleButtonWrapper from "../simpleButtonWrapper/simpleButtonWrapper";
import ArrowRightYellow from "../svgs/icoMoon/icons/arrowRightYellow/ArrowRightYellow";
import { AWARDS_IMG_KEYBOARD, AWARDS_IMG_LOGO } from "./images";
import "./heroArea.css";
import "./awards.css";
import HeroAnimation from "../heroAnimation/heroAnimation";
import { parseCarousel } from "../../constants/parse/banners";
import { connect } from "react-redux";
import LoginLink from "../loginLink/loginLink";
import Divider from "../divider/divider";

type Props = {
  isLoggedIn: boolean,
  carousel: Array<Object>,
};

function HeroArea(props: Props) {
  const { isLoggedIn, carousel } = props;
  return (
    <section style={{ overflowX: "hidden", backgroundColor: "inherit" }}>
      <Container>
        <div id="nbg_effect_area">
          <div className="home-page-title">
            <Title type="section">
              <Heading level="1">
                <br />
                <span className="hyper">CONNECT</span>
                <br />
                ON A GLOBAL SCALE
              </Heading>
            </Title>
            <Title type="descr">
              <span>Powerful Technology that goes far beyond banking.</span>
              {!isLoggedIn && (
                  <span>
                      <br/>NBG's Developer Portal for internal developers. <br/>
                  </span>
              )}
            </Title>
            <div className="hero-actions">
              <BracketButton
                caretEnable
                title="DISCOVER APIs"
                className="movingBrackets"
                url="/apiProducts"
              />
              {!isLoggedIn && (
                <SimpleButtonWrapper style={{ marginBottom: 2 }}>
                  <LoginLink>
                    <Button className="electro">
                      <span>REGISTER </span>
                      <ArrowRightYellow />
                    </Button>
                  </LoginLink>
                </SimpleButtonWrapper>
              )}
            </div>
          </div>
          <HeroAnimation carousel={carousel} />
          <div className="awards-section">
            <a href="https://developer.nbg.gr/news/Best-New-DX-Innovation-award-for-the-NBG-Technology-Hub">
              <img {...AWARDS_IMG_LOGO} />
            </a>
            <a href="https://developer.nbg.gr/news/Best-New-DX-Innovation-award-for-the-NBG-Technology-Hub">
              <img {...AWARDS_IMG_KEYBOARD} />
            </a>
          </div>
        </div>
        <Divider />
      </Container>
    </section>
  );
}

const mapStateToProps = (state) => ({
  carousel: parseCarousel(state.general.get("carousel")),
});

export default connect(mapStateToProps, null)(HeroArea);
