import React, { Fragment } from "react";
import ProfileIcon from "../../svgs/icoMoon/icons/profile/profile";
import LogoutIcon from "../../svgs/icoMoon/icons/logout/logout";

type Props = {
  logout: Function,
  mobile: boolean,
};

const AccountMenuLinksAuthenticated = (props: Props) => {
  const { logout, mobile } = props;
  return (
    <Fragment>
      <div>
        <a
          className={mobile ? "" : "profile"}
          href="https://microsites.nbg.gr/usersPortal/"
          /* eslint-disable-next-line react/jsx-no-target-blank */
          target="_blank"
        >
          <ProfileIcon />
          My Profile
        </a>
      </div>
      {!mobile && <hr />}
      <div>
        <a
          className={mobile ? "" : "profile"}
          style={{ cursor: "pointer" }}
          onClick={logout}
          /* eslint-disable-next-line react/jsx-no-target-blank */
          target="_blank"
        >
          <LogoutIcon />
          Sign Out
        </a>
      </div>
    </Fragment>
  );
};

export default AccountMenuLinksAuthenticated;
