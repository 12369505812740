// @flow
import React, { Fragment } from "react";
import "./faqs.css";
import { FRONTEND_ORIGIN_URL_NO_SLASH } from "../../config";
import { Sidebar, SidebarHeader, SidebarViewAll } from "../layouts/layout1";
import Title from "../title/title";
import Field from "../field/field";
import Tag from "../tag/tag";
import Heading from "../heading/heading";
import FaqItem from "../faqItem/faqItem";
import SectionStart from "../sectionStart/sectionStart";
import v4 from "uuid";
import SearchInput from "../search/searchInput/searchInput";
import MetaData from "../meta/meta";
import { getRouteUrl } from "../../routers";
import { ROUTE_PAGE_FAQ } from "../../routers/content/page";

type Props = {
  faqs: Object,
  faqsIds: Array<String>,
  questionExpanded: ?string,
  faqsCategory: Object,
  faqsCategoryIds: Array<String>,
  search: Function,
  searchFaqText: string,
};

const Faqs = (props: Props) => {
  const {
    faqs,
    faqsIds,
    faqsCategory,
    faqsCategoryIds,
    search,
    searchFaqText,
  } = props;
  return (
    <SectionStart>
      <MetaData
        title="Frequently Asked Questions"
        url={FRONTEND_ORIGIN_URL_NO_SLASH + getRouteUrl(ROUTE_PAGE_FAQ)}
        description="Look in our frequently asked questions section to see if you can find the details that you need."
      />
      <Sidebar
        sidebarLeft={
          <Fragment>
            <SidebarHeader>
              <Title type="section">
                <Heading level="1" className="display-effect">
                  FAQs
                </Heading>
              </Title>
              <Title type="small">
                <Tag className="display-effect">SEARCH</Tag>
              </Title>
              <Field className="search">
                <SearchInput onChange={search} placeholder="keywords,etc" />
              </Field>
            </SidebarHeader>
            <SidebarViewAll />
          </Fragment>
        }
        sidebarRight={
          <div className="nbg-faq-wrapper">
            {faqsCategoryIds &&
              faqsCategoryIds.map((categoryId) => (
                <div key={v4()}>
                  <div className="faq_category">
                    {faqsCategory[categoryId] && faqsCategory[categoryId].name
                      ? faqsCategory[categoryId].name
                      : null}
                  </div>
                  {faqsIds &&
                    faqsIds.length > 0 &&
                    faqsIds
                      .filter(
                        (faqId) =>
                          faqs[faqId] && faqs[faqId].categoryId === categoryId
                      )
                      .map((faqId) => (
                        <FaqItem
                          searchFaqText={searchFaqText}
                          faq={faqs[faqId]}
                          key={faqId}
                        />
                      ))}
                </div>
              ))}
            {faqsIds &&
              faqsIds.length > 0 &&
              faqsIds
                .filter((faqId) => faqs[faqId] && !faqs[faqId].categoryId)
                .map((faqId) => (
                  <FaqItem
                    key={v4()}
                    faq={faqs[faqId]}
                    searchFaqText={searchFaqText}
                  />
                ))}
          </div>
        }
      />
    </SectionStart>
  );
};

export default Faqs;
