// @flow
import React, { useEffect } from "react";
import { FRONTEND_ORIGIN_URL_NO_SLASH } from "../config";
import News from "../components/news/news";
import withTracker from "./withtracker";
import withBreadCrumb from "./withBreadcrumb";
import MetaData from "../components/meta/meta";
import { getRouteUrl } from "../routers";
import { ROUTE_PAGE_NEWS } from "../routers/content/page";
import { REGISTERED_USERS } from "../constants/userGroups";
import AuthorizationHOC from "./AuthorizationHOC";

const internal = window.location.href.indexOf("internal");

function ArticlesHOC() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <MetaData
        title="Blog"
        url={FRONTEND_ORIGIN_URL_NO_SLASH + getRouteUrl(ROUTE_PAGE_NEWS)}
        description="Learn all the latest NBG Developer Portal news."
      />
      <News viewStyle="full" />
    </div>
  );
}

export default internal > -1
  ? withTracker(
      AuthorizationHOC(REGISTERED_USERS)(
        withBreadCrumb(ArticlesHOC, {
          name: "Blog",
        })
      )
    )
  : withTracker(
      withBreadCrumb(ArticlesHOC, {
        name: "Blog",
      })
    );
