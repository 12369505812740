// @flow
import React, { Fragment } from "react";
import "./support.css";
import { FRONTEND_ORIGIN_URL_NO_SLASH } from "../../config";
import { Sidebar, SidebarHeader } from "../layouts/layout1";
import Title from "../title/title";
import Heading from "../heading/heading";
import BracketButton from "../bracketButton/bracketButton";
import SectionStart from "../sectionStart/sectionStart";
import MetaData from "../meta/meta";
import { getRouteUrl } from "../../routers";
import {
  ROUTE_SUPPORT_INDEX,
  ROUTE_SUPPORT_TICKET_VIEW_ALL,
} from "../../routers/content/support";
import { ROUTE_FORUM_VIEW_ALL } from "../../routers/content/forum";
import { ROUTE_PAGE_FAQ } from "../../routers/content/page";

const Support = () => {
  return (
    <Fragment>
      <MetaData
        title="Support"
        type="article"
        url={FRONTEND_ORIGIN_URL_NO_SLASH + getRouteUrl(ROUTE_SUPPORT_INDEX)}
        description="Send us feedback by using our contact form. Look in our frequently asked questions section to see if you can find the details that you need. Raise a support ticket and our team will contact you for any problems you might experience."
      />
      <SectionStart>
        <Sidebar
          sidebarLeft={
            <SidebarHeader>
              <Title type="section">
                <Heading level="1" className="display-effect">
                  SUPPORT
                </Heading>
              </Title>
            </SidebarHeader>
          }
          sidebarRight={
            <div className="support_steps">
              <div className="double-box dots_bg">
                <Heading level="4" style={{ height: "100px" }}>
                  DEVELOPER FORUM
                </Heading>
                <p>
                  Search and participate in our developer forum. Ask questions
                  as the experienced developers in the forum community might
                  know the answers and are happy to help.
                </p>
                <div className="double-box-button-wrapper">
                  <BracketButton
                    caretEnable
                    title="VISIT FORUM"
                    className="movingBrackets right"
                    url={getRouteUrl(ROUTE_FORUM_VIEW_ALL)}
                  />
                </div>
              </div>
              <div className="double-box opacity-white_bg">
                <Heading style={{ height: "100px" }} level="4">
                  FAQ
                </Heading>
                <p>
                  Look in our frequently asked questions section and check if
                  you can find the details that you need.
                </p>
                <div className="double-box-button-wrapper">
                  <BracketButton
                    caretEnable
                    title="VIEW FAQ"
                    className="movingBrackets right"
                    url={getRouteUrl(ROUTE_PAGE_FAQ)}
                  />
                </div>
              </div>
              <div className="double-box lines_bg">
                <Heading style={{ height: "100px" }} level="4">
                  SUPPORT TICKETS
                </Heading>
                <p>
                  Should it be necessary this section is where you can see open
                  support tickets or raise a new one.
                </p>
                <div className="double-box-button-wrapper">
                  <BracketButton
                    caretEnable
                    title="RAISE A SUPPORT TICKET"
                    className="movingBrackets right"
                    url={getRouteUrl(ROUTE_SUPPORT_TICKET_VIEW_ALL)}
                  />
                </div>
              </div>
            </div>
          }
        />
      </SectionStart>
    </Fragment>
  );
};

export default Support;
