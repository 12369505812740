import { fromJS } from "immutable";
import * as ActionTypes from "../actions/types";

const INITIAL_STATE = {
  loading: false,
  results: null,
  query: "",
  type: null,
  itemsPerPage: 10,
  page: 0,
  meta: null,
};

function fetchSearchResults(state, action) {
  const { query, page, itemsPerPage, meta, type } = action.payload;
  return state
    .set("loading", true)
    .set("query", query)
    .set("page", page)
    .set("itemsPerPage", itemsPerPage)
    .set("type", type)
    .set("meta", meta);
}

function updateSearchCategory(state, action) {
  const category = action.payload;
  return state.set("type", category).set("page", 0);
}

function storeSearchResults(state, action) {
  const results = action.payload;
  return state
    .set("loading", false)
    .set("meta", fromJS(results.meta))
    .set("results", fromJS(results));
}

function fetchSearchResultsFailed(state) {
  return state
    .set("loading", false)
    .set("message", "Failed to create organization.");
}

function clearPager(state) {
  return state.set("page", 0);
}

function clearSearchResults(state) {
  return state.set("results", null);
}

export const search = {
  initialState: INITIAL_STATE,
  handlers: {
    [ActionTypes.FETCH_SEARCH_RESULTS]: fetchSearchResults,
    [ActionTypes.STORE_SEARCH_RESULTS]: storeSearchResults,
    [ActionTypes.FETCH_SEARCH_RESULTS_FAILED]: fetchSearchResultsFailed,
    [ActionTypes.UPDATE_SEARCH_CATEGORY]: updateSearchCategory,
    [ActionTypes.CLEAR_PAGER]: clearPager,
    [ActionTypes.CLEAR_SEARCH_RESULTS]: clearSearchResults,
  },
};
