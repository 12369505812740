import React from "react";
import { Field, Form, Formik } from "formik";
import SubmitButton from "../../submitButton/submitButton";

type Props = {
  saveTicketId: Function,
};
const ReplyForm = ({ saveTicketId }: Props) => {
  return (
    <div style={{ marginTop: "8px" }}>
      <Formik
        initialValues={{
          body: "",
        }}
        onSubmit={async (values) => {
          await saveTicketId(values);
        }}
      >
        <Form>
          <label style={{ margin: "4px" }} htmlFor="body">
            Reply
          </label>
          <Field
            className="body_field"
            id="body"
            name="body"
            placeholder="Enter a reply"
            component="textarea"
            rows="6"
          />
          <SubmitButton title="Submit" styles={{ marginTop: "8px" }} />
        </Form>
      </Formik>
    </div>
  );
};

export default ReplyForm;
