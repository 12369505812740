import * as ActionTypes from "./types";

export function fetchSearchResults(payload) {
  return {
    type: ActionTypes.FETCH_SEARCH_RESULTS,
    payload,
  };
}

export function storeSearchResults(payload) {
  return {
    type: ActionTypes.STORE_SEARCH_RESULTS,
    payload,
  };
}

export function fetchSearchResultsFailed(payload) {
  return {
    type: ActionTypes.FETCH_SEARCH_RESULTS_FAILED,
    payload,
  };
}

export function updateSearchCategory(payload) {
  return {
    type: ActionTypes.UPDATE_SEARCH_CATEGORY,
    payload,
  };
}

export function clearPager() {
  return {
    type: ActionTypes.CLEAR_PAGER,
  };
}
export function clearSearchResults() {
  return {
    type: ActionTypes.CLEAR_SEARCH_RESULTS,
  };
}
