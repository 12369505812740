// @flow
import React from "react";
import "./organizationMembersListRow.css";
import EditIcon from "../../../svgs/icoMoon/icons/edit/editIcon";
import UsersIcon from "../../../svgs/icoMoon/icons/users/usersIcon";
import type { Member } from "../../../../constants/map/organization";
import {
  MEMBER_DEFINITIONS,
  ROLE_DEFINITIONS,
} from "../../../../constants/organizationRoles";
import OrganizationPermissionHOC from "../../organizationPermissionHOC/OrganizationPermissionHOC";
import { ORGANIZATION_PERMISSION_ADMINISTER_MEMBERS } from "../../../../constants/organizationPermissions";

type Props = {
  ...Member,
  onEdit: Function,
};

function OrganizationMembersListRow(props: Props) {
  const { onEdit, userName, roles, status, uuid, organizationUuid } = props;

  const DeleteButton = OrganizationPermissionHOC(organizationUuid, {
    allowedPermissions: [ORGANIZATION_PERMISSION_ADMINISTER_MEMBERS],
  })(() => (
    <div onClick={() => onEdit(uuid)}>
      <EditIcon />
    </div>
  ));

  return (
    <li className="row line" key={uuid}>
      <div className="cell forum" data-title="MEMBER NAME">
        <div className="arr_line_1" style={{ wordBreak: "break-word" }}>
          <UsersIcon />
          {" " + userName}
        </div>
      </div>
      <div className="cell tr_white topic" data-title="STATUS">
        {MEMBER_DEFINITIONS.find((def) => def.id === status).name}
      </div>
      <div className="cell tr_white posts" data-title="ROLE">
        {roles
          .filter((role) => ROLE_DEFINITIONS.find((r) => r.id === role))
          .map((role) => ROLE_DEFINITIONS.find((r) => r.id === role).name)
          .join(", ")}
      </div>
      <div className="cell tr_white posts" data-title="EDIT">
        <DeleteButton />
      </div>
    </li>
  );
}

export default OrganizationMembersListRow;
