export const parseLinks = (links: Object) => {
  if (links === undefined) return {};
  return {
    first: links.getIn(["first", "href"]),
    prev: links.getIn(["prev", "href"]),
    self: links.getIn(["self", "href"]),
    next: links.getIn(["next", "href"]),
    last: links.getIn(["last", "href"]),
  };
};

export const parseMeta = (meta: Object) => {
  if (meta === undefined) return {};
  return {
    count: meta.get("count"),
    message: "Total Articles",
  };
};
