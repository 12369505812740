// @flow
import React from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import Pager from "../components/pager/pager";
import { calcNextPage } from "../constants/misc";
import { parseLinks, parseMeta } from "../helperFunctions/parsePager";

type Props = {
  fetchSearchResults: Function,
  page: number,
  meta: {
    count: number,
  },
  links: Object,
  fetchForumTopicComments: Function,
  updateForumField: Function,
  forum: string,
  query: string,
  itemsPerPage: number,
  page: number,
  clearPager: Function,
};

class SearchPagePagerHOC extends React.Component<Props> {
  onPagerClick = (evt, link) => {
    const { page, itemsPerPage } = this.props;
    const { query } = this.props;
    const meta = parseMeta(this.props.meta);

    const nextPage = calcNextPage(link.name, page, meta.count, itemsPerPage);

    this.props.fetchSearchResults({
      page: meta && meta.count && page === nextPage ? link.name - 1 : nextPage,
      query,
      itemsPerPage,
    });

    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  };

  componentDidUpdate(prevProps) {
    if (this.props.query !== prevProps.query) {
      this.props.clearPager();
    }
  }

  render() {
    const { meta, links } = this.props;
    const { page, itemsPerPage } = this.props;
    return (
      <Pager
        page={page + 1}
        meta={parseMeta(meta)}
        links={parseLinks(links)}
        onClick={this.onPagerClick}
        itemsPerPage={itemsPerPage}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  meta: state.search.get("meta"),
  query: state.search.get("query"),
  page: state.search.get("page"),
  itemsPerPage: state.search.get("itemsPerPage"),
});

const mapDispatchToProps = (dispatch) => ({
  fetchSearchResults: (payload) => {
    dispatch(actions.fetchSearchResults(payload));
  },
  clearPager: () => {
    dispatch(actions.clearPager());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchPagePagerHOC);
