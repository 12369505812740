import React, { useState } from "react";
import { Sidebar, SidebarHeader } from "../layouts/layout1";
import Title from "../title/title";
import Heading from "../heading/heading";
import { BASE_URL } from "../../config";
import BracketButton from "../bracketButton/bracketButton";
import classNames from "classnames";
import DynamicScrollSpy from "../scrollSpy/scrollSpy";
import Breadcrumb from "../breadcrumb/breadcrumb";
import HeaderImage from "../headerImage/headerImage";
import { getRouteUrl } from "../../routers";
import {
  ROUTE_PAGE_NEWS,
  ROUTE_PAGE_NEWS_OLD,
} from "../../routers/content/page";
import "./../googleDocsViewer/googleDrive.css";
import "./basicPage.css";
import "./article.css";
import { cleanText } from "../redoc-tryout/redocParamBody/redocParamBody";
import Divider from "../divider/divider";
import SectionStart from "../sectionStart/sectionStart";
import Button from "../button/button";
import { bemClassesFromModifiers } from "../../constants/misc";
import { DownloadIcon } from "../svgs/uploadDownloadIcon";
import htmlToPdfmake from "html-to-pdfmake";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { imageDictionary } from "./pdfImage";
import { useGoogleDrive } from "../../customHooks/useGoogleDrive";
import GoogleAuthButton from "../googleAuthButton/googleAuthButton";

type Props = {
  driveId?: string,
  title: string,
  image: Object,
  html: any,
  fieldScrollspyVisible: boolean,
  author: Object,
  created: Date,
  pathname: string,
};

const BasicPageHeader = (props: Props) => {
  const { title } = props;
  return (
    <SidebarHeader>
      <Title type="section">
        <Heading level="1" className="folowed_by_subtitle display-effect">
          {title}
        </Heading>
      </Title>
    </SidebarHeader>
  );
};

const BasicPage = (props: Props) => {
  const { handleAuthClick, signedInUser, googleDriveHtml } = useGoogleDrive(
    props.driveId
  );
  const [imgShow, setImgShow] = useState(true);
  const { title, image, driveId, pathname, fieldScrollspyVisible } = props;
  let { html } = props;

  const selectors = ["h1", "h2", "h3"];
  selectors.forEach((selector) => {
    const pattern = new RegExp(`<${selector}.+(>|$)`, "gm");
    const wholeTag = html.match(pattern);

    if (wholeTag) {
      wholeTag.forEach((tag) => {
        const hTagContent = cleanText(tag);
        const hTagContentWithoutSpace = hTagContent
          .replace(/\s/g, "")
          .replace("&amp;", "&");
        const finalTag =
          "<" +
          selector +
          " id=" +
          hTagContentWithoutSpace +
          ">" +
          hTagContent +
          "</" +
          selector +
          ">";
        html = html.replace(tag, finalTag);
      });
    }
  });

  const links = html.match(/<a\b[^>]*/gm);
  if (links) {
    links.forEach((link) => {
      const linksBlank = link + ' target="_blank"';
      html = html.replace(link, linksBlank);
    });
  }

  const notWorkingImages = html.match(/<img[^>]+src="\/[^>]+(>|$)/gm);
  if (notWorkingImages) {
    notWorkingImages.forEach((notWorkingImage) => {
      const workingImage = notWorkingImage.replace('src="', 'src="' + BASE_URL);
      html = html.replace(notWorkingImage, workingImage);
    });
  }

  function convertImgToBase64URL(url, callback, outputFormat) {
    var img = new Image();
    img.crossOrigin = "Anonymous";
    img.onload = function () {
      var canvas = document.createElement("CANVAS"),
        ctx = canvas.getContext("2d"),
        dataURL;
      canvas.height = img.height;
      canvas.width = img.width;
      ctx.drawImage(img, 0, 0);
      dataURL = canvas.toDataURL(outputFormat);
      callback(dataURL);
      canvas = null;
    };
    img.src = url;
  }

  const transformPdfData = (data) => {
    data
      .filter((r) => r["stack"])
      .map(
        (node) =>
          node["stack"][0].image &&
          convertImgToBase64URL(node["stack"][0].image, function (base64Img) {
            node["stack"][0].image = base64Img;
            node["stack"][0].alignment = "center";
            node["stack"][0].fit = [450, 450];
            node["stack"][0].margin = 20;
          })
      );

    return data;
  };
  const downloadPdf = async () => {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    const val = htmlToPdfmake(driveId ? googleDriveHtml : html, {
      defaultStyles: {
        // change the default styles
        p: { margin: [0, 0, 0, 0] },
        h2: { marginBottom: 10, marginTop: 10 },
        table: { marginBottom: 10, marginTop: 10 },
      },
    });

    const dd = {
      footer: function (currentPage, pageCount) {
        return {
          margin: 10,
          columns: [
            {
              fontSize: 9,
              text: [
                {
                  text:
                    "--------------------------------------------------------------------------" +
                    "\n",
                  margin: [0, 20],
                },
                {
                  text:
                    "© National Bank Of Greece" +
                    currentPage.toString() +
                    " of " +
                    pageCount,
                },
              ],
              alignment: "center",
            },
          ],
        };
      },
      content: [
        {
          image: imageDictionary.images.logo,
          width: 222,
          height: 145,
          margin: 20,
          alignment: "center",
        },
        transformPdfData(val),
      ],
      styles: {
        "html-pre": {
          wordBreak: "break-all",
        },
      },
    };

    setTimeout(() => {
      return pdfMake.createPdf(dd).download();
    }, 1000);
  };

  return (
    <div>
      <Breadcrumb
        name="{title}"
        parents={[
          pathname.includes(ROUTE_PAGE_NEWS.path) ||
          pathname.includes(ROUTE_PAGE_NEWS_OLD.path)
            ? "Blog"
            : "{parent}",
        ]}
        params={{
          parent: "Blog",
          title: {
            name: title,
          },
        }}
      />
      {(pathname.includes(ROUTE_PAGE_NEWS_OLD.path) ||
        pathname.includes(ROUTE_PAGE_NEWS.path)) && (
        <HeaderImage
          image={
            image && imgShow
              ? {
                  url: BASE_URL + image,
                }
              : null
          }
          onError={() => setImgShow(false)}
          title={{
            main: title,
            sub: (
              <BracketButton
                title="BACK TO BLOG"
                url={getRouteUrl(ROUTE_PAGE_NEWS)}
                caretEnable
                movingBrackets
                className="left"
                style={{ textAlign: "left" }}
              />
            ),
          }}
        />
      )}
      <SectionStart>
        {!fieldScrollspyVisible ? (
          <div className="nbg-hub-apis-product">
            <Sidebar
              sidebarLeft={<span>&nbsp;</span>}
              sidebarRight={
                <div
                  className={classNames("article", {
                    "article--google": driveId,
                  })}
                  dangerouslySetInnerHTML={{ __html: html }}
                />
              }
            />
          </div>
        ) : (
          <DynamicScrollSpy
            sidebarLeft={
              <div className="clearfix">
                <BasicPageHeader {...props} />
              </div>
            }
            selectors={selectors}
          >
            <div>
              {!driveId ? (
                <Button
                  style={{ position: "absolute", right: 0, top: 20 }}
                  onClick={downloadPdf}
                  className={bemClassesFromModifiers(
                    "fileImportExport__button",
                    ["download"]
                  )}
                >
                  <DownloadIcon />
                  <span>Print to PDF</span>
                </Button>
              ) : (
                <a
                  style={{ position: "absolute", right: 0, top: 20 }}
                  className={bemClassesFromModifiers(
                    "fileImportExport__button",
                    ["download"]
                  )}
                  href={`https://docs.google.com/feeds/download/documents/export/Export?id=${driveId}&exportFormat=pdf`}
                >
                  <DownloadIcon />
                  <span>Download</span>
                </a>
              )}
              {driveId && (
                <GoogleAuthButton
                  signedInUser={signedInUser}
                  handleAuthClick={handleAuthClick}
                />
              )}
              <div
                className={classNames("article", {
                  "article--google": driveId,
                })}
                dangerouslySetInnerHTML={{
                  __html: driveId ? googleDriveHtml : html,
                }}
              />
            </div>
          </DynamicScrollSpy>
        )}
        <Divider />
      </SectionStart>
    </div>
  );
};

export default BasicPage;
