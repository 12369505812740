import React, { Component } from "react";
import { FRONTEND_ORIGIN_URL_NO_SLASH } from "../config";
import withTracker from "./withtracker";
import AuthorizationHOC from "./AuthorizationHOC";
import { REGISTERED_USERS } from "../constants/userGroups";
import MetaData from "../components/meta/meta";
import SectionStart from "../components/sectionStart/sectionStart";
import { Sidebar, SidebarHeader } from "../components/layouts/layout1";
import Title from "../components/title/title";
import Heading from "../components/heading/heading";
import * as actions from "../actions";
import { connect } from "react-redux";
import { getRouteUrl } from "../routers";
import {
  ROUTE_ORGANIZATION_CREATE,
  ROUTE_ORGANIZATION_VIEW_SINGLE,
} from "../routers/content/organization";
import OrganizationForm from "../components/organization/organizationForm/organizationForm";
import FaSpinner from "../components/faSpinner/faSpinner";
import withBreadCrumb from "./withBreadcrumb";

type Props = {
  match: {
    params: {
      organizationId: string,
    },
  },
  organizations: Object,
  loading: boolean,
  breadcrumb: Object,
  match: Object,
  fetchOrganizationMemberships: Function,
  clearOrganizationForm: Function,
  fetchOrganizations: Function,
  createOrganization: Function,
  updateFormField: Function,
};

class OrganizationCreateHOC extends Component<Props> {
  handleInputChange = (event: Object) => {
    const target = event.target;
    const value =
      target.type === "checkbox"
        ? target.checked
        : target.files
        ? target.files
        : target.value;
    const name = target.name;
    this.props.updateFormField({
      name,
      value,
    });
  };

  onSubmit = (evt) => {
    const payload = {
      uuid: evt.get("uuid"),
      title: evt.get("title"),
      description: evt.get("description"),
      image: evt.get("image"),
      redirectAfterUpdate: getRouteUrl(ROUTE_ORGANIZATION_VIEW_SINGLE, {
        organizationId: evt.get("uuid"),
      }),
    };
    this.props.createOrganization(payload);
  };

  componentDidMount() {
    this.props.clearOrganizationForm();
  }

  render() {
    const { loading } = this.props;

    return (
      <div>
        <MetaData
          title="Edit Organization"
          url={
            FRONTEND_ORIGIN_URL_NO_SLASH +
            getRouteUrl(ROUTE_ORGANIZATION_CREATE)
          }
        />
        <SectionStart>
          <Sidebar
            sidebarLeft={
              <SidebarHeader>
                <Title type="section" displayEffect>
                  <Heading level="1">CREATE ORGANIZATION</Heading>
                </Title>
              </SidebarHeader>
            }
            sidebarRight={
              <div>
                <OrganizationForm
                  hideImageUpload
                  handleInputChange={this.handleInputChange}
                  onSubmit={this.onSubmit}
                />
                {loading && <FaSpinner loading />}
              </div>
            }
          />
        </SectionStart>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.organizationFormReducer.get("loading"),
});

const mapDispatchToProps = (dispatch) => ({
  updateFormField: (payload) => {
    dispatch(actions.updateOrganizationFormField(payload));
  },
  clearOrganizationForm: (payload) => {
    dispatch(actions.clearOrganizationForm(payload));
  },
  createOrganization: (payload) => {
    dispatch(actions.createOrganization(payload));
  },
});

export default withTracker(
  AuthorizationHOC(REGISTERED_USERS)(
    withBreadCrumb(
      connect(mapStateToProps, mapDispatchToProps)(OrganizationCreateHOC),
      {
        name: "Create",
        parents: ["Organizations"],
      }
    )
  )
);
