// @flow
import React, { useEffect, useState } from "react";
import UnsubscribeFromApiProductModal from "../unsubscribeFromApiProductModal/unsubscribeFromApiProductModal";
import ApplicationSubscription from "../applicationSubscriptions/applicationSubscriptions";
import v4 from "uuid/v4";
import { Sidebar, SideBarButtons, SidebarHeader } from "../layouts/layout1";
import Title from "../title/title";
import SectionStart from "../sectionStart/sectionStart";
import CornersButton from "../button/cornersButton/cornersButton";
import BracketButton from "../bracketButton/bracketButton";
import { getRouteUrl } from "../../routers";
import { ROUTE_PRODUCT_VIEW_ALL } from "../../routers/content/product";
import type { Subscription } from "../../constants/map/application";
import SubscribeProductToApp from "../subscribeProductsToApp/subscribeProductToApp";

type Props = {
  openUnsubscribeFromApiProductModal: Function,
  closeUnsubscribeFromApiProductModal: Function,
  unsubscribeFromApiProductModalIsOpen: boolean,
  applicationId?: string,
  subscriptions: Array<Subscription>,
  applicationStatus: string,
  activeSubscription: Object,
  showSubscription: Function,
};

const ApplicationEditSubscriptions = (props: Props) => {
  const {
    openUnsubscribeFromApiProductModal,
    closeUnsubscribeFromApiProductModal,
    unsubscribeFromApiProductModalIsOpen,
    applicationId,
    subscriptions,
    applicationStatus,
  } = props;
  const [activeSubscription, setActiveSubscription] = useState(null);
  const showSubscription = (uuid: string) => {
    const subscriptionUuid = subscriptions.find(
      (subscription) => subscription.uuid === uuid
    );
    setActiveSubscription(subscriptionUuid);
  };

  useEffect(() => {
    setActiveSubscription(subscriptions[0]);
  }, [subscriptions]);

  return (
    <SectionStart>
      <Sidebar
        sidebarLeft={
          <SidebarHeader>
            <Title type="small">SUBSCRIPTIONS</Title>
            {subscriptions.length ? (
              <SideBarButtons classNameWrapper="left-sidebar-button-wrapper">
                {subscriptions &&
                  subscriptions.map((subscription) => {
                    return (
                      <div
                        key={subscription.uuid}
                        className={
                          activeSubscription &&
                          subscription.uuid === activeSubscription.uuid
                            ? "cornersButton-wrapper enable"
                            : "cornersButton-wrapper"
                        }
                      >
                        <CornersButton
                          onClick={() => {
                            showSubscription(subscription.uuid);
                          }}
                          left
                        >
                          {subscription.title}
                        </CornersButton>
                      </div>
                    );
                  })}
              </SideBarButtons>
            ) : null}
          </SidebarHeader>
        }
        sidebarRight={
          <div className="txtBox-wrapper advantage-wrapper">
            {activeSubscription ? (
              <ApplicationSubscription
                title={activeSubscription.title}
                version={activeSubscription.version}
                uuid={activeSubscription.uuid}
                deprecated={activeSubscription.deprecated}
                scopes={activeSubscription.apiEndpoints}
                openUnsubscribeFromApiProductModal={
                  openUnsubscribeFromApiProductModal
                }
                applicationStatus={applicationStatus}
                key={v4()}
              />
            ) : applicationStatus !== "PRODUCTION" && !subscriptions.length ? (
              <div style={{ padding: 20 }}>
                <span style={{ marginLeft: "9px" }}>
                  You need an active subscription to use this app.
                </span>
                <SubscribeProductToApp
                    subscriptions={subscriptions}
                    applicationId={applicationId}
                />
              </div>
            ) : null}
            <UnsubscribeFromApiProductModal
              modalIsOpen={unsubscribeFromApiProductModalIsOpen}
              closeModal={closeUnsubscribeFromApiProductModal}
              applicationId={applicationId}
            />
            {subscriptions.length && applicationStatus !== "PRODUCTION" ? (
              <div style={{ padding: 20 }}>
                <span
                  style={{
                    marginLeft: "9px",
                    marginRight: "9px",
                    float: "left",
                  }}
                >
                  You can{" "}
                </span>
                <SubscribeProductToApp
                  subscriptions={subscriptions}
                  applicationId={applicationId}
                />
                <span
                  style={{
                    marginLeft: "9px",
                    marginRight: "9px",
                    float: "left",
                  }}
                >
                  {" "}
                  to more APIs{" "}
                </span>
              </div>
            ) : null}
          </div>
        }
      />
    </SectionStart>
  );
};

export default ApplicationEditSubscriptions;
