import React from "react";
import Table from "../table/table";
import { File as FileType } from "../../constants/map/file";
import { BASE_URL } from "../../config";
import FileIcon from "../svgs/fileIcon";
import { humanFileSize } from "../../constants/misc";

type Props = {
  files: Array<FileType>,
  rowProps?: Object,
};

const TABLE_HEADER = [
  {
    title: "FILENAME",
  },
  {
    title: "SIZE",
  },
  {
    title: "OPERATIONS",
  },
];

const FileTable = (props: Props) => {
  const { files, rowProps } = props;
  return files && files.length > 0 ? (
    <Table
      header={TABLE_HEADER}
      cellsNumber={2}
      rows={files}
      rowProps={rowProps}
      RenderRow={RenderTableRow}
    />
  ) : (
    <span>There are no files yet.</span>
  );
};

const RenderTableRow = (props: FileType) => {
  const { fileName, fileSize, url, uuid, onRemove } = props;
  return (
    <li className="row line">
      <div className="cell forum" data-title="FILENAME">
        <div className="arr_line_1">
          <a href={BASE_URL + url} target="_blank">
            <FileIcon
              style={{
                maxWidth: 15,
                marginRight: 8,
                fill: "white",
                verticalAlign: "bottom",
              }}
            />
            {fileName}
          </a>
        </div>
      </div>
      <div className="cell tr_white posts" data-title="FILESIZE">
        {humanFileSize(fileSize, true)}
      </div>
      <div className="cell tr_white posts" data-title="FILESIZE">
        <a href={BASE_URL + url} download target="_blank">
          DOWNLOAD
        </a>
        <span> / </span>
        {onRemove && (
          <a
            href="#"
            onClick={(event) => {
              event.preventDefault();
              onRemove(uuid);
            }}
          >
            REMOVE
          </a>
        )}
      </div>
    </li>
  );
};

export default FileTable;
