// @flow
import React from "react";
import "./gettingStarted.css";
import { connect } from "react-redux";
import Container from "../container/container";
import { Sidebar, SidebarHeader, SidebarViewAll } from "../layouts/layout1";
import Step from "../step/step";
import Authorization from "../authorization/authorization";
import Title from "../title/title";
import Heading from "../heading/heading";
import Divider from "../divider/divider";

type Props = {
  blocks: Object,
};

function GettingStarted(props: Props) {
  const { blocks } = props;
  return (
    <div>
      <section>
        <Container>
          <Container inner>
            <Sidebar
              sidebarLeft={
                <div>
                  <SidebarHeader>
                    <Title type="section">
                      <Heading
                        level="1"
                        className="display-effect"
                        style={{ width: "250px" }}
                        dangerouslySetInnerHTML={{
                          __html: "GETTING </br> STARTED",
                        }}
                      />
                    </Title>
                  </SidebarHeader>
                  <SidebarViewAll />
                </div>
              }
              sidebarRight={
                <div className="timeline">
                  <div className="dots_section dots_bg" />
                  <div className="lines_section lines_bg" />
                  <ul>
                    {blocks.length
                      ? blocks.map((block, index) =>
                          blocks.length === index + 1 ? (
                            <Authorization
                              key={"started" + index}
                              title={block.title}
                              description={block.description}
                              link={block.link}
                            />
                          ) : (
                            <Step
                              key={"started" + index}
                              title={block.title}
                              description={block.description}
                              link={block.link}
                              number={index + 1}
                            />
                          )
                        )
                      : null}
                  </ul>
                </div>
              }
            />
          </Container>
          <Divider />
        </Container>
      </section>
      <section>
        <Container>
          <div className="allDone">
            <span>ALL DONE</span>
          </div>
          <div className="allDoneDescr">
            <p>You are signed up, registered and ready to go.</p>
          </div>
          <Divider />
        </Container>
      </section>
    </div>
  );
}

const parseBlocks = (blocks) => {
  if (!blocks.size) return [];
  return blocks
    .valueSeq()
    .toArray()
    .map((block) => ({
      title: block.get("title"),
      description: block.get("description"),
      link: {
        url: block.getIn(["link", "url"]),
        title: block.getIn(["link", "title"]),
        external: block.getIn(["link", "external"]),
      },
    }));
};

const mapStateToProps = (state) => ({
  blocks: parseBlocks(state.general.get("getting_started")),
});

export default connect(mapStateToProps, null)(GettingStarted);
