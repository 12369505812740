import React from "react";
import { SideBarButtons } from "../../layouts/layout1";
import Tag from "../../tag/tag";
import Title from "../../title/title";
import Field from "../../field/field";
import DropDownMenu from "../../dropDownMenu/dropDownMenu";
import FilterIcon from "../../svgs/icoMoon/icons/filter/filterIcon";
import SearchInput from "../../search/searchInput/searchInput";

type State = {
  toggle: boolean,
};

type Props = {
  searchForumsAndTopics: Function,
  forums: Array<Object>,
  forumCategory: Function,
};

class ForumSidebarFilters extends React.Component<Props, State> {
  state = {
    open: true,
  };

  toggleOpen = () => {
    this.setState({
      open: !this.state.open,
    });
  };

  render() {
    const { open } = this.state;
    const { searchForumsAndTopics, forums, forumCategory } = this.props;

    return (
      <div>
        <SideBarButtons classNameWrapper="filtersButton-wrapper">
          <div className="filtersButton">
            <button
              id="filter_btn"
              className="theFiltersBtn"
              onClick={this.toggleOpen}
            >
              <FilterIcon /> FILTER FORUMS
            </button>
          </div>
        </SideBarButtons>
        {open ? (
          <div id="filter_accordion">
            <Title type="small">
              <Tag className="display-effect">SELECT FORUM</Tag>
            </Title>
            {forums.length > 0 ? (
              <Field className="side">
                <DropDownMenu
                  items={forums.map((item) => ({
                    name: item.name,
                    value: item.uuid,
                  }))}
                  selectHandler={forumCategory}
                />
              </Field>
            ) : null}
            <Title type="small" displayEffect>
              <Tag>SEARCH</Tag>
            </Title>
            <Field className="search">
              <SearchInput
                onChange={searchForumsAndTopics}
                placeholder="keywords,etc"
              />
            </Field>
          </div>
        ) : null}
      </div>
    );
  }
}

export default ForumSidebarFilters;
