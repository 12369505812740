// @flow
import React, { Fragment } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import FaSpinner from "../components/faSpinner/faSpinner";
import ContactForm from "../components/contactForm/contactForm";
import { bemClassesFromModifiers } from "../constants/misc";
import {
  Sidebar,
  SidebarHeader,
  SidebarViewAll,
} from "../components/layouts/layout1";
import Title from "../components/title/title";
import Tag from "../components/tag/tag";
import SectionStart from "../components/sectionStart/sectionStart";
import withTracker from "./withtracker";
import AuthorizationHOC from "./AuthorizationHOC";
import { ALL_USERS } from "../constants/userGroups";
import withBreadCrumb from "./withBreadcrumb";
import StatusMessage from "../components/statusMessage/statusMessage";

type Props = {
  sendContactFormMessage: Function,
  clearContactStatusMessages: Function,
  clearResetForm: Function,
  loading: boolean,
  statusMessage: string,
  success: boolean,
  resetForm: boolean,
};

class ContactFormHOC extends React.Component<Props> {
  onSubmit = (form) => {
    this.props.sendContactFormMessage({
      message: form.get("message"),
      subject: form.get("subject"),
      mail: form.get("mail"),
    });
  };

  componentWillUnmount() {
    this.props.clearContactStatusMessages();
  }

  render() {
    const {
      loading,
      statusMessage,
      success,
      resetForm,
      clearResetForm,
    } = this.props;
    return (
      <SectionStart>
        <Sidebar
          sidebarLeft={
            <Fragment>
              <SidebarHeader>
                <Title type="small">
                  <Tag className="display-effect">CONTACT US</Tag>
                </Title>
                <Title type="descr">
                  <span>
                    If you have any question or you are willing to give us a
                    feedback, please do not hesitate to contact us
                  </span>
                </Title>
              </SidebarHeader>
              <SidebarViewAll />
            </Fragment>
          }
          sidebarRight={
            <Fragment>
              {!loading && statusMessage ? (
                <StatusMessage modifiers={success ? ["success"] : ["fail"]}>
                  {statusMessage}
                </StatusMessage>
              ) : (
                <FaSpinner loading={loading} />
              )}
              <ContactForm
                clearContactStatusMessages={
                  this.props.clearContactStatusMessages
                }
                success={success}
                resetForm={resetForm}
                clearResetForm={clearResetForm}
                sendMessage={this.onSubmit}
              />
            </Fragment>
          }
        />
      </SectionStart>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.contact.get("loading"),
  statusMessage: state.contact.getIn(["contact", "message"]),
  success: state.contact.getIn(["contact", "success"]),
  resetForm: state.contact.getIn(["contact", "resetForm"]),
});

const mapDispatchToProps = (dispatch) => ({
  sendContactFormMessage: (payload) => {
    dispatch(actions.sendContactFormMessage(payload));
  },
  clearContactStatusMessages: (payload) => {
    dispatch(actions.clearContactStatusMessages(payload));
  },
  clearResetForm: (payload) => {
    dispatch(actions.clearResetForm(payload));
  },
});

export default withTracker(
  AuthorizationHOC(ALL_USERS)(
    connect(mapStateToProps, mapDispatchToProps)(ContactFormHOC)
  )
);
