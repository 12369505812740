import React, { Component } from "react";
import { Sidebar, SidebarHeader } from "../layouts/layout1";
import Title from "../title/title";
import Heading from "../heading/heading";
import SectionStart from "../sectionStart/sectionStart";
import Analytics from "./analytics";
import { connect } from "react-redux";
import "react-minimal-datetime-range/lib/react-minimal-datetime-range.min.css";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { DateRangePicker } from "react-dates";
import moment from "moment";
import { checkIfAnalyticsAreEnabled } from "../../actions";
import { checkIfMediaQueryMatches } from "../../constants/misc";

class AnalyticsOverview extends Component<{}> {
  Sidebar = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      fromDate: new Date(
        new Date(new Date().setDate(new Date().getDate() + 1)).setMonth(
          new Date().getMonth() - 1
        )
      ),
      toDate: new Date(),
      focusedInput: null,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.props.checkIfAnalyticsAreEnabled({});
    }, 1);
  }

  falseFunc = () => false;

  render() {
    const {
      analyticsAreEnabled,
      checkingIfAnalyticsAreEnabled,
      applicationStatus,
    } = this.props;

    if (
      checkingIfAnalyticsAreEnabled ||
      !analyticsAreEnabled ||
      applicationStatus !== "PRODUCTION"
    ) {
      return null;
    }

    const { toDate, fromDate, focusedInput } = this.state;

    return (
      <SectionStart>
        <Sidebar
          sidebarLeft={
            <SidebarHeader style={{ marginBottom: 16 }}>
              <Title type="section">
                <Heading
                  style={{ marginBottom: "1rem" }}
                  level="2"
                  className="display-effect"
                >
                  {"Analytics"}
                </Heading>
              </Title>
              <Title type="small">Date range:</Title>
              <div className="tag-action">
                <div
                  className={"tag"}
                  style={{ padding: "14px 20px 14px 20px" }}
                >
                  <DateRangePicker
                    daySize={
                      checkIfMediaQueryMatches("(max-width: 768px)") ? 30 : 39
                    }
                    numberOfMonths={
                      checkIfMediaQueryMatches("(max-width: 768px)") ? 1 : 2
                    }
                    displayFormat={"DD-MM-YYYY"}
                    hideKeyboardShortcutsPanel={true}
                    isOutsideRange={this.falseFunc}
                    startDate={fromDate ? moment(fromDate) : null}
                    startDateId="from"
                    endDate={toDate ? moment(toDate) : null}
                    endDateId="to"
                    onDatesChange={({ startDate, endDate }) =>
                      this.setState({
                        fromDate: startDate ? startDate.toDate() : new Date(),
                        toDate: endDate ? endDate.toDate() : new Date(),
                      })
                    }
                    focusedInput={focusedInput}
                    onFocusChange={(focusedInput) =>
                      this.setState({ focusedInput })
                    }
                  />
                </div>
              </div>
            </SidebarHeader>
          }
        />
        <div>
          <Analytics
            applicationId={this.props.applicationId}
            fromDate={this.state.fromDate}
            toDate={this.state.toDate}
          />
        </div>
      </SectionStart>
    );
  }
}

const mapStateToProps = (state) => ({
  analyticsAreEnabled: state.analytics.get("analyticsAreEnabled"),
  checkingIfAnalyticsAreEnabled: state.analytics.get(
    "checkingIfAnalyticsAreEnabled"
  ),
});

const mapDispatchToProps = (dispatch) => ({
  checkIfAnalyticsAreEnabled: (payload) => {
    dispatch(checkIfAnalyticsAreEnabled(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AnalyticsOverview);
