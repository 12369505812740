// @flow
import React, {Fragment} from 'react';
import FeaturedProducts from "../featuredProducts/featuredProducts";
import RaceBanner from "../raceBanner/raceBanner";
import Steps from "../steps/steps";
import Container from "../container/container";
import News from "../news/news";

type Props = {
    featuredProducts: Array<Object>,
    steps: Array<Object>,
    isLoggedIn: boolean
};

const main = (props: Props) => {
    const {featuredProducts, steps,isLoggedIn} = props;
    return (
        <Fragment>
            <section className="nbg-hub-home-race-section">
                <Container>
                    <RaceBanner/>
                    {isLoggedIn && <FeaturedProducts featuredProducts={featuredProducts}/>}
                </Container>
            </section>
            {steps.length ?
                <Steps steps={steps}/>
                : null
            }
            {isLoggedIn && <News viewStyle="block"/>}
        </Fragment>
    );
};

export default main;
