// @flow
import React from "react";
import ForumTopicDisplay from "../forumTopicDisplay/forumTopicDisplay";
import ForumTopicCommentDisplay from "../forumTopicCommentDisplay/forumTopicCommentDisplay";
import ForumPagerHOC from "../../../containers/ForumPagerHOC";
import { FRONTEND_ORIGIN_URL_NO_SLASH } from "../../../config";
import SectionStart from "../../sectionStart/sectionStart";
import { Sidebar, SidebarHeader } from "../../layouts/layout1";
import Title from "../../title/title";
import Heading from "../../heading/heading";
import SearchInput from "../../search/searchInput/searchInput";
import BracketButton from "../../bracketButton/bracketButton";
import "./forumTopicId.css";
import FaSpinner from "../../faSpinner/faSpinner";
import MetaData from "../../meta/meta";
import { getRouteUrl } from "../../../routers";
import {
  ROUTE_FORUM_TOPIC_VIEW,
  ROUTE_FORUM_VIEW_SINGLE,
} from "../../../routers/content/forum";

type Props = {
  loading: boolean,
  topic: Object,
  comments: Array<Object>,
  changeSubscriptionStatus: Function,
  subscribed?: boolean,
  saveReplyComment: Function,
  forum_id: string,
  searchTopic: Function,
  searchCommentInTopicText: string,
  submit: boolean,
};

const renderCommentsTree = (comments: Array<Object>, props) => {
  // eslint-disable-line
  const renderCommentTree = (parent = null, depth = 0) => {
    const children = getChildren(parent, depth);
    return children.map((child) => {
      return (
        <div
          key={child.uuid}
          data-depth={depth}
          className="forum-topic--comment-answer"
        >
          <ForumTopicCommentDisplay
            {...props}
            {...child}
            isAnswer={depth > 0}
          />
          <hr className="form_divider" />
          <div>{renderCommentTree(child.uuid, depth + 1)}</div>
        </div>
      );
    });
  };

  const getChildren = (parent = null, depth = 0) => {
    return comments.filter(
      (comment) => comment.parent === parent || (depth === 0 && !comment.parent)
    );
  };

  const rootChildren = getChildren();

  if (comments && rootChildren.length === 0) {
    const parents = [...new Set(comments.map((item) => item.parent))];
    return parents.map((parent) => renderCommentTree(parent, 0));
  }

  return renderCommentTree();
};

// const renderCommentsColumn = (comments: Array<Object>, props) => {
//     return comments.map((comment) => <ForumTopicCommentDisplay key={comment.uuid}  {...props} {...comment} />);
// };

const ForumTopicId = (props: Props) => {
  const {
    topic,
    comments,
    forum_id,
    searchTopic,
    loading,
    submit,
    searchCommentInTopicText,
  } = props;
  console.log(submit);
  return (
    <div>
      <MetaData
        title={topic.title ? topic.title : "Forum Topic"}
        url={
          FRONTEND_ORIGIN_URL_NO_SLASH +
          getRouteUrl(ROUTE_FORUM_TOPIC_VIEW, {
            forum_id: topic.uuid,
          })
        }
        description="Search and participate in our developer forum. Ask questions as the experienced developers in the forum community might know the answers and are happy to help."
      />
      <SectionStart>
        <Sidebar
          sidebarLeft={
            <SidebarHeader>
              <Title type="section" displayEffect>
                <Heading
                  level="2"
                  className="folowed_by_subtitle display-effect"
                >
                  FORUMS
                </Heading>
              </Title>
              <div className="forum_back_to_topics">
                <BracketButton
                  title="BACK TO TOPICS"
                  url={getRouteUrl(ROUTE_FORUM_VIEW_SINGLE, {
                    forum_id: topic.forumParent,
                  })}
                  caretEnable
                  movingBrackets
                  className="left"
                />
              </div>
              <Title type="small" displayEffect>
                SEARCH
              </Title>
              <SearchInput
                onChange={searchTopic}
                placeholder="keywords, tags, etc"
              />
            </SidebarHeader>
          }
          sidebarRight={
            <div className="forum_wrapper">
              <FaSpinner loading={loading && !submit} />
              <div className="forum">
                {topic && topic.title !== undefined ? (
                  <ForumTopicDisplay {...props} {...topic} />
                ) : null}
                {comments && comments.length > 0 && (
                  <div className={"comments"}>
                    {renderCommentsTree(comments, props)}
                  </div>
                )}
                <ForumPagerHOC
                  searchCommentInTopicText={searchCommentInTopicText}
                  forum={forum_id}
                />
              </div>
            </div>
          }
        />
      </SectionStart>
    </div>
  );
};

export default ForumTopicId;
