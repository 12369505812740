import React, { useState } from "react";
import "./organizationContent.css";
import FaSpinner from "../../faSpinner/faSpinner";
import Heading from "../../heading/heading";
import { getRouteUrl } from "../../../routers";
import type { Organization } from "../../../constants/map/organization";
import type { Application } from "../../../constants/map/application";
import ApplicationListItem from "../../applicationListItem/applicationListItem";
import FileTable from "../../fileTable/fileTable";
import UploadDocumentsForm from "../uploadDocumentsForm/uploadDocumentsForm";
import UploadFileErrors from "../uploadFileErrors/uploadFileErrors";
import Button from "../../button/button";
import { ROUTE_APPLICATION_CREATE } from "../../../routers/content/application";
import SimpleButtonWrapper from "../../simpleButtonWrapper/simpleButtonWrapper";
import { Link } from "react-router-dom";
import OrganizationPermissionHOC from "../organizationPermissionHOC/OrganizationPermissionHOC";
import {
  ORGANIZATION_PERMISSION_CREATE_GROUP_NODE_APPLICATION_CONTENT,
  ORGANIZATION_PERMISSION_CREATE_GROUP_NODE_APPLICATION_ENTITY,
  ORGANIZATION_PERMISSION_EDIT_GROUP,
} from "../../../constants/organizationPermissions";

type Props = {
  organization: Organization,
  applications: Array<Application>,
  updateDocuments: Function,
  loading: any,
  loadingContent: any,
  updating: any,
};

const OrganizationContent = (props: Props) => {
  const {
    updateDocuments,
    organization: { uuid, documents },
    loading,
    updating,
    applications,
    loadingContent,
  } = props;
  const [files, setFiles] = useState(undefined);

  const CreateAppButton = OrganizationPermissionHOC(uuid, {
    allowedPermissions: [
      ORGANIZATION_PERMISSION_CREATE_GROUP_NODE_APPLICATION_CONTENT,
      ORGANIZATION_PERMISSION_CREATE_GROUP_NODE_APPLICATION_ENTITY,
    ],
  })(() => (
    <SimpleButtonWrapper position="right">
      <Link
        to={getRouteUrl(ROUTE_APPLICATION_CREATE, null, { organization: uuid })}
      >
        <Button className="add_application_button" electro>ADD AN APPLICATION</Button>
      </Link>
    </SimpleButtonWrapper>
  ));

  const UploadDDocuments = OrganizationPermissionHOC(uuid, {
    allowedPermissions: [ORGANIZATION_PERMISSION_EDIT_GROUP],
  })(() => (
    <UploadDocumentsForm
      files={files}
      loading={updating}
      onSubmit={(evt) => {
        setFiles(undefined);
        evt.preventDefault();
        if (files) {
          updateDocuments({
            uuid,
            documents: files,
            field_documents: documents.map((doc) => doc.uuid),
          });
        }
      }}
      onFileChange={(evt) => {
        if (evt.target.files && evt.target.files.length > 0) {
          setFiles(evt.target.files);
        }
      }}
    />
  ));

  return (
    <div>
      <div className="nbg-organization-wrapper">
        <div className="clearfix">
          <div className="clearfix">
            <Heading level={3} style={{ display: "inline-block" }}>
              Applications
            </Heading>
            <CreateAppButton />
          </div>
          <div>
            {loadingContent && <FaSpinner loading={loadingContent} />}
            {applications.map((application) => (
              <ApplicationListItem
                organizationUuid={uuid}
                application={application}
                key={application.uuid}
              />
            ))}
          </div>
          {/*{loading && <FaSpinner loading/>}*/}
        </div>
        <br />
        {documents && (
          <div>
            <Heading level={3}>
              Documents
              <UploadDDocuments />
            </Heading>
            <UploadFileErrors organizationId={uuid} />
            <FileTable
              rowProps={{
                onRemove: (docId) => {
                  setFiles(undefined);
                  updateDocuments({
                    uuid,
                    field_documents: documents
                      .filter((doc) => doc.uuid !== docId)
                      .map((doc) => doc.uuid),
                  });
                },
              }}
              files={documents}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default OrganizationContent;
