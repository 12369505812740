import React from "react";
import Checkbox from "../components/checkbox/checkbox";
import { connect } from "react-redux";
import { parsePopup } from "./CookieConsentHOC";
import Button from "../components/button/button";
import * as actions from "../actions";
import StatusMessage from "../components/statusMessage/statusMessage";
import { Sidebar, SidebarHeader } from "../components/layouts/layout1";
import Title from "../components/title/title";
import Heading from "../components/heading/heading";
import SimpleButtonWrapper from "../components/simpleButtonWrapper/simpleButtonWrapper";
import FooterPrivacy from "../components/footer/footerPrivacy";
import SectionStart from "../components/sectionStart/sectionStart";
import Table from "../components/table/table";
import withBreadCrumb from "./withBreadcrumb";

type State = {};

type Props = {
  cookieConsentAction: Function,
  cookiesEnabled: Object,
  popup: Object,
  labelCheckBox: string,
};

const COOKIES_TABLE_HEAD = [
  {
    title: "Name of Cookie",
  },
  {
    title: "Provider of Cookie",
  },
];

class CookieSettingsHOC extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { cookiesEnabled } = this.props;
    this.state = {
      analytics: cookiesEnabled.analytics,
      message: null,
    };
  }

  handleSave = () => {
    this.setState({
      message: true,
    });
    setTimeout(this.clearMessage, 2000);
    this.props.cookieConsentAction(this.state);
  };

  changeAnalytics = () => {
    this.setState({ analytics: !this.state.analytics });
  };

  clearMessage = () => {
    this.setState({
      message: null,
    });
  };

  render() {
    const { cookiesEnabled, labelCheckBox } = this.props;
    const { analytics, message } = this.state;
    const popup = parsePopup(this.props.popup);
    return (
      <SectionStart>
        <Sidebar
          sidebarLeft={
            <div>
              <SidebarHeader>
                <Title type="section">
                  <Heading level="1" className="display-effect">
                    Cookie Settings
                  </Heading>
                </Title>
              </SidebarHeader>
            </div>
          }
          sidebarRight={
            <div>
              <p>
                Cookies are text files containing small amounts of information
                which are downloaded to your device when you visit a website.
                The website you are visiting sends the file to your internet
                browser. The text file is then installed on your PC, laptop,
                tablet or mobile phone (depending on which of these devices you
                are using to visit the website). This website uses cookies
                grouped in two categories: technical cookies and analysis
                cookies. Please see bellow a description of each cookie category
                with an option to accept/reject the corresponding category
                (where applicable).
              </p>

              <div style={{ marginTop: "2rem" }}>
                <Heading level="3" className="display-effect">
                  Technical cookies
                </Heading>
                <p>
                  The only technical cookie used in this website is a session
                  cookie used only when the user is logged in. This cookie
                  allows users to access restricted sections of the website,
                  thus it is mandatory for signed-in users for security reasons.
                </p>

                <Table header={COOKIES_TABLE_HEAD} cellsNumber={2} hideFooter>
                  <li className="row support_accord_title">
                    <div className="cell">starts with SESS*</div>
                    <div className="cell">NBG</div>
                  </li>
                </Table>
                <br />
                <Checkbox
                  style={{ marginTop: "1rem" }}
                  label="Accept"
                  checked
                  disabled
                />
              </div>
              <div style={{ marginTop: "2rem" }}>
                <Heading level="3" className="display-effect">
                  Analysis cookies
                </Heading>
                <p>
                  This website uses analytics cookies provided by Google Inc.
                  These cookies allow to monitor and analyze the behavior of the
                  website users, measure the website, platform, or application
                  activity, in order to improve the website based on the
                  analysis of user data. Analysis cookies are anonymous;
                  therefore, it is not possible to identify the users who
                  navigate the site from different browsers and devices. If a
                  user deactivates the analysis cookies, every event in Google
                  Analytics is blocked and therefore no information will be
                  collected regarding any further actions of the user on the
                  website.
                </p>
                <Table header={COOKIES_TABLE_HEAD} cellsNumber={2} hideFooter>
                  <li className="row support_accord_title">
                    <div className="cell">_ga, _gat, _gid</div>
                    <div className="cell">Google Analytics</div>
                  </li>
                </Table>
                <br />
                {cookiesEnabled !== undefined && (
                  <Checkbox
                    style={{ marginTop: "1rem" }}
                    label={labelCheckBox}
                    checked={analytics}
                    onChange={this.changeAnalytics}
                  />
                )}
              </div>

              {popup && (
                <div style={{ marginTop: 24 }}>
                  <SimpleButtonWrapper position="left">
                    <Button onClick={this.handleSave}>Save</Button>
                  </SimpleButtonWrapper>
                  <div className="afterfooter" style={{ textAlign: "right" }}>
                    <FooterPrivacy />
                  </div>
                </div>
              )}

              {message && (
                <StatusMessage modifiers={[StatusMessage.SUCCESS]}>
                  Updated cookie preferences successfully.
                </StatusMessage>
              )}
            </div>
          }
        />
      </SectionStart>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.cookies.get("loading"),
  popup: state.cookies.get("popup"),
  expiryDays: state.cookies.get("expiryDays"),
  cookiesConsentAction: state.cookies.get("cookiesConsentAction"),
  cookiesEnabled: state.cookies.get("cookiesEnabled").toJS(),
  labelCheckBox: state.cookies.get("labelCheckBox"),
});

const mapDispatchToProps = (dispatch) => ({
  fetchCookieConsent: () => {
    dispatch(actions.fetchCookieConsent());
  },
  cookieConsentAction: (payload) => {
    dispatch(actions.cookieConsentAction(payload));
  },
});

export default withBreadCrumb(
  connect(mapStateToProps, mapDispatchToProps)(CookieSettingsHOC),
  {
    name: "Cookies",
  }
);
