import "./popover.css";
import React from "react";
import { bemClassesFromModifiers, isOutOfViewport } from "../../constants/misc";

type Props = {
  children?: any,
  message: any,
  direction?: string,
  className?: string,
  style?: Object,
  autohide?: boolean,
  delay?: boolean,
  milliseconds?: number,
  redoc?: boolean,
};

type State = {
  boxWidth: number,
  direction: string,
};

const directions = ["left", "right", "top", "bottom"];

class Popover extends React.Component<Props, State> {
  state = {
    direction: "right",
  };

  onClose = () => {
    this.PopoverMessage.style.display = "none";
  };

  onMouseOver = () => {
    const { out } = isOutOfViewport(this.PopoverMessage);
    const { timeOut } = this.state;
    if (timeOut) {
      clearTimeout(timeOut);
    }
    this.PopoverMessage.style.display = "block";
    if (out.any) {
      const posibleValues = directions.filter(
        (direction) =>
          out[direction] === false && direction !== this.state.direction
      );
      const newDirection =
        posibleValues[Math.floor(Math.random() * posibleValues.length)];
      this.setState({
        direction: newDirection,
      });
    }
  };

  onDelayEnabled = () => {
    const { milliseconds } = this.props;
    const timeOut = setTimeout(this.onMouseOver, milliseconds);
    this.setState({
      timeOut,
    });
  };

  componentDidMount() {
    const { direction } = this.props;
    this.setState({
      direction: direction ? direction : "right",
    });
  }

  onAutohide = () => {
    const { autohide } = this.props;
    if (autohide) {
      this.PopoverMessage.style.display = "none";
    }
  };

  onMouseOut = () => {
    const { timeOut } = this.state;
    if (timeOut) {
      clearTimeout(timeOut);
    }
  };

  render() {
    const { children, message, style, autohide, delay, redoc } = this.props;
    const { direction } = this.state;

    let className = bemClassesFromModifiers("popover", [
      direction ? direction : null,
    ]);

    if (this.props.className) {
      className = className + " " + this.props.className;
    }

    return (
      <div
        style={style}
        onMouseOver={delay ? this.onDelayEnabled : this.onMouseOver}
        onMouseLeave={this.onAutohide}
        onMouseOut={this.onMouseOut}
        className={className}
      >
        <div
          className={redoc ? "popover__message-redoc" : "popover__message"}
          ref={(section) => {
            this.PopoverMessage = section;
          }}
        >
          {!autohide ? (
            <span onClick={this.onClose} className="popover__close">
              &times;
            </span>
          ) : null}
          {message}
        </div>
        <div className="popover__content">{children}</div>
      </div>
    );
  }
}

export default Popover;
