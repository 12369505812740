// @flow
import React, { Fragment } from "react";
import "./featuredProducts.css";
import { renderBlocks } from "../../constants/misc";
import Container from "../container/container";
import { Sidebar, SidebarHeader, SidebarViewAll } from "../layouts/layout1";
import Title from "../title/title";
import Heading from "../heading/heading";
import Field from "../field/field";
import Tag from "../tag/tag";
import BracketButton from "../bracketButton/bracketButton";
import ProductPreview from "../productPreview/productPreview";
import { getRouteUrl } from "../../routers";
import { ROUTE_PRODUCT_VIEW_ALL } from "../../routers/content/product";
import { BASE_URL } from "../../config";
import Divider from "../divider/divider";

type Props = {
  featuredProducts: Array<Object>,
};

const FeaturedProducts = (props: Props) => {
  const { featuredProducts } = props;
  return (
    <Fragment>
      <Container inner>
        <Sidebar
          sidebarLeft={
            <Fragment>
              <SidebarHeader>
                <Title type="sub">
                  <Tag>FEATURED</Tag>
                </Title>
                <Title type="section">
                  <Heading
                    level="2"
                    className="folowed_by_subtitle display-effect"
                  >
                    APIs
                  </Heading>
                </Title>
                <Field className="search" />
              </SidebarHeader>
              <SidebarViewAll
                viewAll={
                  <BracketButton
                    caretEnable
                    title="VIEW ALL APIs"
                    movingBrackets
                    className="left"
                    url={getRouteUrl(ROUTE_PRODUCT_VIEW_ALL)}
                    id="custom_mobile_position_link"
                  />
                }
              />
            </Fragment>
          }
          sidebarRight={
            <div className="grid-container">
              {featuredProducts && featuredProducts.length
                ? featuredProducts.map((featuredProduct) => (
                    <ProductPreview
                      key={featuredProduct.productUuid}
                      square
                      title={featuredProduct.productTitle}
                      body={featuredProduct.body}
                      image={{
                        url: BASE_URL + featuredProduct.imageUrl,
                      }}
                      uuid={featuredProduct.productUuid}
                      alias={featuredProduct.alias}
                      disable_documentation={
                        featuredProduct.disable_documentation
                      }
                      label={featuredProduct.label}
                    />
                  ))
                : null}
            </div>
          }
        />
      </Container>
      <Divider />
    </Fragment>
  );
};

export default FeaturedProducts;
