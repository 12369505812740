// @flow
import React, { useEffect, useState, Fragment } from "react";
import "./forums.css";
import ForumFolder from "./forumFolder/forumFolder";
import FaSpinner from "../faSpinner/faSpinner";
import ForumBreadcrumb from "./forumBreadcrumb/forumBreadcrumb";
import _ from "lodash";
import { invalidDate } from "../../constants/misc";

type Props = {
  forums: Array<Object>,
  topics: {
    size: number,
  },
  loading: boolean,
  root: string,
  isLoggedIn: boolean,
};

const ForumsList = (props: Props) => {
  const {
    forums,
    topics,
    loading,
    root,
    isLoggedIn,
    countVisibleForums,
  } = props;
  const [parentForums, setParentForums] = useState(forums);
  const [parentRoot, setParentRoot] = useState(forums);

  useEffect(() => {
    setParentForums(forums);

    let parentForums = forums,
      parentRootObj = root;
    const rootObj = forums.find(obj => obj.uuid === root);

    if (root !== "virtual" && rootObj) { //virtual root doesn't exist in forums array, this means children in recursive func are top root elements
      parentForums = forums.filter(obj => obj.parent !== rootObj.parent || obj.uuid === rootObj.uuid);//remove
                                                                                                      // siblings
      parentRootObj = rootObj.parent;
      setParentRoot(parentRootObj)
      setParentForums(parentForums)
    } else {
      setParentForums(forums);
      setParentRoot(parentRootObj)
    }

  }, [forums, topics]);

  const setOutdatedRow = (rows) => {
    return rows.map((r) => ({
      ...r,
      last_post:
        r.last_post.length === 0 ? { created: invalidDate } : r.last_post,
    }));
  };

  return (
    <Fragment>
      <ForumBreadcrumb root={root} />
      {forums !== undefined && forums.length && !loading ? (
        _.sortBy(
          parentForums.filter((r) => r.parent === parentRoot),
          ["weight"]
        ).map((p, index) => {
          //Array recursion: Rows are the elements of the forums list which are inside the children property of the list
          const rows =
            p.children.length > 0 &&
            p.children.flatMap((c) =>
              parentForums.filter((parent) => parent.uuid === c)
            );

          return (
            <ForumFolder
              key={p.uuid}
              forum_container={p.forum_container}
              loading={loading}
              title={p.name}
              index={index + 1}
              topics={topics}
              root={root}
              description={p.description}
              rows={rows.length > 0 && setOutdatedRow(rows)}
              isLoggedIn={isLoggedIn}
            />
          );
        })
      ) : (
        <FaSpinner loading={loading} />
      )}
    </Fragment>
  );
};

export default ForumsList;
