import React from "react";
import Title from "../title/title";
import Tag from "../tag/tag";
import Field from "../field/field";
import DropDownMenu from "../dropDownMenu/dropDownMenu";
import { useQuery } from "react-query";
import { QUERRYIDS } from "../../constants/queryIds";
import { fetchApiProductsCategory } from "../../Apis/apiProducts";
import FaSpinner from "../faSpinner/faSpinner";

type Props = {
  selectApiProductCategory: Function
}
const ProductCategoriesFilter = ({selectApiProductCategory}: Props) => {
  const categories = useQuery(QUERRYIDS.fetchProductCategories, () =>
    fetchApiProductsCategory()
  );

  const categoriesParser = (payload) => {
    const { data } = payload;
    const categories = [];
    data.forEach((datum) => {
      const { name } = datum.attributes;
      categories.push({ name, uuid: datum.id });
    });
    return categories;
  };

  if (categories.isLoading) {
    return <FaSpinner loading />;
  }

  return (
    <>
      <Title type="small">
        <Tag>FILTER APIs</Tag>
      </Title>

      <Field className="side">
        <DropDownMenu
          items={categoriesParser(categories.data).map((item) => ({
            name: item.name,
            value: item.uuid,
          }))}
          name="ALL CATEGORIES"
          selectHandler={selectApiProductCategory}
        />
      </Field>
    </>
  );
};

export default ProductCategoriesFilter;

