import { parseImage } from "../constants/parse/image";

// Basic Page url handler for path reconstruction
export const newsPageRouteConverter = (
  url: string,
  original: string,
  converted: string
): string => {
  const reconstructedUrl = url.replace(original, converted);
  return reconstructedUrl;
};

export const parseNodes = (nodes: Object) => {
  return nodes
    .valueSeq()
    .toArray()
    .filter((node) => node.getIn(["attributes", "path"]) !== null)
    .map((node) => ({
      title: node.get("title"),
      created: node.get("created"),
      description: node.get("description"),
      url: node.get("url"),
      image: parseImage(node.get("image")),
      category: node.get("category"),
      author: {
        name: node.getIn(["author", "name"]),
        image: parseImage(node.getIn(["author", "image"])),
        first_name: node.getIn(["author", "first_name"]),
        last_name: node.getIn(["author", "last_name"]),
      },
    }));
};

export const parseBasicPage = (page) => {
  if (!page) return {};
  const { title, path, field_author, field_image, created } = page;
  return {
    // $FlowFixMe
    title: title && title[0] ? title[0].value : null,
    alias: path && path[0] ? path[0].alias : null,
    image: field_image && field_image[0] ? field_image[0] : null,
    author: field_author,
    created: created,

    // $FlowFixMe
    html: page.body && page.body[0] ? page.body[0].processed : null,
    // $FlowFixMe
    driveId:
      page.field_google_drive_id && page.field_google_drive_id[0]
        ? page.field_google_drive_id[0].value
        : null,
    // $FlowFixMe
    fieldScrollspyVisible:
      page.field_scrollspy_visible && page.field_scrollspy_visible[0]
        ? page.field_scrollspy_visible[0].value
        : null,
  };
};
