import React, { Fragment } from "react";
import ArrowDownIcon from "../../svgs/icoMoon/icons/arrowDown/arrowDown";
import * as actions from "../../../authentication/actions";
import { connect } from "react-redux";
import "./accountMenu.css";
import Button from "../../button/button";
import AccountMenuLinksAuthenticated from "./accountMenuLinksAuthenticated";
import AccountMenuLinksAnonymous from "./accountMenuLinksAnonymous";
import $ from "jquery";
import { COOKIES_ICON } from "../../images";
import { Link } from "react-router-dom";

type Props = {
  logout: Function,
  setManuallLogout: Function,
  className: string,
  loggedIn: boolean,
  mobile: boolean,
  userName: string,
};

type State = {
  open: false,
};

class AccountMenu extends React.Component<Props, State> {
  state = {
    open: false,
  };

  constructor(props) {
    super(props);

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (
      this.wrapperRef &&
      !this.wrapperRef.contains(event.target) &&
      this.state.open
    ) {
      this.dropMenuDown();
    }
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  dropMenuDown = () => {
    $(".usr_drop").slideToggle().focus();
    this.setState({
      open: !this.state.open,
    });
  };

  logout = () => {
    this.props.logout();
    this.props.setManuallLogout(true);
  };

  render() {
    const { loggedIn, userName, className, mobile } = this.props;

    return !mobile ? (
      <div className={className}>
        <Link to="/cookie-settings" className="cookie-settings">
          <img src={COOKIES_ICON} alt="cookie settings" />
          <span>Cookie Settings</span>
        </Link>
        {!(loggedIn && userName) ? (
          <div id="not_loged" className="usr">
            <AccountMenuLinksAnonymous />
          </div>
        ) : (
          <div id="loged" className="usr" ref={this.setWrapperRef}>
            <div className="loged">
              <span className="blueTxt">Welcome </span>
              <Button
                id="account_settings"
                className="usr_arrow"
                onClick={this.dropMenuDown}
              >
                <span className="usr_name">{userName}</span>
                <ArrowDownIcon />
              </Button>
            </div>
          </div>
        )}
        {loggedIn && (
          <div className="usr_drop" style={{ display: "none" }}>
            <AccountMenuLinksAuthenticated
              mobile={mobile}
              logout={this.logout}
            />
          </div>
        )}
      </div>
    ) : (
      <Fragment>
        {loggedIn && userName && (
          <li>
            <div className="mobile-welcome">
              <span>Welcome</span> {userName}
            </div>
          </li>
        )}
        <li className="mobile-signup">
          {loggedIn && userName ? (
            <AccountMenuLinksAuthenticated
              mobile={mobile}
              logout={this.logout}
            />
          ) : (
            <AccountMenuLinksAnonymous mobile={mobile} />
          )}
        </li>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedIn: state.applicationUser.get("isLoggedIn"),
  userName: state.applicationUser.get("name"),
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => {
    dispatch(actions.logout());
  },
  setManuallLogout: (payload) => {
    dispatch(actions.setManuallLogout(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountMenu);
