// @flow
import React from "react";
import "./searchInput.css";
import Field from "../../field/field";
import SearchIcon from "../../svgs/icoMoon/icons/searchIconNew/searchIconNew";

type Props = {
  onChange: Function,
  placeholder?: string,
  query?: string,
  onClick: Function,
  disabled?: boolean,
  autoFocus: boolean,
  disabled: boolean,
};

const SearchInput = (props: Props) => {
  const { onChange, placeholder, onClick, autoFocus, query, disabled } = props;
  return (
    <Field className="search">
      <div className="inputWithIcon">
        <input
          className="body_field"
          type="text"
          onChange={onChange}
          placeholder={placeholder ? placeholder : "keywords,etc"}
          onClick={onClick}
          autoFocus={autoFocus}
          value={query}
          disabled={disabled}
        />
        <SearchIcon />
      </div>
    </Field>
  );
};

export default SearchInput;
