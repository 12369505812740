// @flow
import React from "react";
import { connect } from "react-redux";
import withTracker from "./withtracker";
import AuthorizationHOC from "./AuthorizationHOC";
import { REGISTERED_USERS } from "../constants/userGroups";
import OrganizationOverview from "../components/organization/organizationOverview/organizationOverview";
import * as actions from "../actions";
import withBreadCrumb from "./withBreadcrumb";
import Breadcrumb from "../components/breadcrumb/breadcrumb";
import { parseOrganization } from "../constants/parse/organization";
import MetaData from "../components/meta/meta";
import { getRouteUrl } from "../routers";
import { ROUTE_ORGANIZATION_VIEW_SINGLE } from "../routers/content/organization";
import { useOrganizationMemberships } from "../components/organization/organizationPermissionHOC/useOrganizationMemberships";

type Props = {
  match: {
    params: {
      organizationId: string,
    },
  },
  organizations: Object,
  loading: boolean,
  breadcrumb: Object,
  match: Object,
  fetchOrganizationMemberships: Function,
  fetchOrganizations: Function,
  deleteOrganization: Function,
};

class OrganizationHOC extends React.Component<Props> {
  componentDidMount() {
    const { organizations, match } = this.props;
    const { organizationId } = match.params;

    if (!organizations.hasIn([organizationId])) {
      this.props.fetchOrganizations({
        uuid: organizationId,
      });
    }
  }

  render() {
    const {
      deleteOrganization,
      organizations,
      loading,
      breadcrumb,
      match,
    } = this.props;
    const { organizationId } = match.params;
    const organizationData = parseOrganization(
      organizations.get(organizationId)
    );
    useOrganizationMemberships(organizationId);
    return (
      <div>
        {organizationData && (
          <div>
            <MetaData
              title="Organization"
              url={getRouteUrl(ROUTE_ORGANIZATION_VIEW_SINGLE, {
                organizationId: organizationData.uuid,
              })}
            />
            <Breadcrumb
              {...breadcrumb}
              params={{
                org: {
                  name: organizationData.title,
                },
              }}
            />
            <OrganizationOverview
              loading={loading}
              organization={organizationData}
              organizations={organizations}
              deleteOrganization={deleteOrganization}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  organizations: state.organizationReducer.get("byId"),
  loading: state.organizationReducer.get("loadingOrganizationContent"),
});

const mapDispatchToProps = (dispatch) => ({
  fetchOrganizations: (payload) => {
    dispatch(actions.fetchOrganizations(payload));
  },
  deleteOrganization: (payload) => {
    dispatch(actions.deleteOrganization(payload));
  },
});

export default withTracker(
  AuthorizationHOC(REGISTERED_USERS)(
    withBreadCrumb(
      connect(mapStateToProps, mapDispatchToProps)(OrganizationHOC),
      {
        name: "{org}",
        parents: ["Organizations"],
      }
    )
  )
);
