import React from "react";
import "./navBar.css";
import LogoNew from "../logoNew/logoNew";
import * as actions from "../../actions";
import { connect } from "react-redux";
import NavBarMobile from "./navBarMobile/navBarMobile";
import NavBarBurger from "./navBarBurger/navBarBurger";
import NavBarNavigationLinks from "./navBarNavigationLinks/navBarNavigationLinks";
import NavBarSearch from "./navBarSearch/navBarSearch";
import { Link } from "react-router-dom";
import BreadcrumbDisplay from "../breadcrumbDisplay/breadcrumbDisplay";
import Divider from "../divider/divider";

type Props = {
  fetchSearchResults: Function,
  loggedIn: boolean,
  userName: string,
  location: string,
};

class NavBar extends React.Component<Props> {
  state = {
    active: false,
  };

  toggleActive = () => {
    this.setState({ active: !this.state.active });
  };

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    const { location } = this.props;
    if (nextProps.location !== location) {
      this.setState({ active: false });
    }
  }

  render() {
    const { active } = this.state;
    return (
      <div className="container menu">
        <Divider className="logo_dividerLeft" />
        <Divider className="logo_dividerRight" />
        <div className="main_menu">
          <Link to="/">
            <LogoNew />
          </Link>
          <nav>
            <NavBarBurger open={active} onChange={this.toggleActive} />
            <div className="nav-container">
              <NavBarNavigationLinks {...this.props} type="desktop">
              </NavBarNavigationLinks>
              <LogoNew className="mobile" />
              <NavBarMobile toggleMenu={this.toggleActive} {...this.props} />
            </div>
          </nav>
        </div>
        <Divider className="logo_dividerLeft" />
        <Divider className="logo_dividerRight" />
        <BreadcrumbDisplay />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loggedIn: state.applicationUser.get("isLoggedIn"),
    userName: state.applicationUser.get("name"),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSearchResults: (payload) => {
      dispatch(actions.fetchSearchResults(payload));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
