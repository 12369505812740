// @flow
import React from "react";
import FaSpinner from "../../faSpinner/faSpinner";
import { Field, reduxForm } from "redux-form/immutable";
import SubmitButton from "../../submitButton/submitButton";
import { connect } from "react-redux";
import renderTextInput from "../../formHelpers/renderTextInput";
import "./addCommentForm.css";
import SectionStart from "../../sectionStart/sectionStart";
import { Sidebar, SidebarHeader } from "../../layouts/layout1";
import Title from "../../title/title";
import Heading from "../../heading/heading";
import {
  requiredEditor,
  required,
} from "../../formHelpers/validationFunctions";
import RenderWysiwygForm from "../../formHelpers/renderWysiwygForm";

type Props = {
  loading: boolean,
  handleEditorChange: Function,
  handleInputChange: Function,
  handleSubmit: Function,
  saveNewComment: Function,
  form: Object,
  submitting: boolean,
};

let AddCommentForm = (props: Props) => {
  const {
    form,
    loading,
    handleEditorChange,
    handleInputChange,
    saveNewComment,
    handleSubmit,
    submitting,
  } = props;
  return (
    <SectionStart>
      <Sidebar
        sidebarLeft={
          <SidebarHeader>
            <Title type="small">
              <Heading level="2">Post a new comment</Heading>
            </Title>
          </SidebarHeader>
        }
        sidebarRight={
          <form
            id="comment_add_form"
            className="messages-history__form"
            onSubmit={handleSubmit(saveNewComment)}
          >
            <Field
              className="body_field"
              labelClass="block__form_element_label"
              name="subject"
              placeholder="Subject"
              type="text"
              onChange={handleInputChange}
              validate={[required]}
              component={renderTextInput}
            />

            <Field
              name="body"
              submitting={submitting}
              placeholder="Enter a new comment"
              component={RenderWysiwygForm}
              validate={[requiredEditor]}
              onChange={handleEditorChange}
            />
            <Field
              name="parent"
              type="hidden"
              component={renderTextInput}
              onChange={handleInputChange}
            />
            <hr className="form_divider" />
            <div
              style={{
                textAlign: "center",
                marginTop: 20,
              }}
            >
              <FaSpinner loading={loading} />
              <SubmitButton
                disabled={submitting}
                title="POST COMMENT"
                classname="btn"
                position="right"
              />
            </div>
          </form>
        }
      />
    </SectionStart>
  );
};

// $FlowFixMe
AddCommentForm = reduxForm({
  form: "forumTopicId",
})(AddCommentForm);

AddCommentForm = connect((state) => ({
  initialValues: {
    subject: state.forum.getIn(["form", "subject"]),
    parent: state.forum.getIn(["form", "parent"]),
  },
  enableReinitialize: true,
}))(AddCommentForm);

export default AddCommentForm;
