//FETCH_OAUTH_PARAMETERS
export const FETCH_OAUTH_PARAMETERS = "FETCH_OAUTH_PARAMETERS";
export const FETCH_OAUTH_PARAMETERS_SUCCESS = "FETCH_OAUTH_PARAMETERS_SUCCESS";
export const FETCH_OAUTH_PARAMETERS_FAILED = "FETCH_OAUTH_PARAMETERS_FAILED";

//FETCH_TOKEN
export const FETCH_TOKEN = "FETCH_TOKEN";
export const FETCH_TOKEN_SUCCESS = "FETCH_TOKEN_SUCCESS";
export const FETCH_TOKEN_FAIL = "FETCH_TOKEN_FAIL";

// RETURN URL
export const STORE_RETURN_URL = "STORE_RETURN_URL";
export const FETCH_USER_DETAILS = "FETCH_USER_DETAILS";

// LOGIN STATUS
export const FETCH_LOGIN_STATUS = "FETCH_LOGIN_STATUS";
export const FETCH_LOGIN_STATUS_SUCCESS = "FETCH_LOGIN_STATUS_SUCCESS";
export const FETCH_LOGIN_STATUS_FAILED = "FETCH_LOGIN_STATUS_FAILED";

// USER DETAILS
export const STORE_USER_DETAILS = "STORE_USER_DETAILS";
export const FETCH_USER_DETAILS_FAILED = "FETCH_USER_DETAILS_FAILED";

// LOGIN
export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";

//LOGOUT
export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAIL = "LOGOUT_FAIL";
export const SET_MANUAL_LOGOUT = "SET_MANUAL_LOGOUT";


// USER COMPLETED STORIES
export const UPDATE_COMPLETED_STORIES = "UPDATE_COMPLETED_STORIES"
export const UPDATE_COMPLETED_STORIES_SUCCESS = "UPDATE_COMPLETED_STORIES_SUCCESS"

