import { fromJS } from "immutable";
import * as ActionTypes from "../actions/types";

const INITIAL_STATE = {
  byId: {},
  ids: [],
  searchFaq: null,
};

function storeFaqs(state, action) {
  const { data } = action.payload;
  const faqs = {};
  const faqsIds = [];
  data.forEach((faq) => {
    const { field_question, field_answer } = faq.attributes;
    const uuid = faq.id;
    const { field_category } = faq.relationships;
    const categoryId =
      field_category && field_category.data && field_category.data[0]
        ? field_category.data[0].id
        : null;
    faqs[uuid] = {
      uuid,
      question: field_question.processed,
      answer: field_answer.processed,
      categoryId,
    };
    faqsIds.push(uuid);
  });

  return state.set("byId", fromJS(faqs)).set("ids", fromJS(faqsIds));
}

function fetchFaqsFailed(state) {
  return state;
}

function fetchFAQS(state, action) {
  if (!action.payload) return state;
  const { searchFaq } = action.payload;
  return state.set("searchFaq", searchFaq);
}

export const faqs = {
  initialState: INITIAL_STATE,
  handlers: {
    [ActionTypes.STORE_FAQS]: storeFaqs,
    [ActionTypes.FETCH_FAQS_FAILED]: fetchFaqsFailed,
    [ActionTypes.FETCH_FAQS]: fetchFAQS,
  },
};
