// @flow
import React from "react";
import "./layouts.css";
import classNames from "classnames";

type Props = {
  sidebarLeft?: any,
  sidebarRight?: any,
  sidebar?: any,
  content?: any,
  reversed?: any,
};

type PropsHeader = {
  children: any,
  style?: Object,
};

type PropsViewAll = {
  viewAll?: any,
  ViewAllClassName?: string,
};

type PropsButtons = {
  classNameWrapper: string,
  children: any,
};

const SidebarLayout = (props: Props) => {
  const { sidebar, content, reversed } = props;
  return (
    <div>
      <div className="clearfix layout layout--sidebar-left">
        {reversed ? (
          <div>
            <div className="layout__sidebar">{sidebar}</div>
            <div style={{ marginTop: 10 }} className="layout__content">
              {content}
            </div>
          </div>
        ) : (
          <div>
            <div className="layout__content">{content}</div>
            <div className="layout__sidebar">{sidebar}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SidebarLayout;

export const Sidebar = (props: Props) => {
  const { className, sidebarRight, sidebarLeft, style } = props;
  return (
    <div className={className} style={style}>
      <div className="sidebar left">{sidebarLeft}</div>
      {sidebarRight && <div className="sidebar right">{sidebarRight}</div>}
    </div>
  );
};

export const SidebarHeader = (props: PropsHeader) => {
  const { children, style } = props;
  return (
    <div className="area_of titles" style={style}>
      {children}
    </div>
  );
};

export const SidebarViewAll = (props: PropsViewAll) => {
  const { ViewAllClassName, viewAll } = props;
  return (
    <div className={classNames("area_of view_all", ViewAllClassName)}>
      {viewAll}
    </div>
  );
};

export const SideBarButtons = (props: PropsButtons) => {
  const { children, classNameWrapper } = props;
  return <div className={classNameWrapper}>{children}</div>;
};
