import FileIcon from "../components/svgs/fileIcon";
import { BASE_URL } from "../config";
import Tag from "../components/tag/tag";
import LightBulb from "../components/svgs/lightbulb";
import ViewIcon from "../components/svgs/viewIcon";
import NewsIcon from "../components/svgs/newsIcon";
import React from "react";
import { dateFromTimestamp } from "../constants/misc";
import { parseApiProducts } from "../constants/parse/product";

export const highlightedString = (text: string, query: string) => {
  if (!query) {
    return text;
  }
  let newTitle = text;
  if (query.length > 2) {
    const matches = text.match(
      new RegExp(query.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&"), "ig")
    );
    let start = 0;
    if (matches) {
      matches.map((match) => {
        start = newTitle.indexOf(
          match,
          start === 0
            ? start
            : start +
                '<span class="text--highlighted">'.length +
                match.length +
                "</span>".length
        );
        const length = match.length;
        newTitle =
          newTitle.slice(0, start) +
          '<span class="text--highlighted">' +
          match +
          "</span>" +
          newTitle.slice(start + length);
        return newTitle;
      });
    }
  }
  return newTitle;
};

export const parseResults = (query, results, apiProducts) => {
  if (results === undefined || results === null || !results.size) return [];
  return results
    .valueSeq()
    .toArray()
    .map((result) => {
      const item = {
        uuid: result.get("uuid"),
        title: result.get("title")
          ? highlightedString(result.get("title"), query)
          : null,
        link: result.get("link"),
        type: result.get("type"),
        description: result.get("description"),
        imageUrl: result.get("image"),
        deprecated: result.get("deprecated") ? result.get("deprecated") : null,
        created: dateFromTimestamp(result.get("created")),
      };

      const link = result.get("link");
      const linkNode = link && link.indexOf("node");
      const hasAlias = parseApiProducts(apiProducts)
        .map((api) => api.alias)
        .includes(link);

      switch (item.type) {
        case "api_endpoint":
          return {
            ...item,
            icon: <FileIcon />,
            link: linkNode === -1 ? link : "/documentation/" + item.uuid,
          };
        case "api_product":
          return {
            ...item,
            link: hasAlias ? link : "/apiProduct/" + item.uuid,
            imageUrl: BASE_URL + item.imageUrl,
            tag: item.deprecated ? (
              <Tag modifiers={["NEW"]}>deprecated</Tag>
            ) : null,
          };
        case "page":
          return {
            ...item,
            icon: <LightBulb />,
          };
        case "forum":
          return {
            ...item,
            icon: <ViewIcon />,
            link: "/forum-topic/" + item.uuid,
          };
        case "article":
          return {
            ...item,
            icon: <NewsIcon />,
          };
        default:
          return item;
      }
    })
    .filter((item) => item.link);
};
