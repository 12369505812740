import { fromJS } from "immutable";
import * as ActionTypes from "../actions/types";

const INITIAL_STATE = {
  byId: {},
  ids: [],
};

function storeFaqs(state, action) {
  const { included } = action.payload;
  const faqsCategoriesIds = [];
  const faqCategories = {};

  included &&
    included.length > 0 &&
    included
      .filter((item) => item.type === "taxonomy_term--faq_categories")
      .forEach((item) => {
        const { name } = item.attributes;
        faqCategories[item.id] = {
          uuid: item.id,
          name,
        };
        faqsCategoriesIds.push(item.id);
      });

  return state
    .set("byId", fromJS(faqCategories))
    .set("ids", fromJS(faqsCategoriesIds));
}

export const faqsCategories = {
  initialState: INITIAL_STATE,
  handlers: {
    [ActionTypes.STORE_FAQS]: storeFaqs,
  },
};
