// @flow
import React from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import {
  Sidebar,
  SidebarHeader,
  SidebarViewAll,
} from "../components/layouts/layout1";
import SectionStart from "../components/sectionStart/sectionStart";
import ForumSidebarLinks from "../components/forums/forumSidebarButtons/forumSidebarLinks";
import ForumSidebarFilters from "../components/forums/forumSidebarButtons/forumSidebarFilters";
import { isUuid } from "../constants/misc";
import ForumFolder from "../components/forums/forumFolder/forumFolder";
import { FRONTEND_ORIGIN_URL_NO_SLASH } from "../config";
import withTracker from "./withtracker";
import AuthorizationHOC from "./AuthorizationHOC";
import { ALL_USERS } from "../constants/userGroups";
import withBreadCrumb from "./withBreadcrumb";
import MetaData from "../components/meta/meta";
import { getRouteUrl } from "../routers";
import { ROUTE_FORUM_VIEW_ALL } from "../routers/content/forum";
import { parseForums, parseForumTopics } from "../constants/parse/forum";
import ForumsList from "../components/forums/forums";

type Props = {
  fetchForums: Function,
  fetchSubscriptionStatus: Function,
  changeSubscriptionStatus: Function,
  showAddTopicForm: Function,
  fetchForumTopics: Function,
  clearForumTopics: Function,
  loading: boolean,
  forum_id: string,
  forums: Array<Object>,
  topics: {
    size: number,
  },
  location: {
    pathname: string,
  },
  match: Object,
  isLoggedIn: boolean,
  subscribed?: boolean,
  clearForumsSearchText: Function,
  searchForumsAndTopics: Function,
  searchTopicsText: string,
};

class ForumsHOC extends React.Component<Props> {
  state = {
    selectedForum: null,
    textValue: "",
  };

  fetchSubscriptionStatus = (taxonomyForum) => {
    const { isLoggedIn } = this.props;
    if (isLoggedIn) {
      this.props.fetchSubscriptionStatus(
        taxonomyForum + "&entity_type=taxonomy_term"
      );
    }
  };

  componentDidMount() {
    const { forum_id } = this.props.match.params;

    this.fetchSubscriptionStatus(forum_id);
    if (forum_id) {
      setTimeout(() => {
        this.props.fetchForumTopics(forum_id);
      }, 1);
    } else {
      this.props.clearForumTopics();
    }

    this.props.fetchForums();
  }

  componentWillUnmount() {
    this.props.clearForumTopics();
    this.props.clearForumsSearchText();
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    const { forum_id } = this.props.match.params;
    const nextForumId = nextProps.match.params.forum_id;
    const { searchTopicsText } = this.props;

    if (nextForumId !== forum_id) {
      this.fetchSubscriptionStatus(nextForumId);
      // setTimeout(() => {
      this.props.fetchForumTopics(nextForumId);
      // }, 1);
    }

    if (!nextForumId && nextProps.topics && nextProps.topics.size > 0) {
      this.props.clearForumTopics();
    }

    if (nextProps.searchTopicsText === "" && searchTopicsText !== "") {
      if (nextForumId) {
        setTimeout(() => {
          this.props.fetchForumTopics(nextForumId);
        }, 1);
      } else {
        this.props.clearForumTopics();
      }
    }
  }

  searchForumsAndTopics = (evt) => {
    const { selectedForum } = this.state;
    this.props.searchForumsAndTopics({
      searchTopicsText: evt.target.value,
      forumId: selectedForum,
    });
  };

  forumCategory = (item) => {
    this.setState({
      selectedForum: item ? item.value : null,
    });
    this.props.searchForumsAndTopics({
      searchTopicsText: this.props.searchTopicsText,
      forumId: item ? item.value : null,
    });
  };

  render() {
    const { location, searchTopicsText } = this.props;
    const { forums, topics, loading, isLoggedIn } = this.props;
    const { forum_id } = this.props.match.params;
    const urlSuffix = forum_id ? "/" + forum_id : "";
    return (
      <div>
        <MetaData
          title="Forums"
          url={
            FRONTEND_ORIGIN_URL_NO_SLASH +
            getRouteUrl(ROUTE_FORUM_VIEW_ALL) +
            urlSuffix
          }
          description="Search and participate in our developer forum. Ask questions as the experienced developers in the forum community might know the answers and are happy to help."
        />
        <SectionStart>
          <Sidebar
            sidebarLeft={
              <div>
                <SidebarHeader>
                  <ForumSidebarLinks location={location} />
                  {(!forum_id || (forum_id && isUuid(forum_id))) && (
                    <ForumSidebarFilters
                      forumId={forum_id}
                      forums={forums}
                      searchForumsAndTopics={this.searchForumsAndTopics}
                      forumCategory={this.forumCategory}
                    />
                  )}
                </SidebarHeader>
                <SidebarViewAll />
              </div>
            }
            sidebarRight={
              <div style={{ minHeight: 693 }}>
                {(!forum_id || isUuid(forum_id)) &&
                !(searchTopicsText && searchTopicsText.length > 0) ? (
                  <ForumsList
                    forums={forums}
                    topics={topics}
                    loading={loading}
                    root={forum_id ? forum_id : "virtual"}
                    isLoggedIn={isLoggedIn}
                  />
                ) : (
                  <ForumFolder
                    disableCreate
                    loading={loading}
                    title="Topics"
                    topics={topics}
                    root={forum_id}
                    isLoggedIn={isLoggedIn}
                  />
                )}
                {searchTopicsText &&
                searchTopicsText.length > 0 &&
                topics &&
                topics.length === 0 &&
                !loading ? (
                  <div className="nbg-hub nbg-hub-forum-topics text-align-center">
                    No Results
                  </div>
                ) : null}
              </div>
            }
          />
        </SectionStart>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const searchText = state.forum.get("searchTopicsText");
  return {
    loading: state.forum.get("loading"),
    forums: parseForums(state.forum.get("forumById")),
    searchTopicsText: searchText,
    topics: parseForumTopics(state.forum.get("topics"), searchText),
    receiveStatus: state.forum.get("receiveStatus"),
    isLoggedIn: state.applicationUser.get("isLoggedIn"),
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchSubscriptionStatus: (payload) => {
    dispatch(actions.fetchSubscriptionStatus(payload));
  },
  fetchForums: () => {
    dispatch(actions.fetchForums());
  },
  fetchForumTopics: (payload) => {
    dispatch(actions.fetchForumTopics(payload));
  },
  clearForumTopics: (payload) => {
    dispatch(actions.clearForumTopics(payload));
  },
  searchForumsAndTopics: (payload) => {
    dispatch(actions.searchForumsAndTopics(payload));
  },
  clearForumsSearchText: () => {
    dispatch(actions.clearForumsSearchText());
  },
});

export default withTracker(
  AuthorizationHOC(ALL_USERS)(
    withBreadCrumb(connect(mapStateToProps, mapDispatchToProps)(ForumsHOC), {
      name: "Forums",
    })
  )
);
