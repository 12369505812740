import {
  createJsonApiFilter,
  createJsonApiOrGroup,
} from "../constants/jsonapi_helpers";
import dependencies from "../authentication/dependencies";
import { DEFAULT_PAGINATION } from "../config";
const { baseUrl, generalHeader } = dependencies;

const apiProductsUrl =
  "/jsonapi/node/api_product" +
  "?sort=-changed" +
  "&include=field_image" +
  "&fields[file--file]=uri,url" +
  "&" +
  createJsonApiFilter("deprecatedFalse", "field_deprecated", "0") +
  "&" +
  createJsonApiFilter("deprecatedNull", "field_deprecated", null, "IS NULL") +
  "&" +
  createJsonApiOrGroup("orGroup", ["deprecatedNull", "deprecatedFalse"]);

export const fetchApiProducts = async (csrfToken) => {
  const res = await fetch(`${baseUrl}${apiProductsUrl}`, {
    headers: generalHeader(csrfToken),
    credentials: "include",
  });
  return res.json();
};

export const subscribeApiProductToApplication = async (csrfToken, data) => {
  const response = await fetch(`${baseUrl}/api/application`, {
    method: "POST",
    headers: generalHeader(csrfToken, true),
    credentials: "include",
    body: JSON.stringify(data),
  });
  return response.json();
};

export const fetchApiProductsCategory = async () => {
  const url = "/jsonapi/taxonomy_term/product_categories";
  const res = await fetch(`${baseUrl}${url}`, {
    headers: generalHeader(null),
  });
  return res.json();
};

export const filterApiProductsQuery = async (csrfToken, category, page) => {
  const field_category = category
    ? `&${createJsonApiFilter(
        "field_category",
        "field_category.id",
        category.value
      )}`
    : "";

  const requestUrl = `${apiProductsUrl}${field_category}&page[offset]=${
    page * DEFAULT_PAGINATION
  }&page[limit]=${DEFAULT_PAGINATION}`;

  const res = await fetch(`${baseUrl}${requestUrl}`, {
    headers: generalHeader(csrfToken),
    credentials: "include",
  });
  return res.json();
};

export const getProductByAlias = async (alias) => {
  const requestUrl = `/phpsdk/products/getProductUuid?pathAlias=${alias}`
  const res = await fetch(`${baseUrl}${requestUrl}`, {
    headers: generalHeader(null),
    credentials: "include",
  });
  return res.json();
}