// @flow
import React, { useState } from "react";
import "./forumFolder.css";
import TopicTableRow from "../topicTableRow/topicTableRow";
import ForumTableRow from "../forumTableRow/forumTableRow";
import FaSpinner from "../../faSpinner/faSpinner";
import Title from "../../title/title";
import ForumBreadcrumb from "../forumBreadcrumb/forumBreadcrumb";
import SimpleButtonWrapper from "../../simpleButtonWrapper/simpleButtonWrapper";
import { Link } from "react-router-dom";
import Button from "../../button/button";
import Table from "../../table/table";
import { invalidDate } from "../../../constants/misc";

type Props = {
  title: string,
  searchTopicsText?: string,
  rows?: Array<Object>,
  topics?: Object,
  loading: boolean,
  forum_container?: boolean,
  description?: string,
  root: string,
  index?: number,
  disableCreate?: boolean,
  isLoggedIn: boolean,
};

const folderForumHeading = [
  {
    title: "FORUM",
    sortable: true,
    sortAttribute: "name",
    className: "cell forum-head",
  },
  {
    title: "TOPICS",
    sortable: true,
    sortAttribute: "statistics.topic_count",
    className: "cell topic-head",
  },
  {
    title: "POSTS",
    sortable: true,
    sortAttribute: "statistics.comment_count",
    className: "cell posts-head",
  },
  {
    title: "LAST POSTS",
    sortable: true,
    sortAttribute: "last_post.created",
    className: "cell lasrposts-head",
  },
];

const folderTopicHeading = [
  {
    title: "TOPIC",
    sortable: true,
    sortAttribute: "title",
    className: "cell forum-head",
  },
  {
    title: "REPLIES",
    sortable: true,
    sortAttribute: "comment_forum.comment_count",
    className: "cell topic-head",
  },
  {
    title: "LAST POST",
    sortable: true,
    sortAttribute: "comment_forum.last_comment_date",
    className: "cell posts-head",
  },
];

function ForumFolder(props: Props) {
  const {
    title,
    rows,
    topics,
    loading,
    forum_container,
    description,
    root,
    index,
  } = props;

  return (
    <div>
      {rows ? (
        <div
          className="nbg_table"
          style={index !== 0 ? { marginTop: "3rem" } : null}
        >
          <Title type="array">{title}</Title>
          <div
            className="array_descr"
            dangerouslySetInnerHTML={{
              __html: description
                ? description.replace(/<\/?[^>]+(>|$)/g, "")
                : null,
            }}
          />
          <Table
            sortable
            cellsNumber={4}
            header={folderForumHeading}
            rows={rows}
            rowProps={{ parentIndex: index }}
            RenderRow={ForumTableRow}
          />
        </div>
      ) : null}
      {!(topics && topics.length) && !forum_container && loading && (
        <FaSpinner loading={loading} />
      )}
      {topics && topics.length > 0 ? (
        <div
          className="nbg-hub nbg-hub-forum-topics"
          style={rows && rows.length ? { marginTop: "3rem" } : null}
        >
          <ForumBreadcrumb root={root ? root : "virtual"} inner />
          <CreateTopicButton {...props} />
          <Table
            className="nbg_table"
            sortable
            rows={topics}
            header={folderTopicHeading}
            RenderRow={TopicTableRow}
            cellsNumber={3}
          />
        </div>
      ) : !forum_container && !loading && topics && topics.length === 0 ? (
        <div className="nbg-hub nbg-hub-forum-topics">
          <CreateTopicButton {...props} />
        </div>
      ) : null}
    </div>
  );
}

const CreateTopicButton = (props: Props) => {
  const { forum_container, root, disableCreate, isLoggedIn } = props;
  return isLoggedIn &&
    !disableCreate &&
    !forum_container &&
    root !== "virtual" ? (
    <div className="create_topic">
      <SimpleButtonWrapper position="right">
        <Link to={"/forum-create-topic/" + root}>
          <Button>CREATE TOPIC</Button>
        </Link>
      </SimpleButtonWrapper>
    </div>
  ) : null;
};

export default ForumFolder;
