// @flow
import React from "react";
import { Link } from "react-router-dom";
import { formattedDate } from "../../../constants/misc";
import ViewIcon from "../../svgs/viewIcon";

type Props = {
  name: string,
  uuid: string,
  title: string,
  comment_forum: Object,
  created: number,
  author: Object,
};

const TopicTableRow = (props: Props) => {
  const { comment_forum, title, uuid, author, created } = props;
  return (
    <li className="row line">
      <div className="cell topic">
        <div className="arr_line_1">
          <span style={{ marginRight: 4 }}>
            <ViewIcon />
          </span>
          <Link to={"/forum-topic/" + uuid}>
            <span dangerouslySetInnerHTML={{ __html: title }} />
          </Link>
        </div>
        <div className="arr_line_2">
          {author && <span className="transp_white">by {author.name}</span>}
          <span className="yellow">{formattedDate(created)}</span>
        </div>
      </div>
      <div className="cell tr_white topic" data-title="TOPICS">
        {comment_forum.comment_count}
      </div>
      <div
        className="cell tr_white lastposts"
        data-date="2018-11-10"
        data-title="LAST POSTS"
      >
        {comment_forum.last_comment_date && comment_forum.last_comment_name
          ? formattedDate(comment_forum.last_comment_date)
          : "n/a"}
      </div>
    </li>
  );
};

export default TopicTableRow;
