import React from "react";
import * as actions from "../../../actions";
import { connect } from "react-redux";
import {
  MEMBER_ACTIVE,
  MEMBER_PENDING_INVITATION,
} from "../../../constants/organizationRoles";
import Button from "../../button/button";
import type { Organization } from "../../../constants/map/organization";
import StatusMessage from "../../statusMessage/statusMessage";
import SimpleButtonWrapper from "../../simpleButtonWrapper/simpleButtonWrapper";
import ConfirmModal from "../confirmModal/confirmModal";

type State = {
  confirmModalOpen: boolean,
};

type Props = {
  fetchOrganizationMemberships: Function,
  deleteOrganizationMembership: Function,
  updateOrganizationMembership: Function,
  organization: Organization,
  currentUserUuid: string,
  organizations: Object,
};

class AcceptOrganizationInvitation extends React.Component<Props, State> {
  state = {
    confirmModalOpen: false,
  };

  openConfirmModal = () => {
    this.setState({
      confirmModalOpen: true,
    });
  };

  closeConfirmModal = () => {
    this.setState({
      confirmModalOpen: false,
    });
  };

  componentDidMount() {
    const { organization } = this.props;
    if (!organization.members || organization.members.length === 0) {
      this.props.fetchOrganizationMemberships({
        uuid: organization.uuid,
        id: organization.id,
      });
    }
  }

  onInvitationAccept = (membershipUuid) => {
    this.props.updateOrganizationMembership({
      membershipUuid,
      field_status: MEMBER_ACTIVE,
    });
  };

  onInvitationIgnore = (membershipUuid) => {
    const { organization } = this.props;
    this.props.deleteOrganizationMembership({
      organizationUuid: organization.uuid,
      membershipUuid,
    });
  };

  render() {
    const { confirmModalOpen } = this.state;
    const { organization, currentUserUuid } = this.props;
    const membershipInfo = organization.members.find(
      (m) => m.uuid === currentUserUuid
    );
    return membershipInfo &&
      membershipInfo.status === MEMBER_PENDING_INVITATION ? (
      <StatusMessage modifiers={[StatusMessage.INFO]}>
        <p>
          You have been invited to join this organization. In order to become a
          member of this organization you must accept the invitation.
        </p>
        <div style={{ display: "flex" }}>
          <SimpleButtonWrapper>
            <Button
              onClick={() =>
                this.onInvitationAccept(membershipInfo.membershipUuid)
              }
              electro
            >
              Accept
            </Button>
          </SimpleButtonWrapper>
          <SimpleButtonWrapper style={{ marginLeft: 10 }}>
            <Button onClick={this.openConfirmModal}>Ignore</Button>
          </SimpleButtonWrapper>
        </div>
        <ConfirmModal
          isOpen={confirmModalOpen}
          onSubmit={() =>
            this.onInvitationIgnore(membershipInfo.membershipUuid)
          }
          onCancel={this.closeConfirmModal}
          title="Ignore Invitation"
          message="Are you sure you want to ignore this invitation? You will be removed from this organization. "
        />
      </StatusMessage>
    ) : null;
  }
}

const mapStateToProps = (state) => ({
  currentUserUuid: state.applicationUser.get("uuid"),
});

const mapDispatchToProps = (dispatch) => ({
  updateOrganizationMembership: (payload) => {
    dispatch(actions.updateOrganizationMembership(payload));
  },
  deleteOrganizationMembership: (payload) => {
    dispatch(actions.deleteOrganizationMembership(payload));
  },
  fetchOrganizationMemberships: (payload) => {
    dispatch(actions.fetchOrganizationMemberships(payload));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AcceptOrganizationInvitation);
