import xs from "xstream/index";
import sampleCombine from "xstream/extra/sampleCombine";
import { BASE_URL, DRIVE_API_KEY, RELATIVE_JSON_API_LINKS } from "../config";
import * as ActionTypes from "../actions/types";
import * as actions from "../actions";
import {
  createJsonApiFilter,
  createJsonApiOrGroup,
  createJsonApiPager,
} from "../constants/jsonapi_helpers";
import { generalHeader } from "./headers";

export function fetchBasicPage(sources) {
  const request$ = sources.ACTION.filter(
    (action) => action.type === ActionTypes.FETCH_BASIC_PAGE
  ).map((action) => ({
    // eslint-disable-line
    url: BASE_URL + action.payload.route + "?_format=json",
    category: "fetchBasicPage",
    method: "GET",
    headers: generalHeader(action.payload.token),
    withCredentials: true,
  }));

  let httpResponse$ = sources.HTTP.select("fetchBasicPage")
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .filter((response) => response.status === 200)
    .map((response) => actions.storeBasicPage(response.body));

  return {
    ACTION: httpResponse$,
    HTTP: request$,
  };
}

export function fetchBasicPageFailed(sources) {
  let httpResponse$ = sources.HTTP.select("fetchBasicPage")
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .filter((response) => response.status !== 200)
    .map((response) => actions.fetchBasicPageFailed(response));

  return {
    ACTION: httpResponse$,
  };
}

export function fetchArticles(sources) {
  const state$ = sources.STATE;
  const csrfToken$ = state$.map((state) => state.applicationUser.get("token"));
  const request$ = sources.ACTION.filter(
    (action) => action.type === ActionTypes.FETCH_ARTICLES
  )
    .compose(sampleCombine(csrfToken$))
    .map(([action, csrfToken]) => {
      const {
        url,
        page,
        itemsPerPage,
        categories,
        searchArticle,
      } = action.payload;
      let requestUrl = "";
      if (url) {
        requestUrl = url;
        if (RELATIVE_JSON_API_LINKS) {
          requestUrl = BASE_URL + url;
        }
      } else {
        requestUrl =
          BASE_URL +
          "/jsonapi/node/article" +
          "?" +
          createJsonApiPager(itemsPerPage, page) +
          "&" +
          createJsonApiFilter("isPublished", "status", "1", "=") +
          "&include=field_category,field_image&fields[file--file]=uri,url&sort=-created";
        if (categories && categories.length > 0) {
          const members = [];
          categories.forEach((category, index) => {
            members.push(`hasCategory${index}`);
            requestUrl =
              requestUrl +
              "&" +
              createJsonApiFilter(
                members[index],
                "field_category.id",
                category
              );
          });

          requestUrl =
            requestUrl + "&" + createJsonApiOrGroup("orCategoryGroup", members);
        }
        if (searchArticle) {
          requestUrl =
            requestUrl +
            "&" +
            createJsonApiFilter(
              "searchTitle",
              "title",
              searchArticle,
              "CONTAINS"
            );
        }
      }
      return {
        url: requestUrl,
        category: "fetchArticles",
        method: "GET",
        headers: generalHeader(csrfToken),
        withCredentials: true,
      };
    });

  let httpResponse$ = sources.HTTP.select("fetchArticles")
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .filter((response) => response.status === 200)
    .map((response) => actions.storeArticles(response.body));

  return {
    ACTION: httpResponse$,
    HTTP: request$,
  };
}

export function fetchArticlesFailed(sources) {
  let httpResponse$ = sources.HTTP.select("fetchArticles")
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .filter((response) => response.status !== 200)
    .map((response) => actions.fetchArticlesFailed(response));

  return {
    ACTION: httpResponse$,
  };
}

export function fetchArticlesCategory(sources) {
  const request$ = sources.ACTION.filter(
    (action) => action.type === ActionTypes.FETCH_ARTICLES_CATEGORY
  ).map((action) => ({
    // eslint-disable-line
    url: BASE_URL + "/jsonapi/taxonomy_term/news_categories",
    category: "fetchArticlesCategory",
    method: "GET",
    headers: generalHeader(null),
  }));

  let httpResponse$ = sources.HTTP.select("fetchArticlesCategory")
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .filter((response) => response.status === 200)
    .map((response) => actions.storeArticlesCategory(response.body));

  return {
    ACTION: httpResponse$,
    HTTP: request$,
  };
}

export function fetchArticlesCategoryFailed(sources) {
  let httpResponse$ = sources.HTTP.select("fetchArticlesCategory")
    .map((response) => response.replaceError((err) => xs.of(err)))
    .flatten()
    .filter((response) => response.status !== 200)
    .map((response) => actions.fetchArticlesCategoryFailed(response));

  return {
    ACTION: httpResponse$,
  };
}
