// @flow
import React from "react";
import HeroArea from "../heroArea/heroArea";
import Container from "../container/container";
import RaceBanner from "../raceBanner/raceBanner";
import FeaturedProducts from "../featuredProducts/featuredProducts";
import Steps from "../steps/steps";
import News from "../news/news";
import ExpirationModal from "../expirationModal/expirationModal";
import Main from "../main/main";

type Props = {
  featuredProducts: Array<Object>,
  steps: Array<Object>,
  isLoggedIn: boolean,
  isManuallyLoggedOut: boolean,
  setManualLogOut: Function,
};

const FrontPage = (props: Props) => {
  const {
    featuredProducts,
    steps,
    isLoggedIn,
    isManuallyLoggedOut,
    setManualLogOut,
  } = props;
  return (
    <div>
      <ExpirationModal
        isOpen={isManuallyLoggedOut === false && true}
        setManualLogOut={setManualLogOut}
      />
      <HeroArea isLoggedIn={isLoggedIn} />
        <Main
            isLoggedIn={isLoggedIn}
            featuredProducts={featuredProducts}
            steps={steps}
        />
        {/*{steps.length && <Steps steps={steps}/>}*/}
        {/*<News viewStyle="block"/>*/}
    </div>
  );
};

export default FrontPage;
